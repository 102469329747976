/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import axios from 'axios';

// /sign-in
import SignIn from '../signin';
import Impersonate from '../impersonate';
import RegisterDevice from '../registerdevice';

// /admin
import Statistics from '../admin/Statistics';
import Users from '../admin/Users.js';
import AdminClients from '../admin/AdminClients';
import UserRegister from '../admin/UserRegister';
import RegisterClient from '../admin/RegisterClient';
import MentorPlan from '../admin/MentorPlan';
import Activities from '../admin/Activities';
import AdminMenu from '../../components/AdminMenu';
import AdminClient from '../admin/AdminClient';
import Reports from '../admin/AdminReports';
import DevelopmentStages from '../admin/DevelopmentStages';
import Accessories from 'containers/admin/Accessories';
// import AdminScreeningsScreen from '../admin/AdminScreenings';
import ScreeningFormResults from '../admin/AdminScreenings/ScreeningFormResults';
import ScreeningQuestions from '../admin/Screening';
import InterNDAForm from '../admin/Measurements/InterNDAForm';
import PreschoolScreening from '../admin/Measurements/PreschoolScreening';
import AdminUsers from '../admin/UserInfo';

// /omama
import Plan from '../omama/Plan';
import Clients from '../omama/Clients';
import Gallery from '../omama/OmamaClientsGallery';
import AddNewAction from '../omama/AddNewAction';
import OmamaActivities from '../omama/OmamaActivities';
import ClientProfile from '../omama/ClientProfile/ClientProfile';
import ClientImageGallery from '../omama/ClientImageGallery';
import OmamaImageGallery from '../omama/OmamaImageGallery';
//import LentTools from '../omama/LentTools';
import RentedAccessories from 'containers/omama/RentedAccessories';
import RentedAccessoryDetail from 'containers/omama/RentedAccessoryDetail';
import OmamaReport from '../omama/OmamaReport';
import OmamaSupervision from '../omama/OmamaSupervision';
import SupervisionDetail from '../omama/SupervisionDetail';
import Notifications from 'containers/omama/Notifications';
import OmamaProfile from 'containers/omama/Profile/OmamaProfile';

// Supervisor
import SupervisionForm from '../supervisor/SupervisionForm';
import SupervisorPlan from '../supervisor/SupervisorPlan';

// /All
import Settings from '../settings';
import ChangePassword from '../settings/changepassword';
import ChangeProfilePicture from '../settings/changeprofilepicture';
import ReportIssue from 'containers/settings/ReportIssue';
import { api } from '../../conf';
import { urlLogger, errorLogger } from 'conf/loggers';

// Mentor
import MentorReport from 'containers/mentor/MentorReport';

// /404
import NoMatch from '../nomatch';

// Components
import PopUp from '../../components/PopUp';
import Loading from '../../components/Loading';
import GoTop from '../../components/GoTop';
import OmamaMenu from '../../components/Menu';
import Header from '../../components/Header';
import ActionDetail from '../../components/ActionDetail';
import ErrorReportFloatButton from 'components/ErrorReportFloatButton';
import BirthdayPopUp from 'components/BirthdayPopUp';

//Actions
import { getMenuAction } from '../../actions/menu.actions';
import { addNewMessageAction, cleanMessageAction } from '../../actions/message.actions';
import { getDisplayBirthdayModal, getClosedBirthdayModal } from '../../actions/users.actions';
import { strings } from 'strings/StringsProvider';
import ProtectedRoute from './router/protectedRoute';

const pageSettings = [
  {
    starts: '/omama',
    protected: true,
    redirect: '/omama/plan',
  },
  {
    starts: '/admin',
    protected: true,
    redirect: '/admin',
  },
  {
    starts: '/mentor',
    protected: true,
    redirect: '/mentor',
  },
  {
    starts: '/supervisor',
    protected: true,
    redirect: '/supervisor',
  },
  {
    starts: '/sign-in',
    protected: false,
  },
  {
    starts: '/UserRegister',
    protected: false,
  },
  {
    starts: '/404',
    protected: false,
  },
  {
    starts: '/register-device',
    protected: false,
  },
  {
    starts: '/',
    protected: false,
  },
];

// intercepting console.error and storing the error message
(function () {
  var log = console.error;
  console.error = function () {
    errorLogger(arguments);
    log.apply(this, Array.prototype.slice.call(arguments));
  };
})();

function App() {
  const dispatch = useDispatch();
  const [goTop, setGoTop] = useState(false);

  const location = useSelector((state) => state.router.location);
  const showMenu = useSelector((state) => state.menu.showMenu);
  const messageText = useSelector((state) => state.message.text);
  const messageResult = useSelector((state) => state.message.result);
  const loading = useSelector((state) => state.status.loading);
  const showBirthdayModal = useSelector((state) => state.users.displayBirthdayModal);
  const shouldCloseBirthdayModal = useSelector((state) => state.users.shouldCloseBirthdayModal);

  const isUserSignIn = useCallback(() => {
    const token = localStorage.getItem('access-token');
    const pageSetup = pageSettings.find((page) => location.pathname.startsWith(page.starts));
    if (!pageSetup) {
      dispatch(push('/404'));
      return;
    }
    if (pageSetup.protected) {
      if (token) {
        const tokenDecoded = jwtDecode(token);
        const tokenExpired = dayjs.unix(tokenDecoded.exp).isSameOrBefore(dayjs());

        tokenExpired && dispatch(push('/sign-in'));
        const birthdayDate = tokenDecoded.birthday;

        if (birthdayDate) {
          // compare date
          const birthdayDay = new Date(birthdayDate).getDate();
          const birthdayMonth = new Date(birthdayDate).getMonth(); // Calendar month = getMonth() + 1
          const now = new Date();

          if (now.getDate() === birthdayDay && now.getMonth() === birthdayMonth) {
            const path = location.pathname;
            if (path === '/admin' || path === '/admin/users' || path === '/omama/plan') {
              if (!shouldCloseBirthdayModal) {
                dispatch(getDisplayBirthdayModal(true));
              }
            }
          }
        }
        // to do fix
        // !location.startsWith(`/${tokenDecoded.role}`)
      } else {
        dispatch(push('/sign-in'));
      }
    }
  }, [dispatch, location.pathname, shouldCloseBirthdayModal]);

  const tokenCheck = async () => {
    const token = localStorage.getItem('access-token');
    if (token) {
      const tokenDecoded = jwtDecode(token);
      if (!dayjs.unix(tokenDecoded.exp).isAfter(dayjs().add(11, 'minute'))) {
        try {
          const { data } = await axios.get(api.refreshToken);
          localStorage.setItem('access-token', data.token);
        } catch (error) {
          if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            localStorage.removeItem('access-token');
            dispatch(push('/sign-in'));
            dispatch(addNewMessageAction(strings.automaticLoggedOut, false));
          }
        }
      }
    }
  };

  useEffect(() => {
    isUserSignIn();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('error', (error) => errorLogger(error));
    setInterval(tokenCheck, 600000);
  });

  useEffect(() => {
    isUserSignIn();
    urlLogger(location.pathname);
  }, [location, isUserSignIn]);

  const handleScroll = () => {
    if (window.pageYOffset > 80) {
      if (!goTop) {
        setGoTop(true);
      }
    } else {
      if (goTop) {
        setGoTop(false);
      }
    }
  };

  const openMenu = () => {
    dispatch(getMenuAction(!showMenu));
  };

  const onOmamaPictureClick = () => {
    dispatch(push('/omama/plan'));
  };

  const shouldDisplayBirthdayGreeting = () => {
    dispatch(getDisplayBirthdayModal(false));
    dispatch(getClosedBirthdayModal(true));
  };

  const clearPopupMessage = useCallback(() => {
    dispatch(cleanMessageAction());
  }, [dispatch]);

  return (
    <div>
      {(location.pathname.startsWith('/admin') ||
        location.pathname.startsWith('/supervisor') ||
        location.pathname.startsWith('/mentor')) && <AdminMenu pathname={location.pathname} />}
      {location.pathname.startsWith('/omama') && !showMenu && (
        <Header onOmamaPictureClick={onOmamaPictureClick} onClickMenu={openMenu} location={location.pathname} />
      )}
      {showBirthdayModal && !showMenu && <BirthdayPopUp onClose={() => shouldDisplayBirthdayGreeting()} />}
      <Route exact path="/register-device" component={RegisterDevice} />
      <Route exact path="/" component={SignIn} />
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/impersonate" component={Impersonate} />
      <ProtectedRoute exact path="/admin/register" component={UserRegister} allowedRoles={['admin']} />
      <ProtectedRoute exact path="/admin/users" component={Users} allowedRoles={['admin', 'mentor', 'supervisor']} />
      <ProtectedRoute exact path="/omama/plan" component={Plan} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/supervizie" component={OmamaSupervision} allowedRoles={['omama']} />
      <ProtectedRoute
        exact
        path="/omama/supervizie/:supervisionId"
        component={SupervisionDetail}
        allowedRoles={['omama']}
      />
      <ProtectedRoute exact path="/omama/report" component={OmamaReport} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/klienti" component={Clients} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/galeria" component={Gallery} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/pridat" component={AddNewAction} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/aktivity" component={OmamaActivities} allowedRoles={['omama']} />
      <ProtectedRoute
        exact
        path="/omama/screening/:screeningId/klient/:clientId"
        component={ScreeningQuestions}
        allowedRoles={['omama']}
      />
      <ProtectedRoute
        exact
        path="/admin/plan"
        component={MentorPlan}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/plan/akcia/:actionId"
        component={ActionDetail}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute path="/admin/aktivity" component={Activities} allowedRoles={['admin', 'mentor', 'supervisor']} />
      <ProtectedRoute
        exact
        path="/omama/clientProfiles/:clientProfileId"
        component={ClientProfile}
        allowedRoles={['omama']}
      />
      <ProtectedRoute
        exact
        path="/omama/clientGallery/:clientProfileId"
        component={ClientImageGallery}
        allowedRoles={['omama']}
      />
      <ProtectedRoute
        exact
        path="/omama/omamaGallery/:actionType"
        component={OmamaImageGallery}
        allowedRoles={['omama']}
      />
      <ProtectedRoute exact path="/omama/pomocky" component={RentedAccessories} allowedRoles={['omama']} />
      <ProtectedRoute
        exact
        path="/omama/pomocky/:accessoryId"
        component={RentedAccessoryDetail}
        allowedRoles={['omama']}
      />
      <ProtectedRoute exact path="/omama/nastavenia" component={Settings} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/nastavenia/heslo" component={ChangePassword} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/nastavenia/profil" component={ChangeProfilePicture} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/nastavenia/problem" component={ReportIssue} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/notifikacie" component={Notifications} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/zrkadlo" component={OmamaProfile} allowedRoles={['omama']} />
      <ProtectedRoute exact path="/omama/vyvinoveStadia" component={DevelopmentStages} allowedRoles={['omama']} />
      <ProtectedRoute
        exact
        path="/admin/notifikacie"
        component={Notifications}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/nastavenia"
        component={Settings}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/nastavenia/heslo"
        component={ChangePassword}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/nastavenia/profil"
        component={ChangeProfilePicture}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/nastavenia/problem"
        component={ReportIssue}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/klienti"
        component={AdminClients}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/statistic"
        component={Statistics}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute exact path="/admin/" component={Statistics} allowedRoles={['admin', 'mentor', 'supervisor']} />
      <ProtectedRoute exact path="/admin/report" component={Reports} allowedRoles={['admin']} />
      <ProtectedRoute
        exact
        path="/admin/vyvinoveStadia"
        component={DevelopmentStages}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute exact path="/admin/pomocky" component={Accessories} allowedRoles={['admin']} />
      {/* <Route exact path="/admin/skriningy/:clientId" component={AdminScreeningsScreen} />
        <Route exact path="/admin/skriningy" component={AdminScreeningsScreen} /> */}
      <ProtectedRoute
        path="/admin/klienti/:clientId"
        component={AdminClient}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/screening/:screeningId/klient/:clientId/"
        component={ScreeningQuestions}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/screening/:screeningType/:screeningID/klient/:clientId/"
        component={ScreeningFormResults}
        allowedRoles={['admin']}
      />
      <ProtectedRoute
        exact
        path="/admin/klient/:clientId/merania/interNDA"
        component={InterNDAForm}
        allowedRoles={['admin']}
      />
      <ProtectedRoute
        exact
        path="/admin/klient/:clientId/merania/predskolsky-skrining"
        component={PreschoolScreening}
        allowedRoles={['admin']}
      />
      <ProtectedRoute exact path="/admin/klient/pridat" component={RegisterClient} allowedRoles={['admin']} />
      <ProtectedRoute exact path="/admin/klient/upravit" component={RegisterClient} allowedRoles={['admin']} />
      <ProtectedRoute
        exact
        path="/admin/user/:userId/:userRole"
        component={AdminUsers}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute exact path="/supervisor/akcia/:actionId" component={ActionDetail} allowedRoles={['supervisor']} />
      <ProtectedRoute
        exact
        path="/admin/user/:userId/omama/supervizie/:supervisionId"
        component={SupervisionDetail}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/admin/user/:userId/pomocky/:accessoryId"
        component={RentedAccessoryDetail}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute
        exact
        path="/supervisor/form/:supervisionId"
        component={SupervisionForm}
        allowedRoles={['admin', 'mentor', 'supervisor']}
      />
      <ProtectedRoute exact path="/supervisor/plan" component={SupervisorPlan} allowedRoles={['supervisor']} />
      <ProtectedRoute
        exact
        path="/supervisor/plan/akcia/:actionId"
        component={ActionDetail}
        allowedRoles={['supervisor']}
      />
      <ProtectedRoute exact path="/mentor/vykaz" component={MentorReport} allowedRoles={['mentor']} />
      <Route path="/404" component={NoMatch} />

      {messageText && (
        <PopUp messageText={messageText} messageResult={messageResult} cleanMessage={clearPopupMessage} />
      )}

      {loading && <Loading />}

      {/* {this.props.router.location.pathname.startsWith('/omama/plan') &&
          <Footer />} */}
      {showMenu && <OmamaMenu onClickMenu={openMenu} />}

      <ErrorReportFloatButton />

      {goTop && (location.pathname.startsWith('/admin') || location.pathname.startsWith('/mentor')) && <GoTop />}
    </div>
  );
}

export default App;
