import React from 'react';
import { Descriptions, Button } from 'antd';
import { strings } from 'strings/StringsProvider';
import PropTypes from 'prop-types';
import { providedByMap } from 'data/enums';
import dayjs from 'dayjs';

import './AccessoryDetail.scss';

function MissingAccessoryDetail({ accessory, deliverAccessoryModal }) {
  return (
    <>
      <Descriptions className="descriptions">
        <Descriptions.Item className="accessory-detail-item" label={strings.region}>
          {accessory.omamaRegion}
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.providedBy}>
          {providedByMap[accessory.providedBy]}
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.toolName}>
          {accessory.name}
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.omama}>
          {accessory.omamaName}
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.comment}>
          {accessory.note}
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.modifiedDate}>
          {accessory.updatedAt
            ? dayjs(accessory.updatedAt).format('D. M. YYYY')
            : dayjs(accessory.createdAt).format('D. M. YYYY')}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <br />

      <div>
        <Button className="accessories-deliver-button" onClick={() => deliverAccessoryModal(accessory)}>
          {strings.deliverAccessory}
        </Button>
      </div>
    </>
  );
}

MissingAccessoryDetail.propTypes = {
  accessory: PropTypes.object,
  deliverAccessoryModal: PropTypes.func,
};

export default MissingAccessoryDetail;
