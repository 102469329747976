import React from 'react';
import { Layout } from 'antd';
import NotificationsList from 'components/Notifications/NotificationsList';

function Notifications() {
  return (
    <Layout className="content">
      <NotificationsList />
    </Layout>
  );
}

export default Notifications;
