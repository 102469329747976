import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { strings } from 'strings/StringsProvider';
import imageCompression from 'browser-image-compression';
import { jwtDecode } from 'jwt-decode';
import { api } from 'conf';
import axios from 'axios';
import { showBugButton } from 'conf/loggers';

import './index.scss';

const { TextArea } = Input;

function ReportIssue() {
  const [issueDescription, setIssueDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const isOmama = jwtDecode(localStorage.getItem('access-token')).role === 'omama';

  const history = useHistory();

  const loadImage = async (event, useWebWorker = true) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    let options = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation,
    };
    const output = await imageCompression(file, options);

    const base64ImagePhoto = await imageCompression.getDataUrlFromFile(output);
    setPhotos([...photos, base64ImagePhoto]);
  };

  const removeImage = (item) => {
    const updatedPhotos = photos.filter((photo) => photo !== item);
    setPhotos(updatedPhotos);
  };

  const getPhotosClassname = (photosCount) => {
    if (photosCount === 1) return 'one-photo';
    else if (photosCount === 2) return 'two-photos';
    else return 'three-photos';
  };

  const reportIssue = async () => {
    const body = {
      description: issueDescription,
      photos,
      logs: JSON.parse(localStorage.getItem('logs')),
    };

    await axios.post(api.sendErrorReport, body);

    localStorage.removeItem('logs');
    showBugButton(false);
    history.goBack();
  };

  const check = () => {
    return issueDescription === '';
  };

  return (
    <div
      className={classNames('settings-container', {
        admin: !isOmama,
      })}
    >
      <div className="settings-nav" tabIndex={0} onClick={history.goBack}>
        <LeftOutlined />
        <h3>{strings.reportIssue}</h3>
      </div>
      <div className="settings-main report-issue">
        <div style={{ textAlign: 'center' }}>
          <h3>{strings.issueDescription}</h3>
          <TextArea
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            placeholder={strings.issueDescription}
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h3>{strings.onePhoto}</h3>
          <div>
            <div>
              <div
                className={classNames('settings-add-photo', {
                  disabledPhotoButton: photos.length >= 3,
                })}
              >
                <div>
                  <label htmlFor="photogallery">
                    <div className="settings-choose-photo">
                      <img
                        alt="settings-photoIcon"
                        className="settings-photoIcon"
                        src="/images/Icons/Galeria_ikona.png"
                      />
                      <div>{strings.choosePhoto}</div>
                    </div>
                  </label>
                  <input
                    id="photogallery"
                    value={[]}
                    type="file"
                    accept="image/*"
                    onChange={loadImage}
                    style={{ display: 'none' }}
                  ></input>
                </div>
              </div>
              {photos.length > 0 && (
                <div className="tempPhotos">
                  {photos.map((item, i) => (
                    <div key={i} className="photo-container">
                      <img className={getPhotosClassname(photos.length)} alt="" src={item}></img>
                      <div className="xButton" onClick={() => removeImage(item)}>
                        <CloseOutlined />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <Button className="settings-button" type="primary" shape="round" onClick={reportIssue} disabled={check()}>
          {strings.send}
        </Button>
      </div>
    </div>
  );
}

export default ReportIssue;
