import {
  HANDLE_SUPERVISION_FORM,
  RESET_SUPERVISION_FORM,
  POPULATE_SUPERVISION_FORM,
} from '../reducers/supervisionForm.reducer';
import axios from 'axios';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';

export const updateSupervisionFormAction = (field, value) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_SUPERVISION_FORM,
      payload: {
        field,
        value,
      },
    });
  };
};

export const resetSupervisionForm = () => {
  return async (dispatch) => {
    return dispatch({
      type: RESET_SUPERVISION_FORM,
      payload: {},
    });
  };
};

export const saveSupervisionFormAction = (supervisionID, body) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios.post(api.updateSupervision(supervisionID), body);
      return dispatch({
        type: HANDLE_SUPERVISION_FORM,
        payload: {
          field: 'status',
          value: 'done',
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const populateSupervisionFormAction = (supervisionID) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getSupervision(supervisionID));
      const supervision = data.data;
      const result = await axios.get(api.getChecklist(supervision.checklistID || 'generalChecklist_v1'));
      const checklistDefinition = result.data.data;
      const payload = {
        data: {
          omama: supervision.omama,
          client: supervision.client,
          supervisor: supervision.supervisor,
          omamaID: supervision.omamaID,
          supervisorID: supervision.supervisorID,
          lessonsCount: supervision.lessonsCount || 0,
          lastSupervisionTarget: supervision.lastSupervisionTarget || '',
          date: supervision.date,
          targetToNextSupervision: supervision.targetToNextSupervision || '',
          textEvaluation: supervision.textEvaluation || '',
          supervisionIndex: supervision.supervisionIndex || 0,
          checkedActivities: supervision.checkedActivities || [],
          status: supervision.status,
        },
        definition: checklistDefinition,
      };

      if (supervision.questions) {
        payload.data.questions = supervision.questions;
      } else {
        payload.data.questions = checklistDefinition.table
          .filter((section) => section.children)
          .reduce(
            (acc, section) => ({
              ...acc,
              [section.group]: section.children.reduce(
                (questionAcc, question) => ({
                  ...questionAcc,
                  [question.key]: question.enabledCols.reduce(
                    (columnAcc, column) => ({ ...columnAcc, [column]: 0 }),
                    {},
                  ),
                }),
                {},
              ),
            }),
            {},
          );
      }

      return dispatch({
        type: POPULATE_SUPERVISION_FORM,
        payload,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
};
