import React, { useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

// Local imports
import { api } from '../conf';
import { useImageUrlContext } from 'context/ImageUrlContext';

function Base64Image({ imageID, className, type, updateAllowed, backUpImage, showDate }) {
  const prevImageIDRef = useRef(imageID);
  const { imageUrls, setImageUrl, isLoadingImages, setIsLoading } = useImageUrlContext();
  const imageFullId = `${type}/${imageID}`;

  const getImage = useCallback(
    async (type, imageID) => {
      if (!imageID) {
        return;
      }
      setIsLoading(imageFullId);
      const { data } = await axios.get(api.getBase64Image(type, imageID));
      if (data) {
        setImageUrl(imageFullId, data.url, data.date);
      }
    },
    [imageFullId, setImageUrl, setIsLoading],
  );

  useEffect(() => {
    if (!(imageFullId in imageUrls) && !isLoadingImages.includes(imageFullId)) {
      getImage(type, imageID);
    }
  }, [getImage, imageFullId, imageID, imageUrls, isLoadingImages, type]);

  useEffect(() => {
    updateAllowed && prevImageIDRef.current !== imageID && getImage(type, imageID);
    prevImageIDRef.current = imageID;
  }, [getImage, imageID, type, updateAllowed]);

  if (!imageID) return null;

  return (
    <div className={className} data-test-id="baseImage">
      <img
        src={
          imageUrls[imageFullId]?.url && imageUrls[imageFullId]?.url?.length > 0
            ? imageUrls[imageFullId].url
            : backUpImage
        }
        alt=""
      />
      {showDate && imageUrls[imageFullId]?.date && (
        <div style={{ textAlign: 'center', color: '#41aea6', fontSize: '0.9em' }}>
          {dayjs(imageUrls[imageFullId].date).format('D. M. YYYY HH:mm')}
        </div>
      )}
    </div>
  );
}

Base64Image.propTypes = {
  imageID: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  updateAllowed: PropTypes.bool,
  backUpImage: PropTypes.string,
  showDate: PropTypes.bool,
};

export default Base64Image;
