export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';
export const CLEAN_MESSAGE = 'CLEAN_MESSAGE';

const initialState = {
  text: '',
  result: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_MESSAGE:
      return {
        ...state,
        text: action.payload.text,
        result: action.payload.result,
      };
    case CLEAN_MESSAGE:
      return initialState;
    default:
      return state;
  }
};
