import { POPULATE_ACTIVITY_FORM, UPDATE_ACTIVITY_FORM, CLEAN_UP_ACTIVITY_FORM } from '../reducers/activityForm.reducer';
import { SET_LOADING } from '../reducers/status.reducer';
import { getAdminActivitiesAction } from './admin.actions';
import { addNewMessageAction } from './message.actions';

import axios from 'axios';

// Local imports
import { api } from '../conf';
import { strings } from '../strings/StringsProvider';

export const updateActivityFormAction = (formField, payload) => {
  if ((formField === strings.week5 || formField === strings.oneMonth) && payload) {
    payload = Number(payload);
    if (formField === strings.week5 && payload > 4) {
      payload = 4;
    }
  }
  return async (dispatch) => {
    return dispatch({
      type: UPDATE_ACTIVITY_FORM,
      formField,
      payload,
    });
  };
};

export const populateActivityFormAction = (payload) => {
  return async (dispatch) => {
    return dispatch({
      type: POPULATE_ACTIVITY_FORM,
      payload,
    });
  };
};

export const cleanUpActivityFormAction = () => {
  return async (dispatch) => {
    return dispatch({
      type: CLEAN_UP_ACTIVITY_FORM,
    });
  };
};

export const addNewActivityAction = (newActivity) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: true,
      },
    });
    const body = {
      type: Number(newActivity.type),
      name: newActivity.name,
      code: newActivity.code,
      ciel: newActivity.ciel,
      priebeh: newActivity.priebeh,
      from: newActivity.from,
      to: newActivity.to,
      pomocky: newActivity.pomocky,
      link: newActivity.link,
      priebehPhotos: newActivity.priebehPhotos,
      pomockyPhotos: newActivity.pomockyPhotos,
      youtubeVideos: newActivity.youtubeVideos,
      tyzden: newActivity.tyzden,
      mesiac: newActivity.mesiac,
    };

    try {
      await axios.post(api.adminAddNewActivity, body);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
      dispatch(addNewMessageAction(strings.activityAdded, true));
      dispatch(getAdminActivitiesAction());
      return dispatch({
        type: CLEAN_UP_ACTIVITY_FORM,
      });
    } catch (err) {
      dispatch(addNewMessageAction(strings.activityNotAdded, false));
      return dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };
};

export const editActivityFormAction = (newActivity, callback) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: true,
      },
    });
    const body = {
      _id: newActivity._id,
      set: {
        type: Number(newActivity.type),
        name: newActivity.name,
        code: newActivity.code,
        ciel: newActivity.ciel,
        priebeh: newActivity.priebeh,
        from: newActivity.from,
        to: newActivity.to,
        pomocky: newActivity.pomocky,
        link: newActivity.link,
        priebehPhotos: newActivity.priebehPhotos,
        pomockyPhotos: newActivity.pomockyPhotos,
        youtubeVideos: newActivity.youtubeVideos,
        tyzden: newActivity.tyzden,
        mesiac: newActivity.mesiac,
      },
    };

    try {
      await axios.post(api.editActivity, body);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
      dispatch(getAdminActivitiesAction());
      callback();
      return dispatch({
        type: CLEAN_UP_ACTIVITY_FORM,
      });
    } catch (err) {
      return dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };
};

export const removeActivityAction = (activityId) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: true,
      },
    });
    const body = {
      id: activityId,
    };

    try {
      await axios.post(api.removeActivity, body);
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
      return dispatch(getAdminActivitiesAction());
    } catch (err) {
      return dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
        },
      });
    }
  };
};
