import React from 'react';
import AdminAdmin from './AdminAdmin';
import AdminMentor from './AdminMentor';
import AdminOmama from './AdminOmama';
import AdminSupervizor from './AdminSupervizor';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

function AdminUsers({ match }) {
  const { userRole } = useParams();

  return (
    <div className="statsBackground">
      <div className="adminBox">
        <div className="admin-main">
          {userRole === 'admin' && <AdminAdmin match={match} />}
          {userRole === 'mentor' && <AdminMentor match={match} />}
          {userRole === 'omama' && <AdminOmama match={match} />}
          {userRole === 'supervisor' && <AdminSupervizor match={match} />}
        </div>
      </div>
    </div>
  );
}

AdminUsers.propTypes = {
  match: PropTypes.object,
};

export default AdminUsers;
