/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Local imports
import {
  getOmamaClientsAction,
  getClientScreenings,
  getOmamaClientActionsAction,
  initialSkriningforClientProfile,
} from '../../../actions/omama.actions';
import { strings } from '../../../strings/StringsProvider';
import ClientProfileCard from '../../../components/ClientProfileCard';
import ChangeClientPhoto from '../ChangeClientPhoto';
import './ClientProfile.scss';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import ClientProfileAction from '../../../components/ClientProfileAction';
import { getAllScreeningQuestions } from 'slices/screenings';
import { calculateScreeningDate } from 'tools/date.tools';

function ClientProfile(props) {
  const [openClientID, setOpenClientID] = useState('');
  const [openAction, setOpenAction] = useState({});
  const [showAction, setShowAction] = useState(false);
  const [changePhoto, setChangePhoto] = useState(false);
  const [client, setClient] = useState({});
  const [doneActions, setDoneActions] = useState([]);
  const [doneActionsNumber, setDoneActionsNumber] = useState(0);
  const [futureActions, setFutureActions] = useState([]);
  const [canceledActions, setCanceledActions] = useState([]);
  const [omamaClient, setOmamaClient] = useState(null);
  const [clientsById, setClientsById] = useState({});

  const dispatch = useDispatch();

  const { omama, screenings } = useSelector((state) => state);

  useEffect(() => {
    const fetchClientsAction = async () => {
      try {
        const { payload } = await dispatch(getOmamaClientsAction());
        setOmamaClient(payload);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientsAction();

    const clientId = props.match.params.clientProfileId;
    const dayjsFn = dayjs();
    const date = dayjsFn.add(1, 'years').format('YYYY-MM-DD');

    dispatch(getClientScreenings(clientId));
    dispatch(getOmamaClientActionsAction(clientId, '1980-01-01', date));
    dispatch(initialSkriningforClientProfile(clientId));
    dispatch(getAllScreeningQuestions());

    if (openClientID === '') {
      const { clientProfileId } = props.match.params;
      setOpenClientID(clientProfileId);
    }
  }, []);

  useEffect(() => {
    if (omamaClient) {
      const clientMatch = omamaClient.clients.find((item) => item._id === openClientID) || {};
      setClient(clientMatch);
    }
  }, [omamaClient]);

  useEffect(() => {
    if (omama.clientActions[0]) {
      /** Done actions */

      const unsortedDoneAction = omama.clientActions.filter((action) => action.status === 'done');
      const sortedDoneActions = orderBy(unsortedDoneAction, ['date'], ['asc']);
      setDoneActionsNumber(sortedDoneActions.length);

      let finalDoneActions = sortedDoneActions;
      if (sortedDoneActions.length > 8) {
        finalDoneActions = sortedDoneActions.slice(sortedDoneActions.length - 8);
      }

      setDoneActions(finalDoneActions);

      /** Future actions */

      const unsortedFutureActions = omama.clientActions.filter(
        (action) => dayjs(action.date).isAfter(dayjs()) && action.status === 'active',
      );
      const sortedFutureActions = orderBy(unsortedFutureActions, ['date'], ['asc']);

      setFutureActions(sortedFutureActions);

      /** Canceled actions */

      const unsortedCanceledActions = omama.clientActions.filter((action) => action.status.startsWith('canceled'));
      const sortedCanceledActions = orderBy(unsortedCanceledActions, ['date'], ['asc']);
      let finalCanceledActions = sortedCanceledActions;
      if (sortedCanceledActions.length > 8) {
        finalCanceledActions = sortedCanceledActions.slice(sortedCanceledActions.length - 8);
      }

      setCanceledActions(finalCanceledActions);
    }
  }, [omama.clientActions[0]]);

  useEffect(() => {
    setClientsById(omama.clients);
  }, [omama.clients]);

  const changePage = (location) => dispatch(push(location));

  const navigateToScreening = (screeningID, openClientID) => {
    changePage('/omama/screening/' + screeningID + '/klient/' + openClientID);
  };

  const toggleChangePhoto = () => {
    setChangePhoto(!changePhoto);
  };

  const renderScreenings = (firstScreenings) => {
    let clientbirthdate = dayjs(clientsById[props.match?.params?.clientProfileId]?.gestacnyBirthDate).format();

    let tempScreenings = {
      '2.PP': {
        title: `2.PP (do 4. ${strings.week3})`,
        type: '2.PP',
        date: dayjs(clientbirthdate).format('D. M. YYYY'),
      },
      '3.PP': {
        title: `3.PP (5-7. ${strings.week4})`,
        type: '3.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['3.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '4.PP': {
        title: `4.PP (8-10. ${strings.week4})`,
        type: '4.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['4.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '5.PP': {
        title: `5.PP (3-4. ${strings.oneMonth})`,
        type: '5.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['5.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '6.PP': {
        title: `6.PP (5-6. ${strings.oneMonth})`,
        type: '6.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['6.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '7.PP': {
        title: `7.PP (7-8. ${strings.oneMonth})`,
        type: '7.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['7.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '8.PP': {
        title: `8.PP (9-10. ${strings.oneMonth})`,
        type: '8.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['8.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '9.PP': {
        title: `9.PP (11-12. ${strings.oneMonth})`,
        type: '9.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['9.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '10.PP': {
        title: `10.PP (15-18. ${strings.oneMonth})`,
        type: '10.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['10.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '11.PP': {
        title: `11.PP (26-35. ${strings.oneMonth})`,
        type: '11.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['11.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
      '12.PP': {
        title: `12.PP (36-40. ${strings.oneMonth})`,
        type: '12.PP',
        date: calculateScreeningDate(dayjs(clientbirthdate), screenings.screeningTests['12.PP']?.fromDay).format(
          'D. M. YYYY',
        ),
      },
    };

    firstScreenings.forEach((initialScreening) => {
      tempScreenings[initialScreening._id].initialDate = initialScreening.created;
    });

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '2px solid #41aea6',
            marginBottom: '5px',
          }}
        >
          <div style={{ width: '155px', textAlign: 'center', fontWeight: 'bold' }}>
            {strings.screening1.toUpperCase()}
          </div>
          <div style={{ width: '100px', textAlign: 'center', fontWeight: 'bold' }}>{strings.plan.toUpperCase()}</div>
          <div style={{ width: '100px', textAlign: 'center', fontWeight: 'bold' }}> {strings.filled.toUpperCase()}</div>
        </div>
        {tempScreenings &&
          Object.keys(tempScreenings).map((key, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '5px 0',
                borderBottom: '1px solid #41aea6',
              }}
              onClick={() => navigateToScreening(tempScreenings[key].type, openClientID)}
            >
              <div style={{ width: '155px' }}>{tempScreenings[key].title}</div>
              <div style={{ width: '100px', textAlign: 'center' }}>{tempScreenings[key].date}</div>
              <div style={{ width: '100px', textAlign: 'center' }}>
                {tempScreenings[key].initialDate && dayjs(tempScreenings[key].initialDate).format('D. M. YYYY')}
              </div>
            </div>
          ))}
      </div>
    );
  };

  const updateAction = (openAction, showAction) => {
    setOpenAction(openAction);
    setShowAction(showAction);
  };

  return (
    <div>
      <div>
        <ClientProfileCard
          client={client}
          nextLection={futureActions[0]?.date}
          doneLections={doneActionsNumber}
          toScreening={(screeningID) => navigateToScreening(screeningID, openClientID)}
          toggleChangePhoto={toggleChangePhoto}
        />
      </div>
      <div style={{ padding: '0 10px', backgroundColor: 'white' }}>
        {showAction && (
          <ClientProfileAction client={client} action={openAction} goBack={() => updateAction({}, false)} />
        )}

        <div className="clientProfile-actionHeadline">{strings.lastLessons}</div>
        <div className="clientProfile-actionList">
          {doneActions.map((action) => (
            <div key={action._id} onClick={() => updateAction(action, true)}>
              {dayjs(action.date).format('D. M. YYYY')}
            </div>
          ))}
        </div>
        <div className="clientProfile-actionHeadline">{strings.lastCanceledLessons}</div>
        <div className="clientProfile-actionList">
          {canceledActions.map((action) => (
            <div key={action._id}>{dayjs(action.date).format('D. M. YYYY')}</div>
          ))}
        </div>
        {clientsById && renderScreenings(omama.initialScreeningsForClientProfile)}
      </div>
      {changePhoto && <ChangeClientPhoto client={clientsById[openClientID]} toggleChangePhoto={toggleChangePhoto} />}
    </div>
  );
}

ClientProfile.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  getOmamaClientsAction: PropTypes.func,
  clientsByArr: PropTypes.arrayOf(PropTypes.object),
  changePage: PropTypes.func,
  clientScreenings: PropTypes.arrayOf(PropTypes.object),
  clientsById: PropTypes.object,
  doneActions: PropTypes.array,
  canceledActions: PropTypes.array,
  actionsDone: PropTypes.number,
  getAllScreeningQuestions: PropTypes.func,
  screeningTests: PropTypes.object,
  futureActions: PropTypes.arrayOf(PropTypes.object),
};

export default ClientProfile;
