import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ImageUrlContext = createContext();

export function useImageUrlContext() {
  return useContext(ImageUrlContext);
}

export function ImageUrlProvider({ children }) {
  const [imageUrls, setImageUrls] = useState({});
  const [isLoadingImages, setIsLoadingImages] = useState([]);

  const setImageUrl = useCallback((imageID, url, date) => {
    setImageUrls((prevUrls) => ({ ...prevUrls, [imageID]: { url, date } }));
    setIsLoadingImages((prevImages) => [...prevImages.filter((prevImageId) => prevImageId !== imageID)]);
  }, []);

  const setIsLoading = useCallback((imageID) => {
    setIsLoadingImages((prevImages) => [...prevImages, imageID]);
  }, []);

  const removeImageUrl = useCallback((imageID) => {
    setImageUrls((prevUrls) => {
      const currentUrls = { ...prevUrls };
      delete currentUrls[imageID];
      return currentUrls;
    });
  }, []);

  return (
    <ImageUrlContext.Provider value={{ imageUrls, setImageUrl, isLoadingImages, setIsLoading, removeImageUrl }}>
      {children}
    </ImageUrlContext.Provider>
  );
}
ImageUrlProvider.propTypes = {
  children: PropTypes.element,
};
