import React from 'react';
import { UpOutlined } from '@ant-design/icons';

import './GoTop.scss';

export default function GoTop() {
  const goTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div onClick={goTop} id="GoTopBtn" title="Go to top">
        <UpOutlined style={{ fontSize: '25px', color: 'white' }} />
      </div>
    </div>
  );
}
