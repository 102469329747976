import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import {
  getOmamaSupervisions,
  getOmamaSupervisionsByOmamaID,
  changePreviousYear,
  changeNextYear,
  selectMonth,
} from '../../actions/omama.actions';
import { strings } from '../../strings/StringsProvider';
import Supervision from '../../components/Supervision.js';
import './OmamaSupervision.scss';
import { deepSum } from 'tools/utils';

const { MonthPicker } = DatePicker;
function OmamaSupervision({ omamaID }) {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const isAdmin = jwtDecode(localStorage.getItem('access-token')).role === 'admin';
  const isOmama = jwtDecode(localStorage.getItem('access-token')).role === 'omama';
  const isMentor = jwtDecode(localStorage.getItem('access-token')).role === 'mentor';
  const isSupervisor = jwtDecode(localStorage.getItem('access-token')).role === 'supervisor';

  const supervisions = useSelector((state) => state.omama.supervisions);
  const startDate = useSelector((state) => state.omama.startDate);
  const endDate = useSelector((state) => state.omama.endDate);

  const changePage = (location) => push(location);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOmama) {
      dispatch(getOmamaSupervisions(startDate, endDate));
    } else if (isAdmin || isMentor || isSupervisor) {
      dispatch(getOmamaSupervisionsByOmamaID(omamaID, startDate, endDate));
    }
  }, [dispatch, endDate, isAdmin, isMentor, isOmama, isSupervisor, omamaID, startDate]);

  const filterSameMonth = () => {
    const monthsMap = {};
    let supervisionsList = supervisions.slice();
    supervisionsList.sort((a, b) => dayjs(b.date).diff(a.date));
    supervisionsList.forEach((supervision) => {
      const date = dayjs(supervision.date);
      const month = date.format('M YYYY');
      if (!monthsMap[month]) {
        monthsMap[month] = [];
      }
      monthsMap[month].push(supervision);
    });

    return monthsMap;
  };

  const changePreviousYearHandler = () => {
    dispatch(changePreviousYear(startDate, endDate, omamaID));
  };

  const changeNextYearHandler = () => {
    dispatch(changeNextYear(startDate, endDate, omamaID));
  };

  const selectMonthHandler = (val) => {
    dispatch(selectMonth(val, omamaID));
    setDate(val || dayjs());
  };

  const navigateToSupervision = (id) => {
    if (isOmama) {
      dispatch(changePage('/omama/supervizie/' + id));
    } else if (isAdmin || isMentor || isSupervisor) {
      dispatch(changePage('/admin/user/' + omamaID + '/omama/supervizie/' + id));
    }
  };

  const sameMonth = filterSameMonth();
  const selectedYear = dayjs(endDate).format('YYYY');

  return (
    <div className="container omamaSupervisions">
      <div className="container-inner">
        <div className="picker">
          <div className="datepicker">
            <MonthPicker value={dayjs(date)} onChange={selectMonthHandler} placeholder={strings.chooseMonthAndYear} />
          </div>
          <div className="yearpicker">
            <div>
              <LeftOutlined className="icon" onClick={changePreviousYearHandler} />
            </div>
            <div className="yearpicker-year">{selectedYear}</div>
            <div>
              {selectedYear !== dayjs(new Date()).format('YYYY') && (
                <RightOutlined className="icon" type="right" onClick={changeNextYearHandler} />
              )}
            </div>
          </div>
        </div>
        <div className="omamaSupervisions_list">
          {Object.keys(sameMonth).map((key) => {
            const [month, year] = key.split(' ');
            return (
              <div key={key}>
                <h3 className="mesiac">{`${dayjs()
                  .month(Number(month) - 1)
                  .year(year)
                  .format('MMMM YYYY')}`}</h3>
                {sameMonth[key].map((supervision, i) => {
                  return (
                    <Supervision
                      key={i}
                      date={`${dayjs(supervision.date).format('D. M. YYYY')}`}
                      time={`${dayjs(supervision.date).add(1, 'hours').format('HH:mm')}`}
                      supervisor={supervision.supervisorName}
                      points={supervision.questions && deepSum(supervision.questions)}
                      changePage={() => navigateToSupervision(supervision._id)}
                    />
                  );
                })}
              </div>
            );
          })}
          {Object.keys(sameMonth).length === 0 && <div className="message">{strings.noPlannedSupervisions}</div>}
        </div>
      </div>
    </div>
  );
}

OmamaSupervision.propTypes = {
  omamaID: PropTypes.string,
};

export default OmamaSupervision;
