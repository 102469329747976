import React from 'react';
import { Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './Loading.scss';
import { strings } from '../strings/StringsProvider';

function Loading() {
  return (
    <div>
      <Modal
        className="modal"
        open={true}
        closable={false}
        centered={true}
        footer={null}
        styles={{ backgroundColor: 'rgba(140, 140, 140, 0.65)' }}
      >
        <div>
          <Spin className="loading" size="large" indicator={<LoadingOutlined spin />} tip={strings.loading}>
            <div className="content"></div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
}

export default Loading;
