import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../strings/StringsProvider';

import './ActionAnswersStats.scss';

const getPercentage = (questions) => {
  let roundedPercentage = [];
  for (let i = 0; i < questions.length; i++) {
    roundedPercentage[i] = [];
    let totalCount = [questions[i].question1.count, questions[i].question2.count, questions[i].question3.count];
    let answers = [
      [questions[i].question1.notAtAll, questions[i].question1.sometimes, questions[i].question1.often],
      [questions[i].question2.notAtAll, questions[i].question2.sometimes, questions[i].question2.often],
      [questions[i].question3.notAtAll, questions[i].question3.sometimes, questions[i].question3.often],
    ];
    for (let j = 0; j < answers.length; j++) {
      let percentage =
        totalCount[j] !== 0
          ? [
              (answers[j][0] / totalCount[j]) * 100,
              (answers[j][1] / totalCount[j]) * 100,
              (answers[j][2] / totalCount[j]) * 100,
            ]
          : [0, 0, 0];
      let decimals = percentage.map((x) => x - Math.floor(x));
      let sortedDecimals = [...decimals].sort((a, b) => a - b);
      let orderOfDecimals = [
        decimals.indexOf(sortedDecimals[0]),
        decimals.indexOf(sortedDecimals[1]),
        decimals.indexOf(sortedDecimals[2]),
      ];
      roundedPercentage[i][j] = percentage.map((x) => Math.floor(x));
      let diff = totalCount[j] !== 0 ? 100 - roundedPercentage[i][j].reduce((a, b) => a + b, 0) : 0;
      for (let k = 0; k < diff; k++) {
        if (roundedPercentage[i][j][orderOfDecimals[k]] === 0) {
          diff++;
        } else {
          roundedPercentage[i][j][orderOfDecimals[k]]++;
        }
      }
    }
  }
  return roundedPercentage;
};

function TableCell({ text }) {
  return (
    <td
      className="action-answers-td"
      colSpan="1"
      style={{ padding: '3px 10px', overflowWrap: 'break-word', maxWidth: '80px' }}
    >
      {text}
    </td>
  );
}
TableCell.propTypes = {
  text: PropTypes.string,
};

function TableDataCell({ count, percent, amount }) {
  return (
    <td className="action-answers-td">
      <div>
        <div className="action-answers-percentage">{count !== 0 ? percent : 0} %</div>
        <div>{amount}</div>
      </div>
    </td>
  );
}
TableDataCell.propTypes = {
  count: PropTypes.number,
  percent: PropTypes.number,
  amount: PropTypes.number,
};

function ActionAnswersStats({ actionAnswers, omamasByID }) {
  const answers = getPercentage(actionAnswers);

  return (
    <div>
      <table border="1" style={{ margin: '15px auto' }}>
        <thead>
          <tr>
            <td className="action-answers-td" colSpan="1"></td>
            <td className="action-answers-td" colSpan="3" style={{ color: '#41aea6' }}>
              {strings.parentJoined}
            </td>
            <td className="action-answers-td" colSpan="3" style={{ color: '#41aea6' }}>
              {strings.parentPraised}
            </td>
            <td className="action-answers-td" colSpan="3" style={{ color: '#41aea6' }}>
              {strings.parentDevoted}
            </td>
          </tr>
          <tr>
            <TableCell text="" />
            <TableCell text={`${strings.almost} ${strings.atAll}`} />
            <TableCell text={strings.sometimes} />
            <TableCell text={`${strings.aLot} ${strings.often}`} />
            <TableCell text={`${strings.almost} ${strings.atAll}`} />
            <TableCell text={strings.sometimes} />
            <TableCell text={`${strings.aLot} ${strings.often}`} />
            <TableCell text={`${strings.almost} ${strings.atAll}`} />
            <TableCell text={strings.sometimes} />
            <TableCell text={`${strings.aLot} ${strings.often}`} />
          </tr>
        </thead>
        <tbody>
          {actionAnswers.map((row, i) => (
            <tr key={i}>
              <th scope="row">{omamasByID[row.omamaID] ? omamasByID[row.omamaID].name : 'Spolu'}</th>
              <TableDataCell count={row.question1.count} percent={answers[i][0][0]} amount={row.question1.notAtAll} />
              <TableDataCell count={row.question1.count} percent={answers[i][0][1]} amount={row.question1.sometimes} />
              <TableDataCell count={row.question1.count} percent={answers[i][0][2]} amount={row.question1.often} />

              <TableDataCell count={row.question2.count} percent={answers[i][1][0]} amount={row.question2.notAtAll} />
              <TableDataCell count={row.question2.count} percent={answers[i][1][1]} amount={row.question2.sometimes} />
              <TableDataCell count={row.question2.count} percent={answers[i][1][2]} amount={row.question2.often} />

              <TableDataCell count={row.question3.count} percent={answers[i][2][0]} amount={row.question3.notAtAll} />
              <TableDataCell count={row.question3.count} percent={answers[i][2][1]} amount={row.question3.sometimes} />
              <TableDataCell count={row.question3.count} percent={answers[i][2][2]} amount={row.question3.often} />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ActionAnswersStats.propTypes = {
  actionAnswers: PropTypes.array,
  omamasByID: PropTypes.object,
};

export default ActionAnswersStats;
