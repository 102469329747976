import axios from 'axios';
import dayjs from 'dayjs';
import { isEmpty, orderBy, set, cloneDeep } from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { Buffer } from 'buffer';

import {
  GET_ADMIN_ACTIVITIES,
  GET_ADMIN_CLIENTS,
  GET_SCREENINGS_FOR_CLIENT,
  GET_SCREENING_QUESTION_DETAILS,
  GET_ADMIN_OMAMA_CLIENTS,
  GET_ADMIN_OMAMA_ACTIONS,
  ADMIN_OMAMA_CHANGE_NEXT_WEEK,
  ADMIN_OMAMA_CHANGE_PREVIOUS_WEEK,
  ADMIN_OMAMA_CHANGE_WEEK,
  ADMIN_OMAMA_CHANGE_NEXT_MONTH,
  ADMIN_OMAMA_CHANGE_PREVIOUS_MONTH,
  GET_ADMIN_CLIENT,
  ADMIN_SAVE_CLIENT,
  ADMIN_SAVE_FILTEREDCLIENT,
  GET_ADMIN_USER,
  GET_ADMIN_CLIENT_ACTIONS,
  ADMIN_CLIENT_CHANGE_DATE,
  ADMIN_OMAMA_CHANGE_DATE,
  HANDLE_DEACTIVATION_DATE,
  HANDLE_DEACTIVATION_REASON,
  ADMIN_REMOVE_PHOTO,
  ADMIN_CLEAR_CLIENT,
  ADMIN_CHANGE_ACTIVITY_PAGE,
  ADMIN_CHANGE_CLIENTS_PAGE,
  GET_ADMIN_CLIENT_ACTIONS_COUNT,
  GET_ALL_OMAMA_SUPERVISIONS,
  GET_MILESTONES_DATA,
  RESET_MILESTONES_DATA,
  GET_ACCESSORIES,
  GET_MISSING_ACCESSORIES,
  GET_MISSING_ACCESSORIES_COUNT,
  GET_RENTED_ACCESSORY_HISTORY,
  GET_DEVELOPMENT_STAGE,
} from '../reducers/admin.reducer';
import { GET_MENTOR_CLIENTS } from '../reducers/mentor.reducer';
import { GET_CLIENT_SCREENINGS_FOR_REPORT } from '../reducers/report.reducer';
import {
  GET_ALL_CLIENT_MEASUREMENTS,
  POPULATE_INTERNDA_FORM,
  RESET_INTERNDA_FORM,
  POPULATE_PRESCHOOL_SCREENING,
  HANDLE_FORM_ANSWERS_CHANGE,
} from '../reducers/measurements.reducer';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';
import { addNewMessageAction } from './message.actions';
import { groupActions } from '../tools/action.tools';
import { strings } from '../strings/StringsProvider';
import { showErrorMessage } from 'tools/errorHandling';

export const getAdminActivitiesAction = () => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getAdminActivities);
    return dispatch({
      type: GET_ADMIN_ACTIVITIES,
      payload: data.activities,
    });
  };
};

export const getAdminClientsAction = (query) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getClients(query));
    return dispatch({
      type: GET_ADMIN_CLIENTS,
      payload: data,
    });
  };
};

export const removePhotoAdminAction = (clientID, photoID) => {
  return async (dispatch) => {
    const { status } = await axios.post(api.removePhoto, { clientID, photoID });
    if (status === 200) {
      return dispatch({
        type: ADMIN_REMOVE_PHOTO,
        payload: {
          clientID,
          photoID,
        },
      });
    }
  };
};

export const getMentorAction = () => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getMentorClients);
    return dispatch({
      type: GET_MENTOR_CLIENTS,
      payload: data.mentor,
    });
  };
};

export const getClientScreenings = (clientId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getClientScreenings + '/' + clientId);
    return dispatch({
      type: GET_SCREENINGS_FOR_CLIENT,
      payload: data,
    });
  };
};

export const getClientScreeningsForReport = (client) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getClientScreenings + '/' + client._id);

      let orderedScreenings = orderBy(data.data, ['type', 'trans'], ['asc', 'asc']);

      let tempScreenings = {
        '2.PP': [],
        '3.PP': [],
        '4.PP': [],
        '5.PP': [],
        '6.PP': [],
        '7.PP': [],
        '8.PP': [],
        '9.PP': [],
        '10.PP': [],
        '11.PP': [],
        '12.PP': [],
      };

      let i = 2;
      for (const kluc in tempScreenings) {
        let oneType = [];
        oneType = orderedScreenings.filter((screening) => screening.type === kluc);
        if (!isEmpty(oneType)) {
          for (const skrining of oneType) {
            let tempScreening = {
              key: skrining._id,
              number: null,
              date: null,
              age: null,
              motorics: {},
              adaptive_behaviour: {},
              communication: {},
              development_score: { body: null, pasmo: null },
              specific_behaviour: {},
              specific_behaviour_score: { body: null, pasmo: null },
              worries: {},
              worries_score: { body: null, pasmo: null },
              originalAnswers: [],
            };

            tempScreening.number = skrining.type.split('.')[0];

            for (const answer of skrining.answers) {
              tempScreening.originalAnswers.push(answer);
              let answerFields = answer.id.split('_');
              switch (true) {
                /* eslint-disable */
                case tempScreening.number < 11 && answerFields[1].startsWith('M'):
                  tempScreening.motorics[answerFields[1]] = answer.answer;
                  break;
                case tempScreening.number < 11 && answerFields[1].startsWith('S'):
                  tempScreening.adaptive_behaviour[answerFields[1]] = answer.answer;
                  break;
                case tempScreening.number < 11 && answerFields[1].startsWith('K'):
                  tempScreening.communication[answerFields[1]] = answer.answer;
                  break;
                case tempScreening.number < 11 && (answerFields[1].startsWith('Z') || answerFields[1].startsWith('ŠS')):
                  tempScreening.specific_behaviour[answerFields[1]] = answer.answer;
                  break;
                case tempScreening.number < 11 && (answerFields[1].startsWith('0') || answerFields[1].startsWith('O')): // pozn. Ked je 0 (nula) tak nam zobrauje obavy v skrinigoch od 2 po 10 ale nei v 11 a 12; tak ako v tabulke
                  tempScreening.worries[answerFields[1]] = answer.answer;
                  break;
                default:
                  break;
                /* eslint-enable */
              }
            }

            tempScreening.development_score = skrining.pasma.vyvinovehoSkore;
            tempScreening.specific_behaviour_score = skrining.pasma.specifickeSpravanie;
            tempScreening.worries_score = skrining.pasma.obavy;

            tempScreening.date = dayjs(skrining.created).format('D. M. YYYY');

            const calculatedAge = () => {
              const fyzickyVek = dayjs(skrining.created).diff(client.gestacnyBirthDate, 'days');
              const korigovanyVek = dayjs(skrining.created).diff(client.birthDate, 'days');
              return `${fyzickyVek}/${korigovanyVek}`;
            };
            tempScreening.age = calculatedAge();

            tempScreenings[kluc].push(tempScreening);
          }
        } else {
          const sablona = {
            key: i,
            number: kluc.split('.')[0],
            date: null,
            age: null,
            motorics: {},
            adaptive_behaviour: {},
            communication: {},
            development_score: { body: null, pasmo: null },
            specific_behaviour: {},
            specific_behaviour_score: { body: null, pasmo: null },
            worries: {},
            worries_score: { body: null, pasmo: null },
            originalAnswers: [],
          };
          tempScreenings[kluc].push(sablona);
        }
        i++;
      }

      let screeningsForTable = [];

      for (const key in tempScreenings) {
        tempScreenings[key].forEach((item) => screeningsForTable.push(item));
      }

      dispatch(setLoading(false));
      return dispatch({
        type: GET_CLIENT_SCREENINGS_FOR_REPORT,
        payload: screeningsForTable,
      });
    } catch (error) {
      return dispatch(setLoading(false));
    }
  };
};

export const getClientScreeningQuestions = (screeningId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getClientScreeningQuestions + '/' + screeningId);
    return dispatch({
      type: GET_SCREENING_QUESTION_DETAILS,
      payload: data,
    });
  };
};

export const getOmamasSupervisions = (omamaId) => {
  return async (dispatch) => {
    const { data } = await axios.get(`${api.getOmamasSupervisions(omamaId)}`);
    return dispatch({
      type: GET_ALL_OMAMA_SUPERVISIONS,
      payload: data.supervisions,
    });
  };
};

export const getAdminOmamaClientsAction = (omamaId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await axios.get(api.getAdminOmamaClients + '/' + omamaId);
    dispatch(setLoading(false));

    const enhancedClients = data.data.map((item) => ({
      ...item,
      lastFirstName: item.lastName + item.firstName,
    }));

    const activeClients = enhancedClients.filter((item) => item.active === true);
    const inactiveClients = enhancedClients.filter((item) => item.active === false);

    const clientsByStatus = {
      active: orderBy(activeClients, ['lastFirstName'], ['asc']),
      inactive: orderBy(inactiveClients, ['lastFirstName'], ['asc']),
    };
    return dispatch({
      type: GET_ADMIN_OMAMA_CLIENTS,
      payload: {
        clientsByStatus,
        allOmamaClients: data.data,
      },
    });
  };
};

export const getAdminOmamaActionsAction = (omamaId, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let startDate = start;
    let endDate = end;
    if (!(typeof start === 'string') || !(typeof end === 'string')) {
      startDate = dayjs(start).format('YYYY-MM-DD');
      endDate = dayjs(end).format('YYYY-MM-DD');
    }
    try {
      const { data } = await axios.get(api.getAdminOmamaActions(omamaId, startDate, endDate));
      const actions = data.data;
      actions.sort((a, b) => new Date(a.date) - new Date(b.date));
      const { result } = groupActions(data.data);
      const clientActionsByDay = result.clientActionsByDay;
      const actionsByDay = result.actionsByDay;
      const tempActionsByDay = result.tempActionsByDay;
      const allActionsByDay = result.allActionsByDay;

      await dispatch({
        type: GET_ADMIN_OMAMA_ACTIONS,
        payload: {
          allActions: actions,
          byDay: tempActionsByDay,
          clientActionsByDay: clientActionsByDay,
          actionsByDay: actionsByDay,
          allActionsByDay: allActionsByDay,
        },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getAdminClientActionsAction = (clientId, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(
        api.getAdminClientActions(
          clientId,
          dayjs(start).format('YYYY-MM-DD'),
          dayjs(end).add(1, 'days').format('YYYY-MM-DD'),
        ),
      );
      const sortedAdminClientActions = orderBy(data.data, ['date'], ['desc']);
      const adminClientActions = sortedAdminClientActions.map((action) => {
        let statusText;

        switch (action.status) {
          case 'active':
            statusText = isEmpty(action.checkedActivities) ? strings.hasDate : strings.plannedActivities;
            break;
          case 'done':
            statusText = strings.realizated;
            break;
          case `canceled - ${strings.lessonCanceledClient}`:
            statusText = strings.closedByClient;
            break;
          default:
            statusText = 'DEFAULT';
            break;
        }
        action.statusForTable = statusText;

        return action;
      });
      await dispatch({
        type: GET_ADMIN_CLIENT_ACTIONS,
        payload: adminClientActions,
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getAdminClientActionsCount = (clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getAdminClientActionsCount(clientId));
      await dispatch({
        type: GET_ADMIN_CLIENT_ACTIONS_COUNT,
        payload: data,
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const adminClientChangeDate = (date) => {
  return async (dispatch) => {
    let from = null;
    let to = null;
    if (date?.[0] && date?.[1]) {
      from = dayjs(date?.[0]);
      to = dayjs(date?.[1]);
    }

    return dispatch({
      type: ADMIN_CLIENT_CHANGE_DATE,
      payload: {
        clientFrom: from,
        clientTo: to,
      },
    });
  };
};

export const adminOmamaChangeDate = (date) => {
  return async (dispatch) => {
    let from = null;
    let to = null;
    if (date?.[0] && date?.[1]) {
      from = dayjs(date[0]);
      to = dayjs(date[1]);
    }

    return dispatch({
      type: ADMIN_OMAMA_CHANGE_DATE,
      payload: {
        omamaFrom: from,
        omamaTo: to,
      },
    });
  };
};

export const changeNextWeek = (omamaId, actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).add(1, 'weeks').startOf('week').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).add(1, 'weeks').endOf('week').format('YYYY-MM-DD');

    dispatch({
      type: ADMIN_OMAMA_CHANGE_NEXT_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });

    dispatch(getAdminOmamaActionsAction(omamaId, start, dayjs(end).add(1, 'days')));
  };
};

export const changePreviousWeek = (omamaId, actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');

    dispatch({
      type: ADMIN_OMAMA_CHANGE_PREVIOUS_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getAdminOmamaActionsAction(omamaId, start, dayjs(end).add(1, 'days')));
  };
};

export const changeWeek = (omamaId, date) => {
  return async (dispatch) => {
    const start = dayjs(date).startOf('week').format('YYYY-MM-DD');
    const end = dayjs(date).endOf('week').format('YYYY-MM-DD');
    console.log('start', start, 'end', end, date);

    dispatch({
      type: ADMIN_OMAMA_CHANGE_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getAdminOmamaActionsAction(omamaId, start, dayjs(end).add(1, 'days')));
  };
};

export const changeNextMonth = (omamaId, actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).add(1, 'months').startOf('month').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).add(1, 'months').endOf('month').format('YYYY-MM-DD');
    dispatch({
      type: ADMIN_OMAMA_CHANGE_NEXT_MONTH,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getAdminOmamaActionsAction(omamaId, start, dayjs(end).add(1, 'days').format('YYYY-MM-DD')));
  };
};

export const changePreviousMonth = (omamaId, actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    dispatch({
      type: ADMIN_OMAMA_CHANGE_PREVIOUS_MONTH,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getAdminOmamaActionsAction(omamaId, start, dayjs(end).add(1, 'days').format('YYYY-MM-DD')));
  };
};

export const getAdminClient = (clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getAdminClient(clientId));
      dispatch({
        type: GET_ADMIN_CLIENT,
        payload: data.data,
      });
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
    }
  };
};

export const saveClient = (client) => {
  return async (dispatch) => {
    return dispatch({
      type: ADMIN_SAVE_CLIENT,
      payload: client,
    });
  };
};

export const saveFilteredClient = (client) => {
  return async (dispatch) => {
    return dispatch({
      type: ADMIN_SAVE_FILTEREDCLIENT,
      payload: client,
    });
  };
};

export const clearClient = () => {
  return async (dispatch) => {
    return dispatch({
      type: ADMIN_CLEAR_CLIENT,
    });
  };
};

export const getAdminUser = (userId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getAdminUser(userId));
      dispatch({
        type: GET_ADMIN_USER,
        payload: data.data,
      });
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
    }
  };
};

export const handleDeactivationDate = (date) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_DEACTIVATION_DATE,
      payload: {
        // field,
        date,
      },
    });
  };
};

export const handleDeactivationOption = (reason) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_DEACTIVATION_REASON,
      payload: {
        reason,
      },
    });
  };
};

export const handleDeactivationDescription = (reason, description) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_DEACTIVATION_REASON,
      payload: {
        reason,
        description,
      },
    });
  };
};

export const editAdminUser = (userId, form) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const body = {
      id: userId,
      set: form,
    };
    try {
      await axios.post(api.editAdminUser, body);
      if (form.accessoriesCoordinator) {
        dispatch(getMissingAccessoriesCount());
      }
      dispatch(setLoading(false));
      return dispatch(getAdminUser(userId));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const deleteAction = (actionID, removeClub, callback) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const data = {
      id: actionID,
      removeClub,
    };
    try {
      await axios.post(api.deleteAction, data);
      if (callback) callback();
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const changeActivityPageAction = (newPageNumber) => {
  return async (dispatch) => {
    return dispatch({
      type: ADMIN_CHANGE_ACTIVITY_PAGE,
      payload: newPageNumber,
    });
  };
};

export const changeClientsPageAction = (newPageNumber) => {
  return async (dispatch) => {
    return dispatch({
      type: ADMIN_CHANGE_CLIENTS_PAGE,
      payload: newPageNumber,
    });
  };
};

export const resetUserPassword = (userId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await axios.patch(api.resetUserPassword(userId));
      dispatch(setLoading(false));
      if (result?.data?.success) dispatch(addNewMessageAction(strings.resetPasswordSucceed, true));
      else dispatch(addNewMessageAction(strings.resetPasswordFailed, false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addNewMessageAction(strings.resetPasswordFailed, false));
    }
  };
};

export const getAllClientMeasurements = (clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const interNDA = await axios.get(api.getClientMeasurement('inter-NDA', clientId));
      const preschoolScreening = await axios.get(api.getClientMeasurement('preschoolScreening', clientId));

      let interNDAMeasurement = null;
      if (!isEmpty(interNDA.data.data)) {
        interNDAMeasurement = interNDA.data.data[0];
      }

      let preschoolScreeningMeasurement = null;
      if (!isEmpty(preschoolScreening.data.data)) {
        preschoolScreeningMeasurement = preschoolScreening.data.data[0];
      }

      const payload = {
        interNDA: interNDAMeasurement,
        preschoolScreening: preschoolScreeningMeasurement,
      };

      dispatch(setLoading(false));
      return dispatch({
        type: GET_ALL_CLIENT_MEASUREMENTS,
        payload: payload,
      });
    } catch (error) {
      dispatch(setLoading(false));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const populateInterNDAForm = (clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const interNDAAnswersData = await axios.get(api.getClientMeasurement('inter-NDA', clientId));
      const interNDAQuestionsData = await axios.get(api.getMeasurementQuestions('inter-NDA'));
      const interNDAAnswers = interNDAAnswersData?.data?.data[0];
      const interNDAQuestions = interNDAQuestionsData?.data?.data[0].questions;

      const payload = {
        interNDAAnswers,
        interNDAQuestions,
      };

      dispatch(setLoading(false));
      return dispatch({
        type: POPULATE_INTERNDA_FORM,
        payload: payload,
      });
    } catch (error) {
      dispatch(setLoading(false));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const resetInterNDAForm = () => {
  return async (dispatch) => {
    return dispatch({
      type: RESET_INTERNDA_FORM,
      payload: {},
    });
  };
};

export const populatePreschoolScreening = (clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const clientMeasurement = await axios.get(api.getClientMeasurement('preschoolScreening', clientId));
      const preschoolScreeningForm = await axios.get(api.getMeasurementQuestions('preschoolScreening'));
      const preschoolScreeningQuestions = preschoolScreeningForm.data.data[0];

      let answers = null;
      if (isEmpty(clientMeasurement.data.data)) {
        answers = preschoolScreeningQuestions.table
          .filter((section) => section.questions)
          .reduce(
            (acc, section) => ({
              ...acc,
              [section.group]: section.questions.reduce(
                (questionAcc, question) => ({
                  ...questionAcc,
                  comment: '',
                  [question.key]: {
                    value: false,
                    note: '',
                  },
                }),
                {},
              ),
            }),
            {},
          );
      } else {
        answers = clientMeasurement.data.data[0].answers;
      }

      const payload = {
        preschoolScreeningAnswers: answers,
        preschoolScreeningQuestions: preschoolScreeningQuestions,
      };

      dispatch(setLoading(false));
      dispatch({
        type: POPULATE_PRESCHOOL_SCREENING,
        payload: payload,
      });
    } catch (error) {
      dispatch(setLoading(false));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

export const updateAnswersAction = (path, value) => {
  return async (dispatch, getState) => {
    const preschoolScreeningAnswers = getState().measurements.preschoolScreeningAnswers;
    const updatedAnswers = cloneDeep(preschoolScreeningAnswers);
    set(updatedAnswers, path, value);
    return dispatch({
      type: HANDLE_FORM_ANSWERS_CHANGE,
      payload: updatedAnswers,
    });
  };
};

export const saveMeasurement = (body) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    await axios.post(api.saveMeasurement, body);
    dispatch(setLoading(false));
  };
};

export const removeMeasurement = (measurementType, clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const data = {
      measurementType,
      clientId,
    };
    await axios.post(api.removeMeasurement, data);
    dispatch(setLoading(false));
  };
};

export const getMilestonesData = (clientId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getAdminClient(clientId));
    return dispatch({
      type: GET_MILESTONES_DATA,
      payload: data.data.milestones,
    });
  };
};

export const resetMilestonesData = () => {
  return async (dispatch) => {
    return dispatch({
      type: RESET_MILESTONES_DATA,
      payload: {},
    });
  };
};

export const getAccessories = () => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getAccessories);
    return dispatch({
      type: GET_ACCESSORIES,
      payload: data?.data,
    });
  };
};

export const createAccessory = (body, photo) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.post(api.createAccessory, body);
      if (data.signedUrl) {
        const bufferPhoto = Buffer.from(photo.split(',')[1], 'base64');
        await axios.put(data.signedUrl, bufferPhoto);
      }
    } catch (error) {
      showErrorMessage(error, strings.errors.createAccessory);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateAccessory = (body, id, photo) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.put(api.updateAccessory(id), body);
      if (data.signedUrl) {
        const bufferPhoto = Buffer.from(photo.split(',')[1], 'base64');
        await axios.put(data.signedUrl, bufferPhoto);
      }
    } catch (error) {
      showErrorMessage(error, strings.errors.updateAccessory);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const removeAccessory = (id) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    await axios.delete(api.removeAccessory(id));
    dispatch(setLoading(false));
  };
};

export const getMissingAccessories = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await axios.get(api.getMissingAccessories);
    dispatch(setLoading(false));
    return dispatch({
      type: GET_MISSING_ACCESSORIES,
      payload: data.data,
    });
  };
};

export const getMissingAccessoriesCount = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    let missingAccessoriesCount = null;
    const { username, role } = jwtDecode(localStorage.getItem('access-token'));
    if (role === 'admin') {
      const missingAccessoriesCountRaw = await axios.get(api.getMissingAccessoriesCount);
      const missingAccessoriesCountData = missingAccessoriesCountRaw.data.data;
      const userDataRaw = await axios.get(api.getAdminUser(username));
      const userData = userDataRaw.data.data;
      if (userData.accessoriesCoordinator) {
        if (userData.accessoriesCoordinator === 'regionalCoordinator') {
          missingAccessoriesCount = {
            count: missingAccessoriesCountData.regionalCoordinator?.[userData.region]?.count || 0,
            ids: missingAccessoriesCountData.regionalCoordinator?.[userData.region]?.ids || [],
          };
        } else {
          missingAccessoriesCount = {
            count: missingAccessoriesCountData.centralCoordinator?.count || 0,
            ids: missingAccessoriesCountData.centralCoordinator?.ids || [],
          };
        }
      }
    }

    dispatch(setLoading(false));

    return dispatch({
      type: GET_MISSING_ACCESSORIES_COUNT,
      payload: missingAccessoriesCount,
    });
  };
};

export const getRentedAccessoryHistory = (accessoryId, omamaId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await axios.get(api.getRentedAccessoryStatus(accessoryId, omamaId));
    dispatch(setLoading(false));
    return dispatch({
      type: GET_RENTED_ACCESSORY_HISTORY,
      payload: data.data.history,
    });
  };
};

export const getDevelopmentStage = (type) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getDevelopmentStage(type));

      return dispatch({
        type: GET_DEVELOPMENT_STAGE,
        payload: data.data,
      });
    } catch (error) {
      showErrorMessage(error, strings.errors.createAccessory);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const editDevelopmentStage = (body, newPhotos) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.put(api.editDevelopmentStage, body);
      const signedUrls = data.signedUrls;

      for (let i = 0; i < signedUrls.length; i++) {
        const bufferPhoto = Buffer.from(newPhotos[i].split(',')[1], 'base64');
        await axios.put(signedUrls[i], bufferPhoto);
      }
    } catch (error) {
      showErrorMessage(error, strings.errors.createAccessory);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
