import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import { api } from '../../conf';
import axios from 'axios';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { getDateDiff } from 'tools/date.tools';
import { strings } from 'strings/StringsProvider';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import PreschoolScreeningResults from './Measurements/PreschoolScreeningResults';
import InterNDAScoreTable from 'components/InterNDAScoreTable';

import './ClientMilestone.scss';

const ClientMilestone = ({
  milestoneInfo,
  milestoneData,
  birthDate,
  clientID,
  interNDA,
  preschoolScreening,
  updateMilestones,
}) => {
  const [edit, setEdit] = useState(false);
  const [note, setNote] = useState('');
  const [tempNote, setTempNote] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoId, setPhotoId] = useState(null);
  const [tempPhoto, setTempPhoto] = useState(null);
  const [showPhotoDetail, setShowPhotoDetail] = useState(false);

  const isAdmin = jwtDecode(localStorage.getItem('access-token')).role === 'admin';
  const { id, date, title, subtitle, color, iconType } = milestoneInfo;

  const dispatch = useDispatch();
  const changePage = (location) => dispatch(push(location));

  useEffect(() => {
    const loadMilestoneData = async () => {
      const milestoneNote = milestoneData?.[id]?.note || '';
      const milestonePhotoId = milestoneData?.[id]?.photo || null;
      setNote(milestoneNote);
      if (milestonePhotoId) {
        const { data } = await axios.get(api.getBase64Image('milestone', milestonePhotoId));
        setPhoto(data?.url);
        setPhotoId(milestonePhotoId);
      }
    };

    loadMilestoneData();
  }, [milestoneData, id]);

  const changeEdit = () => {
    setEdit(true);
    setTempNote(note);
    setTempPhoto(photo);
  };

  const saveEdit = () => {
    const updatedClientMilestones = {
      [id]: {
        ...milestoneData?.[id],
        note: tempNote,
      },
    };

    const changePhoto = {
      clientId: null,
      milestone: id,
      addPhoto: false,
      removePhoto: false,
      changePhoto: false,
      oldPhoto: photoId,
      newPhoto: tempPhoto,
    };

    if (!photo && tempPhoto) changePhoto.addPhoto = true;

    if (photo && !tempPhoto) changePhoto.removePhoto = true;

    if (photo && tempPhoto && photo !== tempPhoto) changePhoto.changePhoto = true;

    setNote(tempNote);
    setPhoto(tempPhoto);
    setTempNote('');
    setTempPhoto(null);
    setEdit(false);

    updateMilestones(updatedClientMilestones, changePhoto);
  };

  const cancelEdit = () => {
    setTempNote('');
    setTempPhoto(null);
    setEdit(false);
  };

  const loadImage = async (event, useWebWorker = true) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    let options = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation,
    };
    const output = await imageCompression(file, options);

    const base64ImagePhoto = await imageCompression.getDataUrlFromFile(output);
    setTempPhoto(base64ImagePhoto);
  };

  const getFormatedAge = (birthDate, date) => {
    const { months } = getDateDiff(dayjs(birthDate).format('YYYY-MM-DD'), dayjs(date).format('YYYY-MM-DD'));

    const years = Math.floor(months / 12);
    const month = months % 12;

    let age = '(';
    if (years === 1) age += `${years} ${strings.year.toLowerCase()} a`;
    else if (years >= 2 && years <= 4) age += `${years} ${strings.years} a`;
    else if (years !== 0) age += `${years} ${strings.years1} a`;

    if (years !== 0) age += ' ';

    if (month === 1) age += `${month} ${strings.oneMonth}`;
    else if (month >= 2 && month <= 4) age += `${month} ${strings.twoTillFourMonths}`;
    else age += `${month} ${strings.zeroOrFiveMonths}`;

    age += ')';

    return age;
  };

  const renderDate = () => {
    return (
      <div className="milestone-time">
        <div className="milestone-date">{dayjs(date).format('D. M. YYYY')}</div>
        <div className="milestone-age">{getFormatedAge(birthDate, date)}</div>
      </div>
    );
  };

  const renderIconImage = (iconType) => {
    let iconClassname = '';
    if (id === 'interNDAMeasurement' || id === 'preschoolScreening' || id === 'programEnd') {
      iconClassname = 'milestone-icon-small';
    } else {
      iconClassname = 'milestone-icon';
    }
    return <img alt="milestone-icon" className={iconClassname} src={`/images/Icons/${iconType}`} />;
  };

  const displayMeasurement = () => {
    if (id === 'interNDAMeasurement') {
      changePage(`/admin/klient/${clientID}/merania/interNDA`);
    } else {
      changePage(`/admin/klient/${clientID}/merania/predskolsky-skrining`);
    }
  };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date={renderDate()}
      iconStyle={{ background: color, color: '#fff' }}
      icon={renderIconImage(iconType)}
    >
      <div className="milestone-header">
        <h3 className="vertical-timeline-element-title">{title}</h3>
        {!edit && isAdmin && (
          <div className="milestone-action-icons-container">
            <div className="milestone-edit-icon" onClick={changeEdit}>
              <img src="/images/Icons/edit-icon.svg" alt="Edit icon" className="milestone-action-icon" />
            </div>
            {(id === 'interNDAMeasurement' || id === 'preschoolScreening') && (
              <div className="milestone-edit-icon" onClick={displayMeasurement}>
                <img
                  src="/images/Icons/measurement-icon.svg"
                  alt="Measurement icon"
                  className="milestone-action-icon"
                />
              </div>
            )}
          </div>
        )}
      </div>

      {id === 'birth' ? (
        <h4 className="vertical-timeline-element-subtitle">
          {subtitle.split('\n').map((subtitleLine, i) => (
            <div key={i}>{subtitleLine}</div>
          ))}
        </h4>
      ) : (
        <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
      )}
      {edit ? (
        <textarea
          className="milestone-textarea"
          value={tempNote}
          placeholder={strings.addComment}
          onChange={(event) => setTempNote(event.target.value)}
        />
      ) : (
        note !== '' && <p>{note}</p>
      )}
      {!edit && photo && (
        <div className="milestone-photo-container" onClick={() => setShowPhotoDetail(true)}>
          <img alt="" className="milestone-photo" src={photo} />
        </div>
      )}
      {edit && !tempPhoto && (
        <div>
          <div className="milestone-choose-photo">
            <label htmlFor="photogallery" className="pointer">
              <img alt="milestone-photoIcon" className="milestone-photoIcon" src="/images/Icons/Galeria_ikona.png" />
              <div>{strings.choosePhoto}</div>
            </label>
          </div>

          <input
            id="photogallery"
            value={[]}
            type="file"
            accept="image/*"
            onChange={loadImage}
            style={{ display: 'none' }}
          ></input>
        </div>
      )}
      {edit && tempPhoto && (
        <div className="milestone-photo-container new-photo">
          <img alt="" className="milestone-photo" src={tempPhoto} />
          <div className="milestone-remove-photo" onClick={() => setTempPhoto(null)}>
            <CloseOutlined />
          </div>
        </div>
      )}
      {edit && (
        <div className="save-milestone-change">
          <div onClick={saveEdit}>
            <Button>{strings.save}</Button>
          </div>
          <div onClick={cancelEdit}>
            <Button type="secondary">{strings.cancelChanges}</Button>
          </div>
        </div>
      )}
      {id === 'interNDAMeasurement' && (
        <InterNDAScoreTable
          cognitionScore={interNDA.cognition}
          fineMotorScore={interNDA.fineMotor}
          grossMotorScore={interNDA.grossMotor}
          languageScore={interNDA.language}
          positiveBehaviourScore={interNDA.positiveBehaviour}
          negativeBehaviourScore={interNDA.negativeBehaviour}
          showInMilestone={true}
        />
      )}
      {id === 'preschoolScreening' && (
        <PreschoolScreeningResults preschoolScreeningAnswers={preschoolScreening.answers} showInMilestone={true} />
      )}

      <Modal
        className="milestone-photo-detail"
        open={showPhotoDetail}
        onCancel={() => setShowPhotoDetail(false)}
        footer={null}
        centered={true}
      >
        {showPhotoDetail && (
          <div className="milestone-photo-detail-image">
            <img alt="photoDetail" src={photo} />
          </div>
        )}
      </Modal>
    </VerticalTimelineElement>
  );
};

ClientMilestone.propTypes = {
  milestoneInfo: PropTypes.object,
  milestoneData: PropTypes.object,
  birthDate: PropTypes.string,
  clientID: PropTypes.string,
  interNDA: PropTypes.object,
  preschoolScreening: PropTypes.object,
  updateMilestones: PropTypes.func,
};

export default ClientMilestone;
