import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { getOmamaTokenAction } from '../../actions/omama.actions';
import { Button } from 'antd';
import Base64Image from '../../components/Base64Image';
import { strings } from '../../strings/StringsProvider';
import PropTypes from 'prop-types';
import './index.scss';

function Settings({ location }) {
  const omama = useSelector((state) => state.omama.omama);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!omama.username) {
      dispatch(getOmamaTokenAction());
    }
  }, [dispatch, omama]);

  const path = location.pathname.substr(0, location.pathname.lastIndexOf('/'));
  const changePage = (location) => dispatch(push(location));

  return (
    <div
      className={classNames('settings-container settings-menu', {
        admin: path === '/admin',
      })}
    >
      <div className="settings-top-container" data-test-id="settings-info">
        <div className="settings-user-pic">
          {omama.username && (
            <Base64Image
              className="settings-profile-pic"
              type="user"
              backUpImage="/images/cestavonLOGO_unofficial.png"
              imageID={omama.username}
            />
          )}
        </div>
        <div className="settings-user-name">
          <div className="settings-meno" data-test-id="settings-name">
            {omama.name}
          </div>
          <div className="settings-mesto" data-test-id="settings-town">
            {omama.city}
          </div>
        </div>
      </div>
      <div className="settings-button-container" data-test-id="settings-buttons">
        <Button
          className="settings-button settings-menu-button"
          type="primary"
          shape="round"
          onClick={() => changePage(path + '/nastavenia/heslo')}
          data-test-id="settings-changePassword"
        >
          {strings.changePassword}
        </Button>
        <Button
          className="settings-button settings-menu-button"
          type="primary"
          shape="round"
          onClick={() => changePage(path + '/nastavenia/profil')}
          data-test-id="settings-changeProfilePicture"
        >
          {strings.changeProfilePicture}
        </Button>
        <Button
          className="settings-button settings-menu-button"
          type="primary"
          shape="round"
          onClick={() => changePage(path + '/nastavenia/problem')}
          data-test-id="settings-reportIssue"
        >
          {strings.reportIssue}
        </Button>
      </div>
    </div>
  );
}

Settings.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any,
  }),
};

export default Settings;
