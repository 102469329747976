import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from 'conf/api';
import { keyBy } from 'lodash';

export const getAllScreeningQuestions = createAsyncThunk('screenings/getAllScreeningQuestions', async () => {
  const { data } = await axios.get(api.getAllScreeningTests);
  return data;
});

export const screeningsSlice = createSlice({
  name: 'screenings',
  initialState: {
    status: 'initial',
    screeningTests: {},
  },
  extraReducers: {
    [getAllScreeningQuestions.pending]: (state) => {
      state.status = 'pending';
    },
    [getAllScreeningQuestions.fulfilled]: (state, action) => {
      state.status = 'fulfilled';
      state.screeningTests = keyBy(action.payload.data, '_id');
    },
    [getAllScreeningQuestions.rejected]: (state) => {
      state.status = 'rejected';
    },
  },
});

export default screeningsSlice.reducer;
