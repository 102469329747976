import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, TimePicker, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { strings } from '../strings/StringsProvider';
import classNames from 'classnames';
import imageCompression from 'browser-image-compression';
import Base64Image from './Base64Image';

import {
  changeDateAction,
  changeTimeSpentAction,
  changeCommentAction,
  loadActionPhotos,
  removeActionPhotoAction,
} from '../actions/omama.actions';
import { cancelActionAction, setClientLoading } from '../actions/clientActivities.action';

import './Action.scss';

function Action({ action, showActiontModalEnd }) {
  const [actionDate, setActionDate] = useState(action.date);
  const [actionTime, setActionTime] = useState(dayjs(action.date));
  const [timeSpent, setTimeSpent] = useState(action.timeSpent);
  const [visible, setVisible] = useState(false);
  const [visibleComment, setVisibleComment] = useState(false);
  const [comment, setComment] = useState(action.comment || action.differentAction);
  const [photos, setPhotos] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [datesTaken, setDatesTaken] = useState([]);
  const [photoDetail, setPhotoDetail] = useState({ visible: false, image: null });

  const startWeek = useSelector((state) => state.omama.startWeek);
  const endWeek = useSelector((state) => state.omama.endWeek);
  const actionPhotos = useSelector((state) => state.omama.actionPhotos);

  const uploadedPhotos = actionPhotos.filter((photo) => photo._id.endsWith('_photo'));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadActionPhotos('omama', action._id));
  }, [dispatch, action._id]);

  const showTimeModal = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const handleModalCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const changeAction = useCallback(() => {
    setVisible(false);
    const actionDateFormatted = actionDate.split('T')[0];
    const actionTimeFormatted = actionTime.format('HH:mm');
    const date = dayjs(actionDateFormatted + 'T' + actionTimeFormatted).format();
    dispatch(
      changeDateAction(
        action._id,
        date,
        dayjs(actionDate).startOf('week').format('YYYY-MM-DD'),
        dayjs(actionDate).endOf('week').format('YYYY-MM-DD'),
      ),
    );
  }, [actionDate, actionTime, action._id, dispatch, setVisible]);

  const changeComment = useCallback(() => {
    setVisibleComment(false);
    dispatch(
      changeCommentAction(
        action._id,
        comment,
        dayjs(actionDate).startOf('week').format('YYYY-MM-DD'),
        dayjs(actionDate).endOf('week').format('YYYY-MM-DD'),
      ),
    );
  }, [action, actionDate, comment, dispatch, setVisibleComment]);

  const cancel = useCallback(() => {
    dispatch(cancelActionAction(action, startWeek, endWeek));
    showActiontModalEnd();
  }, [action, dispatch, endWeek, showActiontModalEnd, startWeek]);

  const compressImage = async (event, useWebWorker = true) => {
    dispatch(setClientLoading(true));
    const file = event.target.files[0];

    if (!file) {
      dispatch(setClientLoading(false));
      return;
    }

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    const imageOptions = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation,
    };
    const thumbnailOptions = {
      maxSizeMB: 0.2,
      useWebWorker: useWebWorker,
      exifOrientation: orientation,
    };

    const output = await imageCompression(file, imageOptions);
    const thumbnail = await imageCompression(file, thumbnailOptions);

    const base64ImagePhoto = await imageCompression.getDataUrlFromFile(output);
    const base64ImageThumbnail = await imageCompression.getDataUrlFromFile(thumbnail);

    setPhotos([...photos, base64ImagePhoto]);
    setThumbnails([...thumbnails, base64ImageThumbnail]);
    setDatesTaken([...datesTaken, file.lastModified || null]);
    dispatch(setClientLoading(false));
  };

  const deleteImage = (photoIndex) => {
    const tempPhotos = [...photos];
    const tempThumbnails = [...thumbnails];
    const tempDatesTaken = [...datesTaken];
    tempPhotos.splice(photoIndex, 1);
    tempThumbnails.splice(photoIndex, 1);
    tempDatesTaken.splice(photoIndex, 1);
    setPhotos(tempPhotos);
    setThumbnails(tempThumbnails);
    setDatesTaken(tempDatesTaken);
  };

  const showPhotoDetail = (image) => {
    setPhotoDetail({ visible: true, image });
  };

  const hidePhotoDetail = () => {
    setPhotoDetail({ visible: false, image: null });
  };

  const removeUploadedPhoto = () => {
    const photoToRemove = uploadedPhotos.find((photo) => photo._id === photoDetail.image._id);
    const omamaID = photoToRemove.omamaID;
    const photoID = photoToRemove._id.replace('_photo', '');
    dispatch(removeActionPhotoAction(omamaID, photoID, action._id, action.id));

    hidePhotoDetail();
  };

  return (
    <div className="clientsActivities">
      <div className="clientHeader">{strings.action}</div>
      <div className="actionHeader">
        <CloseOutlined className="actionCloseIcon" onClick={() => showActiontModalEnd()} />
        <div>{dayjs(action.date).format('dddd D. M. YYYY, HH:mm')}</div>
        <div>{typeof action.id === 'string' ? action.id : strings.unknowAction}</div>
        <div>{action.comment || action.differentAction}</div>
      </div>

      {/* Aktivity */}
      <div className="activities">
        {(action.id === strings.parentalClub1 || action.id === strings.otherJob) && (
          <div className="photoSectionGroup">
            <div className="addActionPhotoSection">
              <div className="activityHeadline">{strings.addPhoto}</div>
              <div className="photoButtons">
                <div
                  className={classNames('photoButton', {
                    disabledPhotoButton: photos.length + uploadedPhotos.length >= 3,
                  })}
                >
                  <label htmlFor="photogallery">
                    <img alt="addphotofromgallery" className="photoIcon" src="/images/Icons/Galeria_ikona.png" />
                    <div>{strings.gallery}</div>
                  </label>
                </div>
                <input
                  id="photogallery"
                  value={[]}
                  type="file"
                  accept="image/*"
                  onChange={compressImage}
                  style={{ display: 'none' }}
                />
                <div
                  className={classNames('photoButton', {
                    disabledPhotoButton: photos.length + uploadedPhotos.length >= 3,
                  })}
                >
                  <label htmlFor="photoCamera">
                    <img alt="addphotofromcamera" className="photoIcon" src="/images/Icons/Galeria_ikona.png" />
                    <div>{strings.camera}</div>
                  </label>
                </div>
                <input
                  id="photoCamera"
                  value={[]}
                  type="file"
                  accept="image/*;capture=camera"
                  onChange={compressImage}
                  style={{ display: 'none' }}
                />
              </div>
              {thumbnails && (
                <div className="tempPhotos">
                  {photos.map((item, i) => (
                    <div key={i} className="photo-container">
                      <img className="clientEndImg" alt="" src={item}></img>
                      <div className="xButton" onClick={() => deleteImage(i)}>
                        <CloseOutlined />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {uploadedPhotos.length > 0 && (
              <div className="showActionPhotoSection">
                <div className="activityHeadline">{strings.photo + ' ' + strings.fromPreviousAction}</div>
                <div className="ActionDetailAdmin-tempPhotos">
                  {uploadedPhotos.map((photo, i) => (
                    <div key={i} className="ActionDetailAdmin-photo" onClick={() => showPhotoDetail(photo)}>
                      <Base64Image
                        className="imageWrapper"
                        key={i}
                        type="gallery-omama"
                        imageID={photo._id}
                        showDate={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        <Modal
          className="modalGallery"
          open={photoDetail.visible}
          onCancel={hidePhotoDetail}
          footer={null}
          closable={true}
        >
          <div id="modalGallery">
            <Base64Image
              key={photoDetail.image?._id}
              type="gallery-omama"
              imageID={photoDetail.image?._id}
              showDate={true}
            />
            <Popconfirm
              title={strings.deletePhotoQuestion}
              okText={strings.yes}
              cancelText={strings.no}
              okType="default"
              onConfirm={removeUploadedPhoto}
            >
              <Button type="primary" shape="round">
                {strings.deletePicture}
              </Button>
            </Popconfirm>
          </div>
        </Modal>

        <div className="clientButtons" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button shape="round" onClick={showTimeModal}>
            {strings.changeTime}
          </Button>
          <Modal
            title={strings.changeTimeOfAction}
            open={visible}
            onOk={() => changeAction()}
            okText={strings.confirm}
            okButtonProps={{
              disabled: !(typeof actionDate === 'string') || actionTime === null,
            }}
            onCancel={handleModalCancel}
            cancelText={strings.cancel}
          >
            <Input
              type="date"
              value={dayjs(actionDate).format('YYYY-MM-DD')}
              onChange={(value) => setActionDate(value.target.value)}
              className="picker"
            />
            <br />
            <br />
            <TimePicker
              defaultValue={dayjs('12:00', 'HH:mm')}
              type="time"
              format={'HH:mm'}
              minuteStep={15}
              onChange={(value) => setActionTime(value)}
              value={actionTime}
              needConfirm={false}
              className="action-modal-picker"
              placeholder="Vyber čas"
            />
          </Modal>

          <Button shape="round" onClick={() => setVisibleComment(true)}>
            {strings.editComment}
          </Button>

          <Modal
            title={strings.editComment}
            open={visibleComment}
            onCancel={() => setVisibleComment(false)}
            cancelText={strings.cancel}
            onOk={() => changeComment()}
            okText={strings.confirm}
            okButtonProps={{
              disabled: comment === (action.comment || action.differentAction),
            }}
          >
            <div className="changeCommentContainer">
              <textarea
                value={comment}
                className="changeCommentInput"
                onChange={(value) => setComment(value.target.value)}
              />
            </div>
          </Modal>

          <Button shape="round" onClick={cancel}>
            {strings.removeAction}
          </Button>
        </div>
        <div className="activityHeadline">{strings.spentTime}</div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Input
            type="number"
            id="timeSpent"
            style={{ width: '80px' }}
            onChange={(value) => setTimeSpent(value.target.value)}
            value={timeSpent}
            step={0.5}
            min={0}
          />
          <div style={{ paddingTop: '2%', paddingLeft: '2%', color: '#41aea6' }}>h</div>
        </div>
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <Button
            shape="round"
            className="action-button-save"
            onClick={() => {
              dispatch(
                changeTimeSpentAction(
                  action._id,
                  timeSpent,
                  startWeek,
                  endWeek,
                  action.omamaID,
                  photos,
                  thumbnails,
                  datesTaken,
                  action.id,
                ),
              );
              showActiontModalEnd();
            }}
          >
            {strings.save}
          </Button>
        </div>
      </div>
    </div>
  );
}

Action.propTypes = {
  showActiontModalEnd: PropTypes.func,
  action: PropTypes.object,
};

export default Action;
