import React, { useState } from 'react';
import { strings } from '../../strings/StringsProvider';
import { Modal } from 'antd';
import { jwtDecode } from 'jwt-decode';
import DevelopmentStageInfo from 'components/DevelopmentStageInfo';

import '../../index.scss';
import './DevelopmentStages.scss';

const developmentStages = {
  '2.PP': {
    title: `do 4. ${strings.week3}`,
    type: '2.PP',
  },
  '3.PP': {
    title: `5-7. ${strings.week4}`,
    type: '3.PP',
  },
  '4.PP': {
    title: `8-10. ${strings.week4}`,
    type: '4.PP',
  },
  '5.PP': {
    title: `3-4. ${strings.oneMonth}`,
    type: '5.PP',
  },
  '6.PP': {
    title: `5-6. ${strings.oneMonth}`,
    type: '6.PP',
  },
  '7.PP': {
    title: `7-8. ${strings.oneMonth}`,
    type: '7.PP',
  },
  '8.PP': {
    title: `9-10. ${strings.oneMonth}`,
    type: '8.PP',
  },
  '9.PP': {
    title: `11-12. ${strings.oneMonth}`,
    type: '9.PP',
  },
  '10.PP': {
    title: `15-18. ${strings.oneMonth}`,
    type: '10.PP',
  },
  '11.PP': {
    title: `26-35. ${strings.oneMonth}`,
    type: '11.PP',
  },
  '12.PP': {
    title: `36-40. ${strings.oneMonth}`,
    type: '12.PP',
  },
};

function DevelopmentStages() {
  const [developmentStageDetail, setDevelopmentStageDetail] = useState(null);
  const isOmama = jwtDecode(localStorage.getItem('access-token')).role === 'omama';

  const developmentStageDetailClose = () => {
    setDevelopmentStageDetail(null);
  };

  return (
    <div className={isOmama ? 'plainBackground' : 'statsBackground'}>
      <div className="adminBox">
        <div className="development-stages-main">
          {Object.keys(developmentStages).map((key, index) => (
            <div
              key={index}
              onClick={() => setDevelopmentStageDetail(developmentStages[key].type)}
              className="developmentStage"
              data-test-id={`developmentStages-${key}`}
            >
              {developmentStages[key].title}
            </div>
          ))}
          <Modal
            centered
            title={strings.developmentStageDescription}
            open={developmentStageDetail !== null}
            onCancel={developmentStageDetailClose}
            onOk={developmentStageDetailClose}
            footer={null}
          >
            {developmentStageDetail && <DevelopmentStageInfo type={developmentStageDetail} />}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentStages;
