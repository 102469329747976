import React from 'react';
import './GalleryCard.scss';
import PropTypes from 'prop-types';
import Base64Image from './Base64Image';

function GalleryCard({ photo, name, changePage }) {
  return (
    <div className="galleryCard" onClick={() => changePage()}>
      {photo ? (
        <>
          <Base64Image key={photo} className="galleryCardImage" type="user" imageID={photo} />
          <h3 className="galleryCardImageName">{name}</h3>
        </>
      ) : (
        <h3 className="galleryActionFolderName">{name}</h3>
      )}
    </div>
  );
}

GalleryCard.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  changePage: PropTypes.func,
};

export default GalleryCard;
