import dayjs from 'dayjs';

export const groupActions = (data) => {
  const tempClientActionsByDay = {};
  const tempActionsByDay = {};
  const tempAllActionsByDay = {};

  data.forEach((el) => {
    const day = dayjs(el.date).format('D.M.');
    if (tempAllActionsByDay[day]) {
      tempAllActionsByDay[day].push(el);
    } else {
      tempAllActionsByDay[day] = [el];
    }
    if (el.client) {
      if (tempClientActionsByDay[day]) {
        tempClientActionsByDay[day].push(el);
      } else {
        tempClientActionsByDay[day] = [el];
      }
    } else {
      if (tempActionsByDay[day]) {
        tempActionsByDay[day].push(el);
      } else {
        tempActionsByDay[day] = [el];
      }
    }
  });

  const actionsByDay = [];
  const clientActionsByDay = [];
  const allActionsByDay = [];

  Object.keys(tempActionsByDay).forEach((item) =>
    actionsByDay.push({
      day: item,
      Actions: tempActionsByDay[item],
    }),
  );
  Object.keys(tempClientActionsByDay).forEach((item) =>
    clientActionsByDay.push({
      day: item,
      Actions: tempClientActionsByDay[item],
    }),
  );

  Object.keys(tempAllActionsByDay).forEach((item) =>
    allActionsByDay.push({
      day: item,
      Actions: tempAllActionsByDay[item],
    }),
  );
  return {
    result: {
      tempActionsByDay: tempActionsByDay,
      allActionsByDay: allActionsByDay,
      actionsByDay: actionsByDay,
      clientActionsByDay: clientActionsByDay,
    },
  };
};
