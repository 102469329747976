import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import {
  getScreeningQuestions,
  persistScreeningQuestions,
  getOneTypeOneClientScreenings,
} from '../../../actions/omama.actions';
import { setLoading } from '../../../actions/status.actions';
import { Button, Divider, Modal, Tooltip } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import './screeningQuestions.scss';
import { ScreeningSections } from './screeningSections';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { api } from '../../../conf';
import { jwtDecode } from 'jwt-decode';
import { calculateScreeningDate } from 'tools/date.tools';

import ActionDetailStat from '../../../components/ActionDetailStat';
import { strings } from '../../../strings/StringsProvider';
import '../../../components/ActionDetail.scss';

function ScreeningQuestionsScreen({
  getScreeningQuestions,
  screeningId,
  clientId,
  questionsById,
  router,
  changePage,
  getOneTypeOneClientScreenings,
  oneTypeOneClientScreening,
  setLoading,
  isLoading,
  persistScreeningQuestions,
  from,
  to,
  screening,
  goBack,
  questions,
}) {
  const [currentScreeningID, setCurrentScreeningID] = useState(undefined);
  const [answers, setAnswers] = useState(undefined);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getScreeningQuestions && getScreeningQuestions(screeningId, clientId);
  }, [getScreeningQuestions, screeningId, clientId]);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (questionsById && !isEmpty(questionsById)) {
        await getOneTypeOneClientScreenings(clientId, screeningId);
      }
    };

    fetchAnswers();
  }, [questionsById, clientId, screeningId, getOneTypeOneClientScreenings]);

  const navigateToPage = () =>
    router.location.pathname.startsWith('/omama')
      ? changePage(`/omama/clientProfiles/${clientId}`)
      : changePage(`/admin/klienti/${clientId}/skriningy`);

  const getDefaultAnswers = useCallback(
    () =>
      Object.keys(questionsById).reduce((acc, curId) => {
        return {
          ...acc,
          [curId]: {
            value: false,
            wasClicked: undefined,
            isYesPositiveAnswer: undefined,
          },
        };
      }, {}),
    [questionsById],
  );

  useEffect(() => {
    const latestScreening = oneTypeOneClientScreening;
    const currentAnswers = getDefaultAnswers();

    if (!isEmpty(latestScreening)) {
      for (const element of latestScreening.answers) {
        if (currentAnswers[element.id]) {
          currentAnswers[element.id].isYesPositiveAnswer = questionsById[element.id]?.yesIsPositiveAnswer;

          const vyhodnotene = element.answer;
          const maBytZakliknute = !!(
            (questionsById[element.id]?.yesIsPositiveAnswer && vyhodnotene) ||
            (!questionsById[element.id]?.yesIsPositiveAnswer && !vyhodnotene)
          );
          currentAnswers[element.id].value = maBytZakliknute;
          currentAnswers[element.id].wasClicked = maBytZakliknute ? strings.yes : strings.no;
        }
      }
      setAnswers(currentAnswers);
      setCurrentScreeningID(latestScreening._id);
    } else {
      setAnswers(currentAnswers);
      setCurrentScreeningID(null);
    }
  }, [questionsById, oneTypeOneClientScreening, getDefaultAnswers]);

  const deleteScreening = async () => {
    const data = {
      id: currentScreeningID,
    };
    try {
      setLoading(true);
      await axios.post(api.deleteScreening, data);
      if (questionsById) {
        await getOneTypeOneClientScreenings(clientId, screeningId);
        setAnswers(getDefaultAnswers());
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const changeQuestionStatus = (questionId, newStatusLabel, isYesPositiveAnswer) => {
    const answerValue = newStatusLabel === strings.yes;
    setAnswers({
      ...answers,
      [questionId]: {
        value: answerValue,
        wasClicked: newStatusLabel,
        isYesPositiveAnswer: isYesPositiveAnswer,
      },
    });
  };

  const persistQuestions = () => {
    const screeningNumber = Number(screeningId.split('.')[0]);

    const answersForApi = Object.keys(answers).map((answerId) => {
      const answer = answers[answerId];
      let answerValue = answer.value;

      if (answer.isYesPositiveAnswer === false) {
        // napr otazka v tvare mate obavy ?
        // admin view len rata, kolko bolo false
        answerValue = !answer.value;
      }

      return { id: answerId, answer: answerValue };
    });

    let vyvinovehoSkore = 0;
    let specifickeSpravanie = 0;
    let obavy = 0;

    // answersForApi.forEach(answer => {
    //   let answerFields = answer.id.split('_');
    //   switch (true) {
    //     case answerFields[1].startsWith('Z'):
    //       if (answer.answer) specifickeSpravanie++;
    //       break;
    //     case answerFields[1].startsWith('0'):
    //       if (answer.answer === false) obavy++;
    //       break;
    //     default:
    //       if (answer.answer === false) vyvinovehoSkore++;
    //       break;
    //   }
    // });
    for (const answer of answersForApi) {
      let answerFields = answer.id.split('_');
      switch (true) {
        case screeningNumber >= 11 && answerFields[1].startsWith('Z'):
          if (answer.answer === true) vyvinovehoSkore = vyvinovehoSkore + 1;
          break;
        case (screeningNumber < 11 && answerFields[1].startsWith('Z')) || answerFields[1].startsWith('ŠS'):
          if (answer.answer === false) specifickeSpravanie = specifickeSpravanie + 1;
          break;
        case answerFields[1].startsWith('0') || answerFields[1].startsWith('O'):
          // pozn. Ked je 0 (nula) tak nam zobrauje obavy v skrinigoch od 2 po 10 ale nei v 11 a 12; tak ako v tabulke
          if (answer.answer === false) obavy = obavy + 1;
          break;
        default:
          if (answer.answer) vyvinovehoSkore = vyvinovehoSkore + 1;
          break;
      }
    }
    const pasmaResults = {
      vyvinovehoSkore: { body: vyvinovehoSkore, pasmo: '' },
      specifickeSpravanie: { body: specifickeSpravanie, pasmo: '' },
      obavy: { body: obavy, pasmo: '' },
    };

    // Nastavenie pasma pre vyvinovehoSkore spravanie
    switch (screeningId) {
      case '2.PP':
        if (pasmaResults.vyvinovehoSkore.body > 8) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 5) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '3.PP':
        if (pasmaResults.vyvinovehoSkore.body > 7) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 5) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '4.PP':
        if (pasmaResults.vyvinovehoSkore.body > 8) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 6) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '5.PP':
        if (pasmaResults.vyvinovehoSkore.body > 8) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 6) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '6.PP':
        if (pasmaResults.vyvinovehoSkore.body > 10) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 9) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '7.PP':
        if (pasmaResults.vyvinovehoSkore.body > 9) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 7) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '8.PP':
        if (pasmaResults.vyvinovehoSkore.body > 8) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 6) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '9.PP':
        if (pasmaResults.vyvinovehoSkore.body > 9) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 6) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '10.PP':
        if (pasmaResults.vyvinovehoSkore.body > 10) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 9) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '11.PP':
        if (pasmaResults.vyvinovehoSkore.body > 16) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 14) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;
      case '12.PP':
        if (pasmaResults.vyvinovehoSkore.body > 17) {
          pasmaResults.vyvinovehoSkore.pasmo = 'green';
        } else if (pasmaResults.vyvinovehoSkore.body < 16) {
          pasmaResults.vyvinovehoSkore.pasmo = 'red';
        } else {
          pasmaResults.vyvinovehoSkore.pasmo = 'orange';
        }
        break;

      default:
        // console.log('DEFAULT SWITCH pasma');
        break;
    }
    // Nastavenie pasma pre Specificke spravanie
    if (pasmaResults.specifickeSpravanie.body > 3) {
      pasmaResults.specifickeSpravanie.pasmo = 'red';
    } else if (pasmaResults.specifickeSpravanie.body < 2) {
      pasmaResults.specifickeSpravanie.pasmo = 'green';
    } else {
      pasmaResults.specifickeSpravanie.pasmo = 'orange';
    }
    // Nastavenie pasma pre OBAVY spravanie
    if (pasmaResults.obavy.body > 1) {
      pasmaResults.obavy.pasmo = 'red';
    } else if (pasmaResults.obavy.body < 1) {
      pasmaResults.obavy.pasmo = 'green';
    } else {
      pasmaResults.obavy.pasmo = 'orange';
    }

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('Sending screening answers with isPositiveAnswer logic', answersForApi);
    }
    persistScreeningQuestions(screeningId, clientId, answersForApi, pasmaResults, navigateToPage);
  };

  const renderScreeningLabel = () => (
    <div style={{ marginTop: '10vw' }} data-test-id="admin-adminScreening-warning">
      <div>
        <b>{strings.warning}:</b> {strings.textForWarning}
      </div>
      <br />
      {strings.dearParents}, <br /> {strings.textForDearParents} <b>{strings.textForDearParents1}</b>{' '}
      {strings.textForDearParents2}&nbsp;
      <b>{strings.textForDearParents3}</b>
      <br />
      <br />
      {strings.textForDearParents4}
      <b> {strings.textForDearParents5}</b>
    </div>
  );

  const renderScreeningHeader = () => {
    return (
      <div data-test-id="admin-adminScreening-detailStat" style={{ width: '100%' }}>
        {router?.location?.pathname?.startsWith('/admin') && (
          <div>
            <div style={{ paddingTop: '40px', color: '#41AEA6', fontSize: '11px' }} onClick={() => goBack()}>
              <LeftOutlined style={{ position: 'absolute', top: '43px' }} />
              <span style={{ paddingLeft: '15px' }}>{strings.back1}</span>
            </div>
            {currentScreeningID !== null && (
              <div style={{ marginTop: '25px', borderBottom: '2px solid #41AEA6', textAlign: 'center' }}>
                <ActionDetailStat
                  title={`${strings.createdBy}:`}
                  firstInput={oneTypeOneClientScreening?.createdByRole}
                  secondInput={oneTypeOneClientScreening?.createdByName}
                ></ActionDetailStat>
                <ActionDetailStat
                  title={`${strings.date}:`}
                  firstInput={dayjs(oneTypeOneClientScreening?.created).format('DD.MM.YYYY')}
                ></ActionDetailStat>
              </div>
            )}
          </div>
        )}
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '5vw',
          }}
        >
          {router?.location?.pathname?.startsWith('/omama') && (
            <LeftOutlined
              onClick={() => goBack()}
              style={{
                position: 'relative',
                top: '30px',
                marginBottom: '3vw',
                fontSize: '20px',
                marginRight: '13px',
              }}
            />
          )}
          <h2 style={{ color: '#41aea6', fontWeight: '600' }}>
            {strings.developmentScreening} {screeningId}
          </h2>
        </span>
        <span
          style={{
            fontSize: '11px',
            textAlign: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {strings.questionarForParents} <br />
          {from}.-{to}. {strings.zeroOrFiveMonths}
        </span>
      </div>
    );
  };

  const checkCanSubmit = () => {
    if (questionsById && answers) {
      return !!Object.keys(answers)
        .map((id) => answers[id])
        .every((answer) => answer.wasClicked !== undefined);
    } else {
      return false;
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    await deleteScreening();
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const userRole = jwtDecode(localStorage.getItem('access-token')).role;
  const isAdminSupervisorMentor = userRole === 'admin' || userRole === 'supervisor' || userRole === 'mentor';

  return (
    <div className="animated bounceInUp screening-screen" data-test-id="admin-adminScreening-screening">
      {renderScreeningHeader()}
      {renderScreeningLabel()}
      <Divider style={{ border: '1px solid #41aea6' }} />

      <ScreeningSections
        questions={questions}
        answers={answers}
        changeQuestionStatus={changeQuestionStatus}
        clientId={clientId}
        screening={screening}
      />

      <Modal
        title={strings.screeningDeletionQuestion}
        open={visible}
        onCancel={handleCancel}
        cancelText={strings.keep}
        onOk={handleOk}
        okText={strings.delete}
        okType="danger"
      >
        <div>{strings.screeningModalText}</div>
      </Modal>

      {!isAdminSupervisorMentor ? (
        <>
          <Button className="saveButton" shape="round" disabled={!currentScreeningID} onClick={showModal}>
            {strings.deleteScreening}
          </Button>
          <br />
          <>
            <Tooltip title={!checkCanSubmit(answers) && strings.saveScreeningTooltip} placement="bottomRight">
              <span>
                <Button
                  className="saveButton"
                  disabled={!checkCanSubmit(answers)}
                  shape="round"
                  loading={isLoading}
                  onClick={persistQuestions}
                >
                  {strings.save}
                </Button>
              </span>
            </Tooltip>
          </>
        </>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { screeningId = null, clientId = null } = ownProps?.match?.params || {};

  return {
    router: state.router,
    questionsById: state.omama.questionsById,
    questions: Object.keys(state.omama.questionsById).map((id) => state.omama.questionsById[id]),
    isLoading: state.omama.loading,
    screeningId,
    clientId,
    from:
      state.omama.screening &&
      Math.round(calculateScreeningDate(dayjs(), state.omama.screening.fromDay).diff(dayjs(), 'month')),
    to:
      state.omama.screening &&
      Math.round(calculateScreeningDate(dayjs(), state.omama.screening.toDay).diff(dayjs(), 'month')),
    screening: state.omama.screening,
    goBack: ownProps.history.goBack,
    oneTypeOneClientScreening: state.omama.oneTypeOneClientScreening,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOneTypeOneClientScreenings: (clientID, type) => dispatch(getOneTypeOneClientScreenings(clientID, type)),
    getScreeningQuestions: (screeningId, clientId) => dispatch(getScreeningQuestions(screeningId, clientId)),
    persistScreeningQuestions: (screeningId, clientId, answers, pasmaResults, goBackFnc) =>
      dispatch(persistScreeningQuestions(screeningId, clientId, answers, pasmaResults, goBackFnc)),
    changePage: (location) => dispatch(push(location)),
    setLoading: (flag) => dispatch(setLoading(flag)),
  };
}

ScreeningQuestionsScreen.propTypes = {
  screeningId: PropTypes.string,
  clientId: PropTypes.string,
  getScreeningQuestions: PropTypes.func,
  questionsById: PropTypes.object,
  questionIds: PropTypes.object,
  persistScreeningQuestions: PropTypes.func,
  isLoading: PropTypes.bool,
  questions: PropTypes.array,
  from: PropTypes.number,
  to: PropTypes.number,
  goBack: PropTypes.func,
  changePage: PropTypes.func,
  setLoading: PropTypes.func,
  getOneTypeOneClientScreenings: PropTypes.func,
  oneTypeOneClientScreening: PropTypes.object,
  screening: PropTypes.object,
  router: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningQuestionsScreen);
