import React, { useState, useEffect, useCallback } from 'react';
import { goBack } from 'connected-react-router';
import { Button, Col, Row, Dropdown, Modal, DatePicker } from 'antd';
import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { strings } from '../../../strings/StringsProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { isArray, isEmpty } from 'lodash';

import {
  populateInterNDAForm,
  saveMeasurement,
  removeMeasurement,
  resetInterNDAForm,
} from '../../../actions/admin.actions';
import InterNDAScoreTable from 'components/InterNDAScoreTable';
import './InterNDAForm.scss';

const answerOptions = [1, 2, 3, 4, 'X'];

function InterNDAForm() {
  const [answers, setAnswers] = useState([]);
  const [measurementDate, setMeasurementDate] = useState(null);
  const [cognitionScore, setCognitionScore] = useState(0);
  const [fineMotorScore, setFineMotorScore] = useState(0);
  const [grossMotorScore, setGrossMotorScore] = useState(0);
  const [languageScore, setLanguageScore] = useState(0);
  const [positiveBehaviourScore, setPositiveBehaviourScore] = useState(0);
  const [negativeBehaviourScore, setNegativeBehaviourScore] = useState(0);
  const [edit, setEdit] = useState(false);
  const [removeWindowVisible, setRemoveWindowVisible] = useState(false);
  const adminRole = jwtDecode(localStorage.getItem('access-token')).role === 'admin';
  const userName = jwtDecode(localStorage.getItem('access-token')).username;
  const { clientId } = useParams();

  const interNDAAnswers = useSelector((state) => state.measurements.interNDAAnswers);
  const interNDAQuestions = useSelector((state) => state.measurements.interNDAQuestions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(populateInterNDAForm(clientId));

    return () => dispatch(resetInterNDAForm());
  }, [dispatch, clientId]);

  useEffect(() => {
    if (isEmpty(answers) && !isEmpty(interNDAQuestions)) {
      if (!isEmpty(interNDAAnswers)) {
        setAnswers(interNDAAnswers.answers);
        setMeasurementDate(interNDAAnswers.date);
        setCognitionScore(interNDAAnswers.cognition);
        setFineMotorScore(interNDAAnswers.fineMotor);
        setGrossMotorScore(interNDAAnswers.grossMotor);
        setLanguageScore(interNDAAnswers.language);
        setPositiveBehaviourScore(interNDAAnswers.positiveBehaviour);
        setNegativeBehaviourScore(interNDAAnswers.negativeBehaviour);
        setEdit(true);
      } else {
        setAnswers(interNDAQuestions);
      }
    }
  }, [interNDAQuestions, interNDAAnswers, answers]);

  const getQuestionsFromCategory = useCallback(
    (questionCategory) => {
      const categoryQuestions = answers.filter((question) => {
        if (isArray(question.category)) {
          return question.category.includes(questionCategory);
        }
        return question.category === questionCategory;
      });

      return categoryQuestions;
    },
    [answers],
  );

  useEffect(() => {
    if (answers) {
      const cognitionAnswers = getQuestionsFromCategory('cognition');
      const totalCognitionScore = calculateTotalScore(cognitionAnswers, 3);
      setCognitionScore(totalCognitionScore);

      const fineMotorAnswers = getQuestionsFromCategory('fineMotor');
      const totalFineMotorScore = calculateTotalScore(fineMotorAnswers, 3);
      setFineMotorScore(totalFineMotorScore);

      const grossMotorAnswers = getQuestionsFromCategory('grossMotor');
      const totalGrossMotorScore = calculateTotalScore(grossMotorAnswers, 3);
      setGrossMotorScore(totalGrossMotorScore);

      const languageAnswers = getQuestionsFromCategory('language');
      const totalLanguageScore = calculateTotalScore(languageAnswers, 3);
      setLanguageScore(totalLanguageScore);

      const positiveBehaviourAnswers = getQuestionsFromCategory('positiveBehaviour');
      const totalPositiveBehaviourScore = calculateTotalScore(positiveBehaviourAnswers, 2);
      setPositiveBehaviourScore(totalPositiveBehaviourScore);

      const negativeBehaviourAnswers = getQuestionsFromCategory('negativeBehaviour');
      const totalNegativeBehaviourScore = calculateTotalScore(negativeBehaviourAnswers, 2);
      setNegativeBehaviourScore(totalNegativeBehaviourScore);
    }
  }, [answers, getQuestionsFromCategory]);

  const calculateTotalScore = (measurementAnswers, divisor) => {
    let sumOfValues = 0;
    let totalAnswers = 0;

    measurementAnswers.forEach((answer) => {
      if (!isNaN(answer.value)) {
        sumOfValues += answer.value;
        totalAnswers += 1;
      }
    });

    if (totalAnswers === 0) return 0;
    const totalScore = ((sumOfValues / totalAnswers - 1) / divisor) * 100;

    return Math.round(totalScore * 10) / 10;
  };

  const onAnswerChange = ({ key }) => {
    const [answer, questionIndex] = key.split('-');
    let newAnswers = [...answers];
    newAnswers[questionIndex].value = isNaN(answer) ? answer : Number(answer);
    setAnswers(newAnswers);
  };

  const valueOptions = (questionIndex) =>
    answerOptions.map((answer) => ({
      key: `${answer}-${questionIndex}`,
      label: answer,
    }));

  const renderQuestions = (from, to) => {
    return (
      answers &&
      answers.map((question, i) => {
        if (i >= from && i < to) {
          return (
            <div className="interNDA-question" key={i}>
              {question.title}:
              <div className="interNDA-answer-dropdown">
                <Dropdown menu={{ items: valueOptions(i), onClick: onAnswerChange }} trigger={['click']}>
                  <div>
                    {question.value}
                    <DownOutlined style={{ paddingLeft: '10px' }} />
                  </div>
                </Dropdown>
              </div>
            </div>
          );
        } else return null;
      })
    );
  };

  const saveInterNDA = () => {
    const body = {
      client: clientId,
      type: 'inter-NDA',
      date: new Date(measurementDate),
      savedBy: userName,
      answers: answers,
      cognition: cognitionScore,
      fineMotor: fineMotorScore,
      grossMotor: grossMotorScore,
      language: languageScore,
      positiveBehaviour: positiveBehaviourScore,
      negativeBehaviour: negativeBehaviourScore,
    };

    dispatch(saveMeasurement(body)).then(() => {
      dispatch(resetInterNDAForm());
      dispatch(goBack());
    });
  };

  const openRemoveWindow = () => {
    setRemoveWindowVisible(true);
  };

  const closeRemoveWindow = () => {
    setRemoveWindowVisible(false);
  };

  const removeInterNDA = () => {
    setRemoveWindowVisible(false);
    dispatch(removeMeasurement('inter-NDA', clientId)).then(() => {
      dispatch(resetInterNDAForm());
      dispatch(goBack());
    });
  };

  const returnBack = () => {
    dispatch(resetInterNDAForm());
    dispatch(goBack());
  };

  const checkFields = () => {
    const notAllAnswersFilled = answers.some((answer) => answer.value === '-');
    if (!measurementDate || notAllAnswersFilled) return true;

    return false;
  };

  return (
    <div className="interNDA-form-background">
      <div className="interNDA-form-wrapper">
        <div className="back-button" style={{ cursor: 'pointer' }} onClick={returnBack}>
          <LeftOutlined /> {strings.back1}
        </div>
        <h2>{strings.interNDAMeasurement}</h2>
        <div className="measurement-date">
          <p className="measurement-date-label">{strings.measurementDate}</p>
          {edit ? (
            <p className="measurement-date-data">{dayjs(measurementDate).format('D. M. YYYY')}</p>
          ) : (
            <DatePicker
              value={measurementDate}
              onChange={(date) => setMeasurementDate(date)}
              placeholder={strings.chooseDate}
              className="measurement-date-picker"
            />
          )}
        </div>
        <div className="interNDA-questions desktopView">
          <Row align="middle" justify="space-around">
            <Col span={6}>{renderQuestions(0, 10)}</Col>
            <Col span={6}>{renderQuestions(10, 20)}</Col>
            <Col span={6}>{renderQuestions(20, 30)}</Col>
            <Col span={6}>{renderQuestions(30, 37)}</Col>
          </Row>
        </div>
        <div className="interNDA-questions mobileView">
          <Row align="middle" justify="space-around">
            <Col span={12}>{renderQuestions(0, 19)}</Col>
            <Col span={12}>{renderQuestions(19, 37)}</Col>
          </Row>
        </div>
        <div>
          {answers && (
            <InterNDAScoreTable
              cognitionScore={cognitionScore}
              fineMotorScore={fineMotorScore}
              grossMotorScore={grossMotorScore}
              languageScore={languageScore}
              positiveBehaviourScore={positiveBehaviourScore}
              negativeBehaviourScore={negativeBehaviourScore}
            />
          )}
        </div>
        <div className="interNDA-footer">
          {adminRole && (
            <div>
              <Button onClick={saveInterNDA} className="save-measurement" disabled={checkFields()}>
                {strings.save}
              </Button>
              {edit && (
                <Button onClick={openRemoveWindow} className="remove-measurement">
                  {strings.remove}
                </Button>
              )}
              <Modal
                centered
                title={strings.removeMeasurement}
                open={removeWindowVisible}
                onCancel={closeRemoveWindow}
                onOk={() => removeInterNDA()}
              >
                {strings.removeMeasurementMessage}
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InterNDAForm;
