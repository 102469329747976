import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { Buffer } from 'buffer';

import {
  GET_ACTIONS,
  GET_OMAMA_CLIENTS,
  GET_OMAMA_SUPERVISIONS,
  GET_OMAMA_SUPERVISION_DETAIL,
  GET_OMAMA_TOKEN,
  RESET_OMAMA,
  RESET_CLIENTS,
  SHOW_CLIENT_MODAL,
  SHOW_CLIENT_MODAL_END,
  SHOW_ACTION_MODAL,
  SHOW_ACTION_MODAL_END,
  GET_SCREENING_QUESTIONS,
  PERSIST_SCREENING_QUESTIONS_RESULT,
  API_CALL_STARTED,
  API_CALL_FINISHED,
  CHANGE_NEXT_WEEK,
  CHANGE_PREVIOUS_WEEK,
  CHANGE_CURRENT_WEEK,
  SELECT_DATE,
  GET_CLIENT_SCREENINGS,
  GET_ACTIONS_FOR_CLIENT,
  // CLIENTPROFILE_SCREENING_TABLE, // TO OD
  INITIAL_SKRININGS_FOR_CLIENTPROFILE,
  GET_ONETYPE_ONECLIENT_SCREENINGS,
  GET_LENT_TOOLS,
  MAKE_FOLDERS,
  REMOVE_PHOTO,
  GET_FINISHED_LESSONS_COUNT,
  GET_FINISHED_CLUBS_COUNT,
  GET_OMAMA_MILESTONES,
  GET_RENTED_ACCESSORIES,
  GET_RENTED_ACCESSORY_STATUS,
  GET_ACCESSORY_INFO,
  RESET_ACCESSORY_DETAIL,
  GET_ACTION_PHOTOS,
  GET_OMAMA_ACTION_GALLERY,
} from '../reducers/omama.reducer';

// Local imports
import { api } from '../conf';
import { cloneDeep } from 'lodash';
import { strings } from '../strings/StringsProvider';
import { handleClientXButton, handleClientEndXButton } from './clientActivities.action';
import { groupActions } from '../tools/action.tools';
import { getDateDiff } from 'tools/date.tools';
import { setLoading } from './status.actions';
import { getAdminOmamaActionsAction, getMissingAccessoriesCount } from './admin.actions';
import { v4 as uuidv4 } from 'uuid';
import { showErrorMessage } from 'tools/errorHandling';

export const getFinishedLessonsCount = (start, end) => {
  return async (dispatch) => {
    const thisYear = await axios.get(api.getFinishedLessonsCount(dayjs(start).format(), dayjs(end).format()));

    const allTime = await axios.get(api.getFinishedLessonsCount(null, dayjs(end).format()));

    const data = {
      thisYear: thisYear.data.data,
      allTime: allTime.data.data,
    };

    dispatch({
      type: GET_FINISHED_LESSONS_COUNT,
      payload: data,
    });
  };
};

export const getFinishedClubsCount = (start, end) => {
  return async (dispatch) => {
    const thisYear = await axios.get(api.getFinishedClubsCount(dayjs(start).format(), dayjs(end).format()));

    const allTime = await axios.get(api.getFinishedClubsCount(null, dayjs(end).format()));

    const data = {
      thisYear: thisYear.data.data,
      allTime: allTime.data.data,
    };

    dispatch({
      type: GET_FINISHED_CLUBS_COUNT,
      payload: data,
    });
  };
};

export const getOmamaMilestones = (omamaId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const milestones = await axios.get(api.getOmamaMilestones(omamaId));

    dispatch(setLoading(false));
    dispatch({
      type: GET_OMAMA_MILESTONES,
      payload: milestones.data.data,
    });
  };
};

export const getActionsAction = (start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getActivities(start, dayjs(end).add(1, 'days').format('YYYY-MM-DD')));
      const actions = data.data;
      actions.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      const { result } = groupActions(data.data);
      const clientActionsByDay = result.clientActionsByDay;
      const actionsByDay = result.actionsByDay;
      const tempActionsByDay = result.tempActionsByDay;
      const allActionsByDay = result.allActionsByDay;

      // added await below
      await dispatch({
        type: GET_ACTIONS,
        payload: {
          all: data.data.map((item) => ({
            ...item,
            checkedActivitiesDefault: item.checkedActivities,
            evaluationDefault: cloneDeep(item.evaluation),
          })),
          byDay: tempActionsByDay,
          clientActionsByDay: clientActionsByDay,
          actionsByDay: actionsByDay,
          allActionsByDay: allActionsByDay,
        },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getOmamaClientsAction = () => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getOmamaClients);
    const clients = data.clients.map((item) => {
      const months = getDateDiff(item.birthDate, new Date()).months;

      let age;

      switch (true) {
        case months === 1:
          age = `${months} ${strings.oneMonth}`;
          break;
        case months === 0 || months >= 5:
          age = `${months} ${strings.zeroOrFiveMonths}`;
          break;
        case months >= 2 || months <= 4:
          age = `${months} ${strings.twoTillFourMonths}`;
          break;
        default:
          age = `${months} ${strings.zeroOrFiveMonths}`;
          break;
      }

      return {
        ...item,
        name: item.firstName + ' ' + item.lastName,
        lastFirstName: item.lastName + item.firstName,
        age,
      };
    });
    return dispatch({
      type: GET_OMAMA_CLIENTS,
      payload: {
        clients,
      },
    });
  };
};

export const getOmamaSupervisions = (from, to) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getSupervisionsOmama(from, to));
    return dispatch({
      type: GET_OMAMA_SUPERVISIONS,
      payload: data.supervisions,
    });
  };
};

export const getOmamaSupervisionsByOmamaID = (omamaID, from, to) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getOmamaSupervisionsByOmamaID(omamaID, from, to));
    return dispatch({
      type: GET_OMAMA_SUPERVISIONS,
      payload: data.supervisions,
    });
  };
};

export const getOmamaSupervisionDetail = (id) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getSupervision(id));
    return dispatch({
      type: GET_OMAMA_SUPERVISION_DETAIL,
      payload: data.data,
    });
  };
};

export const resetOmamaSupervisionDetail = () => {
  return async (dispatch) => {
    return dispatch({
      type: GET_OMAMA_SUPERVISION_DETAIL,
      payload: {},
    });
  };
};

export const makeFolders = (clientId) => {
  return async (dispatch) => {
    const galleryFolders = await axios.get(api.getGalleryFolders(clientId));
    return dispatch({
      type: MAKE_FOLDERS,
      payload: galleryFolders?.data?.data,
    });
  };
};

export const removePhotoAction = (clientID, photoID, omamaID) => {
  return async (dispatch) => {
    const { status } = await axios.post(api.removePhoto, {
      clientID,
      photoID,
      omamaID,
    });
    if (status === 200) {
      return dispatch({
        type: REMOVE_PHOTO,
        payload: {
          clientID,
          photoID,
        },
      });
    }
  };
};

export const resetOmamaClientsAction = () => {
  return { type: RESET_CLIENTS };
};

/*
export const getLastXClientActions = (clientID, status) => {
  return async (dispatch) => {

    const { data } = await axios.get(api.getLastXClientActions(clientID, status));
    console.log('getLastXClientActions ACTION DATA', data);
    // const clients = data.clients.map(item => ({...item, name: item.firstName + ' ' + item.lastName}));
    // return dispatch({
    //   type: GET_OMAMA_CLIENTS,
    //   payload: {
    //     clients,
    //   }
    // });
  };
};
*/

export const getOmamaClientActionsAction = (ID, from, to) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getOmamaClientActions(ID, from, to));
    return dispatch({
      type: GET_ACTIONS_FOR_CLIENT,
      payload: data.data,
    });
  };
};

export const getOmamaTokenAction = () => {
  const omama = jwtDecode(localStorage.getItem('access-token'));
  return {
    type: GET_OMAMA_TOKEN,
    payload: omama,
  };
};

export const resetOmamaAction = () => {
  return { type: RESET_OMAMA };
};

export const showClientModal = (id, closeId, type) => {
  // TO DO - FIX typo v params
  return async (dispatch) => {
    if (type === 'client') {
      dispatch(handleClientXButton(closeId));
    } else if (type === 'clientEnd') {
      dispatch(handleClientEndXButton(closeId));
    }

    return dispatch({
      type: SHOW_CLIENT_MODAL,
      payload: {
        actionID: id,
      },
    });
  };
};

export const showActionModal = (id) => {
  return async (dispatch) => {
    return dispatch({
      type: SHOW_ACTION_MODAL,
      payload: {
        actionID: id,
      },
    });
  };
};

export const getClientScreenings = (clientId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getClientScreenings + '/' + clientId);
    return dispatch({
      type: GET_CLIENT_SCREENINGS,
      payload: data.data,
    });
  };
};

export const getOneTypeOneClientScreenings = (clientId, type) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getOneTypeOneClientSkrinings(clientId, type));
    return dispatch({
      type: GET_ONETYPE_ONECLIENT_SCREENINGS,
      payload: data.data,
    });
  };
};

export const getScreeningQuestions = (screeningId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getScreeningQuestions + '/' + screeningId);
    return dispatch({
      type: GET_SCREENING_QUESTIONS,
      payload: data,
    });
  };
};

export const persistScreeningQuestions = (screeningId, clientId, answers, results, goBackFnc) => {
  return async (dispatch) => {
    dispatch({ type: API_CALL_STARTED });

    try {
      await axios.post(api.persistScreeningQuestions, {
        clientID: clientId,
        answers: answers,
        pasma: results, // TODO rename pasma
        type: screeningId,
      });
      return dispatch({
        type: PERSIST_SCREENING_QUESTIONS_RESULT,
        success: true,
      });
    } catch (e) {
      //TODO handle error properly
      return dispatch({
        type: PERSIST_SCREENING_QUESTIONS_RESULT,
        success: false,
      });
    } finally {
      dispatch({ type: API_CALL_FINISHED });
      goBackFnc();
    }
  };
};

export const showClientModalEnd = () => {
  return async (dispatch) => {
    return dispatch({
      type: SHOW_CLIENT_MODAL_END,
      payload: {},
    });
  };
};

export const showActiontModalEnd = () => {
  return async (dispatch) => {
    return dispatch({
      type: SHOW_ACTION_MODAL_END,
      payload: {
        // actionID: id,
      },
    });
  };
};

export const changeNextWeek = (actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).add(1, 'weeks').startOf('week').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).add(1, 'weeks').endOf('week').format('YYYY-MM-DD');

    dispatch({
      type: CHANGE_NEXT_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });

    dispatch(getActionsAction(start, end));
  };
};

export const changePreviousWeek = (actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');

    dispatch({
      type: CHANGE_PREVIOUS_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getActionsAction(start, end));
  };
};

export const changeWeek = (date) => {
  return async (dispatch) => {
    const start = dayjs(date).startOf('week').format('YYYY-MM-DD');
    const end = dayjs(date).endOf('week').format('YYYY-MM-DD');

    dispatch({
      type: CHANGE_CURRENT_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getActionsAction(start, end));
  };
};

export const changeNextYear = (actualStart, actualEnd, omamaID) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).add(1, 'years').startOf('year').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).add(1, 'years').endOf('year').format('YYYY-MM-DD');

    dispatch({
      type: SELECT_DATE,
      payload: {
        startDate: start,
        endDate: end,
      },
    });

    let user = jwtDecode(localStorage.getItem('access-token'));
    if (user.role === 'omama') {
      dispatch(getOmamaSupervisions(start, end));
    } else if (user.role === 'admin' || user.role === 'mentor' || user.role === 'supervisor') {
      dispatch(getOmamaSupervisionsByOmamaID(omamaID, start, end));
    }
  };
};

export const changePreviousYear = (actualStart, actualEnd, omamaID) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).subtract(1, 'years').endOf('year').format('YYYY-MM-DD');

    dispatch({
      type: SELECT_DATE,
      payload: {
        startDate: start,
        endDate: end,
      },
    });

    let user = jwtDecode(localStorage.getItem('access-token'));
    if (user.role === 'omama') {
      dispatch(getOmamaSupervisions(start, end));
    } else if (user.role === 'admin' || user.role === 'mentor' || user.role === 'supervisor') {
      dispatch(getOmamaSupervisionsByOmamaID(omamaID, start, end));
    }
  };
};

export const selectMonth = (date, omamaID) => {
  return async (dispatch) => {
    let startDate = null;
    let endDate = null;

    if (!date) {
      // If date null, reset to default period (same as in reducer)
      startDate = dayjs().startOf('year').format();
      endDate = dayjs().endOf('year').format();
    } else {
      startDate = date.startOf('month').format();
      endDate = date.endOf('month').format();
    }

    dispatch({
      type: SELECT_DATE,
      payload: {
        startDate: startDate,
        endDate: endDate,
      },
    });

    let user = jwtDecode(localStorage.getItem('access-token'));
    if (user.role === 'omama') {
      dispatch(getOmamaSupervisions(startDate, endDate));
    } else if (user.role === 'admin' || user.role === 'mentor' || user.role === 'supervisor') {
      dispatch(getOmamaSupervisionsByOmamaID(omamaID, startDate, endDate));
    }
  };
};

export function cancelAction(action, start, end) {
  return async (dispatch) => {
    const data = {
      id: action._id,
      set: {
        status: 'canceled',
      },
    };

    try {
      dispatch(setLoading(true));
      await axios.post(api.updateAction, data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
    dispatch(getActionsAction(start, end));
  };
}

export const changeDateAction = (actionId, date, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const body = {
      id: actionId,
      set: {
        date: date,
      },
    };
    try {
      await axios.post(api.updateAction, body);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }

    dispatch({
      type: CHANGE_CURRENT_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });
    await dispatch(getActionsAction(start, end));
  };
};

export const changeTimeSpentAction = (
  actionID,
  time,
  start,
  end,
  omamaID,
  photos,
  thumbnails,
  datesTaken,
  actionType,
) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const body = {
      id: actionID,
      set: {
        timeSpent: Number(time),
      },
    };
    try {
      await axios.post(api.updateAction, body);
      if (photos.length > 0) {
        for (let i = 0; i < photos.length; i++) {
          const photoID = uuidv4();
          const newPhotoBody = {
            actionID,
            actionType,
            omamaID,
            photoID,
            date: datesTaken[i],
          };
          const { data } = await axios.post(api.addNewActionPhoto, newPhotoBody);
          const signedUrls = data.signedUrls;
          const bufferThumbnail = Buffer.from(thumbnails[i].split(',')[1], 'base64');
          const bufferPhoto = Buffer.from(photos[i].split(',')[1], 'base64');
          await axios.put(signedUrls.thumbnail, bufferThumbnail);
          await axios.put(signedUrls.photo, bufferPhoto);
        }
      }
    } catch (error) {
      showErrorMessage(error, strings.errors.changeTimeSpentAction);
    } finally {
      dispatch(setLoading(false));
    }
    await dispatch(getActionsAction(start, end));
  };
};

export const initialSkriningforClientProfile = (clientID) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(api.getClientsInitialScreenings(clientID));
      dispatch({
        type: INITIAL_SKRININGS_FOR_CLIENTPROFILE,
        payload: data.data,
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

export const changeCommentAction = (actionId, comment, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const body = {
      id: actionId,
      set: { comment: comment },
    };
    try {
      await axios.post(api.updateAction, body);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
    await dispatch(getActionsAction(start, end));
  };
};

export const changeOmamaActionInfo = (actionId, omamaId, info, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const body = {
      id: actionId,
      omamaId: omamaId,
      set: { ...info },
    };
    try {
      await axios.post(api.updateAction, body);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
    await dispatch(getAdminOmamaActionsAction(omamaId, start, dayjs(end).add(1, 'days').format('YYYY-MM-DD')));
  };
};

export const getLentToolsAction = (userId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getLentTools(userId));
    return dispatch({
      type: GET_LENT_TOOLS,
      payload: data.lentTools,
    });
  };
};

export const getRentedAccessories = (userId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getRentedAccessories(userId));
    return dispatch({
      type: GET_RENTED_ACCESSORIES,
      payload: data.data,
    });
  };
};

export const getAccessoryStatus = (accessoryId, userId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getRentedAccessoryStatus(accessoryId, userId));
    return dispatch({
      type: GET_RENTED_ACCESSORY_STATUS,
      payload: data.data,
    });
  };
};

export const getAccessoryInfo = (accessoryId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getAccessory(accessoryId));
    return dispatch({
      type: GET_ACCESSORY_INFO,
      payload: data.data,
    });
  };
};

export const resetAccessoryDetail = () => {
  return async (dispatch) => {
    return dispatch({
      type: RESET_ACCESSORY_DETAIL,
    });
  };
};

export const changeOmamaAccessoryStatus = (accessoryId, userId, body) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const updateAccessoryStatus = await axios.post(api.changeOmamaAccessoryStatus(accessoryId, userId), body);
      dispatch(getMissingAccessoriesCount());
      dispatch(setLoading(false));
      return dispatch({
        type: GET_RENTED_ACCESSORY_STATUS,
        payload: updateAccessoryStatus.data.data,
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const loadActionPhotos = (userType, actionID) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getActionPhotos(userType, actionID));
      return dispatch({
        type: GET_ACTION_PHOTOS,
        payload: data.data,
      });
    } catch (error) {
      showErrorMessage(error, strings.errors.loadActionPhotos);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const removeActionPhotoAction = (omamaID, photoID, actionID, actionType) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios.post(api.removeActionPhoto, {
        omamaID,
        photoID,
        actionType,
      });
      if (actionID !== '') {
        dispatch(loadActionPhotos('omama', actionID));
      }
    } catch (error) {
      showErrorMessage(error, strings.errors.removeActionPhotoAction);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getOmamaActionGallery = (omamaID) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await axios.get(api.getOmamaActionGallery(omamaID));
      return dispatch({
        type: GET_OMAMA_ACTION_GALLERY,
        payload: data.omamaGallery,
      });
    } catch (error) {
      showErrorMessage(error, strings.errors.getOmamaActionGallery);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
