import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { loadLocale, dayjsLocale } from './tools/date.tools';

import store, { history } from './store';
import App from './containers/app';
import ScrollToTop from 'components/ScrollToTop';
import { ImageUrlProvider } from './context/ImageUrlContext';
import csCZ from 'antd/es/locale/cs_CZ';
import skSK from 'antd/es/locale/sk_SK';

import 'sanitize.css/sanitize.css';
import './index.scss';

const target = document.querySelector('#root');

loadLocale().then(() =>
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ImageUrlProvider>
          <div>
            <ConfigProvider locale={dayjsLocale === 'cs' ? csCZ : skSK}>
              <ScrollToTop />
              <App />
            </ConfigProvider>
          </div>
        </ImageUrlProvider>
      </ConnectedRouter>
    </Provider>,
    target,
  ),
);
