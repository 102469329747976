import {
  GET_MENTOR_CLIENTS,
  ADMIN_MENTOR_CHANGE_NEXT_MONTH,
  ADMIN_MENTOR_CHANGE_PREVIOUS_MONTH,
  GET_ADMIN_MENTOR_ACTIONS,
  GET_SPECIFIC_MENTOR_ACTION,
  ADMIN_MENTOR_CHANGE_DATE,
} from '../reducers/mentor.reducer';
import { orderBy } from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';

export const getMentorAction = () => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getMentorClients);
    return dispatch({
      type: GET_MENTOR_CLIENTS,
      payload: data.data,
    });
  };
};

export const getSpecificMentorAction = (actionId) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getSpecificMentorAction(actionId));
    return dispatch({
      type: GET_SPECIFIC_MENTOR_ACTION,
      payload: data.data,
    });
  };
};

export const getAdminMentorActionsAction = (mentorId, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let startDate = start;
    let endDate = end;
    if (!(typeof start === 'string') || !(typeof end === 'string')) {
      startDate = dayjs(start).format('YYYY-MM-DD');
      endDate = dayjs(end).format('YYYY-MM-DD');
    }
    try {
      const { data } = await axios.get(api.getAdminMentorActions(mentorId, startDate, endDate));

      await dispatch({
        type: GET_ADMIN_MENTOR_ACTIONS,
        payload: {
          mentorActions: orderBy(data.data, ['date'], ['desc']),
        },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const changeNextMonthMentor = (mentorId, actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).add(1, 'months').startOf('month').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).add(1, 'months').endOf('month').format('YYYY-MM-DD');
    dispatch({
      type: ADMIN_MENTOR_CHANGE_NEXT_MONTH,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getAdminMentorActionsAction(mentorId, start, dayjs(end).add(1, 'days').format('YYYY-MM-DD')));
  };
};

export const changePreviousMonthMentor = (mentorId, actualStart, actualEnd) => {
  return async (dispatch) => {
    const start = dayjs(actualStart).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    const end = dayjs(actualEnd).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    dispatch({
      type: ADMIN_MENTOR_CHANGE_PREVIOUS_MONTH,
      payload: {
        start: start,
        end: end,
      },
    });
    dispatch(getAdminMentorActionsAction(mentorId, start, dayjs(end).add(1, 'days').format('YYYY-MM-DD')));
  };
};

export const adminMentorChangeDate = (date) => {
  return async (dispatch) => {
    let from = null;
    let to = null;
    if (date?.[0] && date?.[1]) {
      from = dayjs(date[0]);
      to = dayjs(date[1]);
    }

    return dispatch({
      type: ADMIN_MENTOR_CHANGE_DATE,
      payload: {
        mentorFrom: from,
        mentorTo: to,
      },
    });
  };
};

export const deleteMentorAction = (actionID) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const data = {
      id: actionID,
    };
    try {
      await axios.post(api.deleteMentorAction, data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const changeMentorComment = (actionId, comment, mentorId, from, to) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const body = { comment: comment };

    try {
      await axios.put(api.updateMentorAction(actionId), body);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }

    if (mentorId && from && to) await dispatch(getAdminMentorActionsAction(mentorId, from, to));
  };
};

export const changeMentorAction = (actionId, data, mentorId, from, to) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await axios.put(api.updateMentorAction(actionId), data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }

    if (mentorId && from && to) await dispatch(getAdminMentorActionsAction(mentorId, from, to));
  };
};
