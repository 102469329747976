import React, { useState, useEffect, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from 'antd';
import './ClientImageGallery.scss';
import { jwtDecode } from 'jwt-decode';
import { useParams } from 'react-router-dom';

// Local imports
import { getOmamaClientsAction, makeFolders, removePhotoAction } from '../../actions/omama.actions';
import GalleryFolders from '../../components/GalleryFolders';
import { strings } from '../../strings/StringsProvider';
import ImageGalleryNav from '../../components/ImageGalleryNav';
import ImageGallery from '../../components/ImageGallery';

function ClientImageGallery() {
  const [openClientID, setOpenClientID] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [openedFolder, setOpenedFolder] = useState(null);
  const { clientProfileId } = useParams();
  const omamaID = jwtDecode(localStorage.getItem('access-token')).username;

  const clientsByID = useSelector((state) => state.omama.clients);
  const folders = useSelector((state) => state.omama.galleryFolders);

  const dispatch = useDispatch();

  const changePage = useCallback((location) => dispatch(push(location)), [dispatch]);

  useEffect(() => {
    dispatch(getOmamaClientsAction());
    dispatch(makeFolders(clientProfileId));
    setOpenClientID(clientProfileId);
  }, [clientProfileId, dispatch]);

  const showFolder = (folder) => {
    setOpenedFolder(folder);
  };

  const goBackTo = useCallback(
    (level) => {
      switch (level) {
        case 'user':
          setOpenedFolder(null);
          break;
        case 'gallery':
          changePage('/omama/galeria');
          break;
        default:
          break;
      }
      // Reset page when navigating away
      setPageNumber(1);
    },
    [changePage],
  );

  const pageCount = useCallback((page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
  }, []);

  const imageCount = useCallback(
    (gallery) => {
      return gallery.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
    [pageNumber, pageSize],
  );

  const removeImage = useCallback(
    (clientID, photoID) => {
      dispatch(removePhotoAction(clientID, photoID, omamaID));

      // If deleting the last photo in the folder, close folder
      // and return one level back
      const folder = folders[openedFolder];
      if (folder && folder?.length === 1 && folder[0] === photoID) {
        setOpenedFolder(null);
      }
    },
    [dispatch, folders, openedFolder, omamaID],
  );

  if (openClientID && clientsByID) {
    const client = clientsByID[openClientID];
    return (
      <div className="galleryContainer">
        <div className="gallery"></div>
        <div className="clientGalleryWrapper">
          <ImageGalleryNav name={client?.name} goBack={goBackTo} folder={openedFolder} />
          {client?.gallery?.length === 0 && <p className="galleryEmpty">{strings.galleryIsEmpty}</p>}
          {!openedFolder ? (
            <GalleryFolders folders={folders} clickHandler={(key) => showFolder(key)} />
          ) : (
            <ImageGallery gallery={imageCount(folders[openedFolder])} removeImage={removeImage} clientID={client._id} />
          )}
          <div className="paginationDiv">
            {openedFolder && (
              <Pagination
                className="pagination"
                size="small"
                defaultCurrent={1}
                defaultPageSize={9}
                total={folders[openedFolder]?.length}
                onChange={pageCount}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default ClientImageGallery;
