import { keyBy } from 'lodash';
import dayjs from 'dayjs';

export const GET_ACTIONS = 'GET_ACTIONS';
export const GET_OMAMA_CLIENTS = 'GET_OMAMA_CLIENTS';
export const GET_OMAMA_SUPERVISIONS = 'GET_OMAMA_SUPERVISIONS';
export const GET_OMAMA_SUPERVISION_DETAIL = 'GET_OMAMA_SUPERVISION_DETAIL';
export const HANDLE_ACTION_CHECKBOX = 'HANDLE_ACTION_CHECKBOX';
export const HANDLE_ACTION_RADIO = 'HANDLE_ACTION_RADIO';
export const HANDLE_ACTION_COMMENT = 'HANDLE_ACTION_COMMENT';
export const HANDLE_CLIENT_XBUTTON = 'HANDLE_CLIENT_XBUTTON';
export const HANDLE_CLIENTEND_XBUTTON = 'HANDLE_CLIENTEND_XBUTTON';
export const SHOW_CLIENT_MODAL = 'SHOW_CLIENT_MODAL';
export const SHOW_CLIENT_MODAL_END = 'SHOW_CLIENT_MODAL_END';
export const SHOW_ACTION_MODAL = 'SHOW_ACTION_MODAL';
export const SHOW_ACTION_MODAL_END = 'SHOW_ACTION_MODAL_END';
export const GET_SCREENING_QUESTIONS = 'GET_SCREENING_QUESTIONS';
export const PERSIST_SCREENING_QUESTIONS_RESULT = 'PERSIST_SCREENING_QUESTIONS_RESULT';
export const GET_OMAMA_TOKEN = 'GET_OMAMA_TOKEN';
export const RESET_OMAMA = 'RESET_OMAMA';
export const RESET_CLIENTS = 'RESET_CLIENTS';
export const GET_LENT_TOOLS = 'GET_LENT_TOOLS';
export const MAKE_FOLDERS = 'MAKE_FOLDERS';
export const REMOVE_PHOTO = 'REMOVE_PHOTO';
// handlovanie jednej z troch otazok na ClientEnd
export const HANDLE_ACTION_QUESTION = 'HANDLE_ACTION_QUESTION';

export const API_CALL_FINISHED = 'API_CALL_FINISHED';
export const API_CALL_STARTED = 'API_CALL_STARTED';

export const CHANGE_NEXT_WEEK = 'CHANGE_NEXT_WEEK';
export const CHANGE_PREVIOUS_WEEK = 'CHANGE_PREVIOUS_WEEK';
export const CHANGE_CURRENT_WEEK = 'CHANGE_CURRENT_WEEK';
export const SELECT_DATE = 'SELECT_DATE';

export const GET_CLIENT_SCREENINGS = 'GET_CLIENT_SCREENINGS';

export const GET_ACTIONS_FOR_CLIENT = 'GET_ACTIONS_FOR_CLIENT';

export const CLIENTPROFILE_SCREENING_TABLE = 'CLIENTPROFILE_SCREENING_TABLE';
export const INITIAL_SKRININGS_FOR_CLIENTPROFILE = 'INITIAL_SKRININGS_FOR_CLIENTPROFILE';
export const GET_ONETYPE_ONECLIENT_SCREENINGS = 'GET_ONETYPE_ONECLIENT_SCREENINGS';

export const GET_FINISHED_LESSONS_COUNT = 'GET_FINISHED_LESSONS_COUNT';
export const GET_FINISHED_CLUBS_COUNT = 'GET_FINISHED_CLUBS_COUNT';

export const GET_OMAMA_MILESTONES = 'GET_OMAMA_MILESTONES';
export const GET_RENTED_ACCESSORIES = 'GET_RENTED_ACCESSORIES';
export const GET_RENTED_ACCESSORY_STATUS = 'GET_RENTED_ACCESSORY_STATUS';
export const GET_ACCESSORY_INFO = 'GET_ACCESSORY_INFO';
export const RESET_ACCESSORY_DETAIL = 'RESET_ACCESSORY_DETAIL';
export const GET_ACTION_PHOTOS = 'GET_ACTION_PHOTOS';
export const GET_OMAMA_ACTION_GALLERY = 'GET_OMAMA_ACTION_GALLERY';

import { dayjsLocale } from '../tools/date.tools';
// Language is set after the reduces are loaded,
// this means that we have to set it manually so that initiaState is using correct laguage
// TODO in the furure there should be a reducer function that sets the language
// and then the values from initial state should be set
dayjs.locale(dayjsLocale);

const initialState = {
  activities: [],
  clients: {},
  clientsCount: 0,
  omama: {},
  supervisions: [],
  supervisionDetail: {},
  clientActionsByDay: [],
  actionsByDay: [],
  clientsArr: [],
  actionsById: {},
  allActionsByDay: [],
  actionID: '',
  clientModal: false,
  clientModalEnd: false,
  actionModal: false,
  questionsById: {},
  questionIds: [],
  startWeek: dayjs().startOf('week').format('YYYY-MM-DD'),
  endWeek: dayjs().endOf('week').format('YYYY-MM-DD'),
  startDate: dayjs().startOf('year').format(),
  endDate: dayjs().endOf('year').format(),
  clientScreenings: [],
  clientActions: [],
  clientProfileScreeningTable: {},
  initialScreeningsForClientProfile: [],
  oneTypeOneClientScreening: {},
  actionsCount: {
    lessons: {
      thisYear: 0,
      allTime: 0,
    },
    clubs: {
      thisYear: 0,
      allTime: 0,
    },
  },
  milestones: {},
  galleryFolders: {},
  rentedAccessories: [],
  rentedAccessoryStatus: {},
  rentedAccessoryInfo: {},
  actionPhotos: [],
  actionGallery: {},
};

export default (state = initialState, action) => {
  let updatedActionActivities;
  let updatedActionEvaluation;
  let updatedActionEvaluationQuestion;
  let updatedActionComment;
  let TEMPcheckedActivitiesDefault;
  let TEMPevaluationDefault;
  switch (action.type) {
    case GET_FINISHED_LESSONS_COUNT:
      return {
        ...state,
        actionsCount: {
          ...state.actionsCount,
          lessons: action.payload,
        },
      };
    case GET_FINISHED_CLUBS_COUNT:
      return {
        ...state,
        actionsCount: {
          ...state.actionsCount,
          clubs: action.payload,
        },
      };
    case GET_ACTIONS:
      return {
        ...state,
        activities: action.payload.all,
        actionsById: keyBy(action.payload.all, '_id'),
        clientActionsByDay: action.payload.clientActionsByDay,
        actionsByDay: action.payload.actionsByDay,
        allActionsByDay: action.payload.allActionsByDay,
      };
    case GET_OMAMA_CLIENTS:
      return {
        ...state,
        clients: keyBy(action.payload.clients, '_id'),
        clientsArr: action.payload.clients,
        clientsCount: action.payload.clients.length,
      };

    case GET_OMAMA_SUPERVISIONS:
      return {
        ...state,
        supervisions: action.payload,
      };

    case GET_OMAMA_SUPERVISION_DETAIL:
      return {
        ...state,
        supervisionDetail: action.payload,
      };

    case RESET_CLIENTS:
      return {
        ...state,
        clientsArr: [],
      };

    case MAKE_FOLDERS:
      return {
        ...state,
        galleryFolders: action.payload,
      };

    case REMOVE_PHOTO:
      const { clientID, photoID } = action.payload;
      const clients = { ...state.clients };
      clients[clientID].gallery = clients[clientID].gallery.filter((image) => {
        return image !== photoID;
      });
      const folders = { ...state.galleryFolders };
      Object.keys(folders).forEach((folder) => {
        folders[folder] = folders[folder].filter((image) => image !== photoID);
        if (folders[folder].length === 0) delete folders[folder];
      });
      return {
        ...state,
        clients,
        galleryFolders: folders,
      };

    case GET_OMAMA_TOKEN:
      return {
        ...state,
        omama: action.payload,
      };

    case RESET_OMAMA:
      return {
        ...state,
        omama: {},
      };

    case HANDLE_ACTION_CHECKBOX:
      updatedActionActivities = [...state.actionsById[action.payload.actionId].checkedActivities];
      const found = updatedActionActivities.find((element) => {
        return element === action.payload.activityId;
      });
      if (found) {
        let i;
        for (i = 0; i < updatedActionActivities.length; i++) {
          if (updatedActionActivities[i] === action.payload.activityId) {
            updatedActionActivities.splice(i, 1);
          }
        }
      } else {
        updatedActionActivities.push(action.payload.activityId);
      }
      return {
        ...state,
        actionsById: {
          ...state.actionsById,
          [action.payload.actionId]: {
            ...state.actionsById[action.payload.actionId],
            checkedActivities: updatedActionActivities,
          },
        },
      };
    case HANDLE_ACTION_RADIO:
      updatedActionEvaluation = [...state.actionsById[action.payload.actionId].evaluation];
      const foundIndex = updatedActionEvaluation.findIndex((element) => {
        return element._id === action.payload.activityId;
      });
      if (foundIndex !== -1) {
        updatedActionEvaluation[foundIndex].value = action.payload.value;
      }
      return {
        ...state,
        actionsById: {
          ...state.actionsById,
          [action.payload.actionId]: {
            ...state.actionsById[action.payload.actionId],
            evaluation: updatedActionEvaluation,
          },
        },
      };

    case HANDLE_ACTION_QUESTION:
      updatedActionEvaluationQuestion = {
        ...state.actionsById[action.payload.actionId].evaluation,
      };
      updatedActionEvaluationQuestion[action.payload.questionId] = action.payload.value;

      return {
        ...state,
        actionsById: {
          ...state.actionsById,
          [action.payload.actionId]: {
            ...state.actionsById[action.payload.actionId],
            evaluation: updatedActionEvaluationQuestion,
          },
        },
      };

    case HANDLE_ACTION_COMMENT:
      updatedActionComment = action.payload.comment;

      return {
        ...state,
        actionsById: {
          ...state.actionsById,
          [action.payload.actionId]: {
            ...state.actionsById[action.payload.actionId],
            comment: updatedActionComment,
          },
        },
      };
    case HANDLE_CLIENT_XBUTTON:
      if (state.actionsById[action.payload.actionId]) {
        TEMPcheckedActivitiesDefault = [...state.actionsById[action.payload.actionId].checkedActivitiesDefault];

        return {
          ...state,
          actionsById: {
            ...state.actionsById,
            [action.payload.actionId]: {
              ...state.actionsById[action.payload.actionId],
              checkedActivities: TEMPcheckedActivitiesDefault,
            },
          },
        };
      } else return state;
    case HANDLE_CLIENTEND_XBUTTON:
      TEMPevaluationDefault = {
        ...state.actionsById[action.payload.actionId].evaluationDefault,
      };
      return {
        ...state,
        actionsById: {
          ...state.actionsById,
          [action.payload.actionId]: {
            ...state.actionsById[action.payload.actionId],
            evaluation: TEMPevaluationDefault,
          },
        },
      };
    case SHOW_CLIENT_MODAL:
      return {
        ...state,
        actionID: action.payload.actionID,
        clientModal: state.clientModal === false || state.clientModalEnd === false,
        clientModalEnd: false,
      };
    case SHOW_CLIENT_MODAL_END:
      return {
        ...state,
        clientModal: false,
        clientModalEnd: !state.clientModalEnd,
      };

    case SHOW_ACTION_MODAL:
      return {
        ...state,
        actionID: action.payload.actionID,
        actionModal: true,
        clientModal: false,
      };
    case SHOW_ACTION_MODAL_END:
      return {
        ...state,
        actionModal: false,
        actionID: '',
      };

    case GET_SCREENING_QUESTIONS: {
      if (action.payload.data !== null) {
        const { questions, toDay, fromDay, _id } = action.payload.data;

        return {
          ...state,
          questionsById: keyBy(questions, 'id'),
          questionIds: questions.map((q) => q.id),
          screening: { toDay, fromDay, _id },
        };
      } else {
        return state;
      }
    }

    case API_CALL_FINISHED: {
      return { ...state, loading: false };
    }

    case API_CALL_STARTED: {
      return { ...state, loading: true };
    }

    case CHANGE_NEXT_WEEK:
      return {
        ...state,
        startWeek: action.payload.start,
        endWeek: action.payload.end,
      };

    case CHANGE_PREVIOUS_WEEK:
      return {
        ...state,
        startWeek: action.payload.start,
        endWeek: action.payload.end,
      };

    case CHANGE_CURRENT_WEEK:
      return {
        ...state,
        startWeek: action.payload.start,
        endWeek: action.payload.end,
      };

    case SELECT_DATE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case GET_CLIENT_SCREENINGS:
      return {
        ...state,
        clientScreenings: action.payload,
      };

    case GET_ACTIONS_FOR_CLIENT:
      return {
        ...state,
        clientActions: action.payload,
      };

    case CLIENTPROFILE_SCREENING_TABLE:
      return {
        ...state,
        clientProfileScreeningTable: action.payload,
      };

    case INITIAL_SKRININGS_FOR_CLIENTPROFILE:
      return {
        ...state,
        initialScreeningsForClientProfile: action.payload,
      };

    case GET_ONETYPE_ONECLIENT_SCREENINGS:
      return {
        ...state,
        oneTypeOneClientScreening: action.payload,
      };

    case GET_LENT_TOOLS:
      return {
        ...state,
        lentTools: action.payload,
      };

    case GET_OMAMA_MILESTONES:
      return {
        ...state,
        milestones: action.payload,
      };

    case GET_RENTED_ACCESSORIES:
      return {
        ...state,
        rentedAccessories: action.payload,
      };

    case GET_RENTED_ACCESSORY_STATUS:
      return {
        ...state,
        rentedAccessoryStatus: action.payload,
      };

    case GET_ACCESSORY_INFO:
      return {
        ...state,
        rentedAccessoryInfo: action.payload,
      };

    case RESET_ACCESSORY_DETAIL:
      return {
        ...state,
        rentedAccessoryStatus: {},
        rentedAccessoryInfo: {},
      };

    case GET_ACTION_PHOTOS:
      return {
        ...state,
        actionPhotos: action.payload,
      };

    case GET_OMAMA_ACTION_GALLERY:
      return {
        ...state,
        actionGallery: action.payload,
      };

    default:
      return state;
  }
};
