import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getOmamaClientsAction } from '../../actions/omama.actions';
import GalleryCard from '../../components/GalleryCard';
import { strings } from 'strings/StringsProvider';

function OmamaClientsGallery({ getOmamaClientsAction, changePage, clientsByArr }) {
  useEffect(() => {
    getOmamaClientsAction();
  }, [getOmamaClientsAction]);

  const navigateToGallery = (id) => {
    changePage('/omama/clientGallery/' + id);
  };

  return (
    <div className="galleryContainer">
      <div className="galleryCardWrapper">
        <GalleryCard
          key="parentalClubGallery"
          name={strings.parentalClub}
          changePage={() => changePage('/omama/omamaGallery/parentalClub')}
        />
        <GalleryCard
          key="otherWorkGallery"
          name={strings.otherJob}
          changePage={() => changePage('/omama/omamaGallery/otherWork')}
        />
        {clientsByArr.map((client) => (
          <GalleryCard
            key={client._id}
            name={client.name}
            photo={client._id}
            id={client._id}
            changePage={() => navigateToGallery(client._id)}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ omama }) => ({
  clientsByArr: omama.clientsArr,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOmamaClientsAction,
      changePage: (location) => push(location),
    },
    dispatch,
  );

OmamaClientsGallery.propTypes = {
  getOmamaClientsAction: PropTypes.func,
  clientsByArr: PropTypes.arrayOf(PropTypes.object),
  changePage: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OmamaClientsGallery);
