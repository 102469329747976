import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, Tag } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import './ScreeningFormResults.scss';

import { strings } from '../../../strings/StringsProvider';
import { getAdminClient, getClientScreeningsForReport } from '../../../actions/admin.actions';
import { getUsersAction } from '../../../actions/users.actions';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

function ScreeningFormResults() {
  const { clientId, screeningID, screeningType } = useParams();
  const history = useHistory();

  const client = useSelector((state) => state.admin.oneClient);
  const users = useSelector((state) => state.users.byId);
  const screeningReport = useSelector((state) => state.report.screeningReport);

  const dispatch = useDispatch();

  const changePage = (location) => dispatch(push(location));

  useEffect(() => {
    dispatch(getAdminClient(clientId));
    dispatch(getUsersAction());
  }, [dispatch, clientId]);

  useEffect(() => {
    if (client) {
      dispatch(getClientScreeningsForReport(client));
    }
  }, [dispatch, client]);

  const navigateToScreeningForm = (screeningType, clientID) => {
    changePage('/admin/screening/' + screeningType + '/klient/' + clientID);
  };

  let clientInfo = [
    {
      title: strings.firstName,
      data: `${client.firstName} ${client.lastName}`,
      key: 'name',
    },
    {
      title: strings.age,
      data: dayjs().diff(client.birthDate, 'months') + ' ' + strings.zeroOrFiveMonths,
      key: 'age',
    },
    {
      title: strings.city,
      data: client.city,
      key: 'city',
    },
    {
      title: strings.omama,
      data: users[client?.omamaID]?.name,
      key: 'omama',
    },
  ];

  let screeningIndex = screeningReport.findIndex((screening) => {
    return screening.key === screeningID;
  });

  let screeningInfo = [
    {
      title: strings.numberPP,
      data: screeningReport[screeningIndex]?.number,
      key: 'number',
    },
    {
      title: strings.date,
      data: screeningReport[screeningIndex]?.date,
      key: 'datum',
    },
    {
      title: strings.physicalAge,
      data: screeningReport[screeningIndex]?.age,
      key: 'age',
    },
  ];

  let screeningResults = [
    {
      title: strings.motorics,
      data: screeningReport[screeningIndex]?.motorics,
      key: 'motorics',
    },
    {
      title: strings.adaptiveBehaviour,
      data: screeningReport[screeningIndex]?.adaptive_behaviour,
      key: 'adaptive_behaviour',
    },
    {
      title: strings.communication,
      data: screeningReport[screeningIndex]?.communication,
      key: 'communication',
    },
    {
      title: strings.developmentScore,
      data: screeningReport[screeningIndex]?.development_score,
      key: 'development_score',
    },
    {
      title: strings.specificBehaviour,
      data: screeningReport[screeningIndex]?.specific_behaviour,
      key: 'specific_behaviour',
    },
    {
      title: strings.specificBehaviourScore,
      data: screeningReport[screeningIndex]?.specific_behaviour_score,
      key: 'specific_behaviour_score',
    },
    {
      title: strings.worries,
      data: screeningReport[screeningIndex]?.worries,
      key: 'worries',
    },
    {
      title: strings.worriesScore,
      data: screeningReport[screeningIndex]?.worries_score,
      key: 'worries_score',
    },
  ];

  return (
    <div className="container">
      <div className="container-inner">
        <div className="back" onClick={() => history.goBack()}>
          <LeftOutlined className="icon" type="left" /> {strings.backToTheList}
        </div>
        <div className="container-inner-info">
          {clientInfo.map((info) => {
            return (
              <div
                className="container-inner-info-item"
                key={info.key}
                data-test-id={`admin-screeningFromResults-info-${info.title}`}
              >
                <span className="container-inner-info-item-title">{`${info.title}: `}</span>
                <span>{info.data}</span>
              </div>
            );
          })}
        </div>
        <div className="container-inner-button">
          <Button className="adminClient-button butt" onClick={() => navigateToScreeningForm(screeningType, clientId)}>
            {strings.showQuestionnaire}
          </Button>
        </div>
        <div className="container-inner-results">
          <h3 className="container-inner-results-header">{strings.results}</h3>
          {screeningInfo.map((item) => {
            return (
              <div key={item.key} data-test-id={`admin-screeningFromResults-item-${item.title}`}>
                <h4 className="container-inner-results-title">{item.title}</h4>
                <p>{item.data}</p>
              </div>
            );
          })}

          {screeningResults.map((category) => {
            return (
              <div key={category.key} data-test-id={`admin-screeningFromResults-category-${category.title}`}>
                <h4 className="container-inner-results-title">{category.title}</h4>
                <p>
                  {!category.key.includes('score') ? (
                    <span>
                      {category.data &&
                        Object.keys(category.data).map((item) => {
                          let color = category.data[item] ? 'green' : 'red';
                          return (
                            <Tag color={color} key={item}>
                              {item}
                            </Tag>
                          );
                        })}
                    </span>
                  ) : (
                    <span>{category.data && <Tag color={category.data.pasmo}>{category.data.body}</Tag>}</span>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ScreeningFormResults;
