import React, { useEffect } from 'react';
import { goBack, push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import ProgressBar from '../../components/ProgressBar';
import { strings } from '../../strings/StringsProvider';
import { jwtDecode } from 'jwt-decode';
import { getOmamaSupervisionDetail, resetOmamaSupervisionDetail } from '../../actions/omama.actions';

import './SupervisionDetail.scss';

function SupervisionDetail({
  supervision,
  getOmamaSupervisionDetail,
  resetOmamaSupervisionDetail,
  changePage,
  match,
  goBack,
}) {
  useEffect(() => {
    getOmamaSupervisionDetail(match.params.supervisionId);
    return () => {
      resetOmamaSupervisionDetail();
    };
  }, [getOmamaSupervisionDetail, match, resetOmamaSupervisionDetail]);

  const getCategoryPoints = (category) => {
    const categories = supervision.questions;
    if (!categories || !categories[category]) {
      return [0, 0];
    }

    const questions = categories[category];
    let total = 0;
    let scored = 0;

    Object.values(questions).forEach((question) => {
      Object.values(question).forEach((checkbox) => {
        total += 1;
        if (checkbox) {
          scored += 1;
        }
      });
    });

    return [scored, total];
  };

  const handleGoBackClick = () => {
    const isOmama = jwtDecode(localStorage.getItem('access-token')).role === 'oamma';
    if (isOmama) {
      changePage('/omama/supervizie');
    } else {
      //this.props.changePage('/admin/user/' + this.props.omamaID + '/omama/supervizie/');
      goBack();
    }
  };

  const onChangePage = (url) => {
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    changePage(url);
  };

  const categories = [
    {
      text: strings.preparation,
      key: 'preparation',
    },
    {
      text: strings.followingManual,
      key: 'followingManual',
    },
    {
      text: strings.understandingPrinciple,
      key: 'understandingPrinciple',
    },
    {
      text: strings.executionOfActivity,
      key: 'executionOfActivity',
    },
    {
      text: strings.workWithChild,
      key: 'workWithChild',
    },
    {
      text: strings.workWithParent,
      key: 'workWithParent',
    },
  ];

  const supervisionCategories = categories.map((category) => {
    const [scored, total] = getCategoryPoints(category.key);
    category.scored = scored;
    category.total = total;
    return category;
  });

  const scoredTotal = supervisionCategories.reduce((acc, category) => acc + category.scored, 0);

  const maxTotal = supervisionCategories.reduce((acc, category) => acc + category.total, 0);

  return (
    <div className="container">
      <div className="container-inner">
        <div className="back" onClick={handleGoBackClick}>
          <LeftOutlined className="icon" /> {strings.backToTheList}
        </div>

        {!supervision.lection ? (
          <div className="message">{strings.lectureWasntFound}</div>
        ) : (
          <>
            <div className="container-inner-info">
              <div className="container-inner-info-date">
                <span className="container-inner-info-date-datum">{strings.date}:</span>
                <span>{supervision && dayjs(supervision.date).format('D. M. YYYY')}</span>
                <span className="container-inner-info-date-hodina">
                  {supervision && dayjs(supervision.date).add(1, 'hours').format('HH:mm')}
                </span>
              </div>
              <div className="container-inner-info-supervisor">
                <span className="container-inner-info-supervisor-header">{strings.supervision1}:</span>
                <span>{supervision.supervisorName}</span>
              </div>
            </div>
            <div className="container-inner-stats">
              <div className="container-inner-stats-header">
                <span className="container-inner-stats-header-total">
                  {scoredTotal}/{maxTotal} {strings.points1}
                </span>
                <div onClick={() => onChangePage(`/supervisor/form/${supervision._id}`)}>
                  <span>{strings.showInMoreDetail}</span>
                  <RightOutlined className="icon" />
                </div>
              </div>
              <div className="container-inner-stats-categories">
                {supervisionCategories.map((cat) => {
                  const percentage = (cat.scored / cat.total) * 100;
                  return (
                    <ProgressBar
                      text={cat.text}
                      key={supervision._id + cat.key}
                      current={cat.scored}
                      total={cat.total}
                      fillClass={percentage <= 50 ? ' red' : ''}
                    />
                  );
                })}
              </div>
            </div>
            <div className="container-inner-comment">
              <h4 className="container-inner-comment-head">{strings.comment}:</h4>
              <div>{supervision && supervision.comment}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ omama }) => {
  return {
    supervision: omama.supervisionDetail,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOmamaSupervisionDetail,
      resetOmamaSupervisionDetail,
      changePage: (location) => push(location),
      goBack: goBack,
    },
    dispatch,
  );

SupervisionDetail.propTypes = {
  supervision: PropTypes.object,
  getOmamaSupervisionDetail: PropTypes.func,
  resetOmamaSupervisionDetail: PropTypes.func,
  changePage: PropTypes.func,
  match: PropTypes.object,
  goBack: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisionDetail);
