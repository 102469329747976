import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { strings } from 'strings/StringsProvider';

import './AccessoryStatusDetail.scss';

const { TextArea } = Input;

function AccessoryStatusDetail({ name, request, closeModal, save }) {
  const [comment, setComment] = useState('');

  const checkData = () => {
    if (request) {
      if (isEmpty(comment)) return true;
    }

    return false;
  };

  const saveAccessoryStatus = () => {
    const body = {
      status: request ? 'missing' : 'rented',
      note: comment,
    };

    save(body);
  };

  return (
    <div>
      <Flex vertical className="descriptions">
        <div className="accessory-detail-item">
          <Typography.Title level={5}>{strings.toolName}</Typography.Title>
          {name}
        </div>

        <div className="accessory-detail-item">
          <Typography.Title level={5}>{strings.date}</Typography.Title>
          {dayjs().format('D. M. YYYY')}
        </div>

        <div className="accessory-detail-item">
          <Typography.Title level={5}>{strings.comment}</Typography.Title>
          <div className="accessory-detail-textarea">
            <TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={request ? strings.mandatoryComment : strings.optionalComment}
              rows={4}
              style={{ minHeight: '100px' }}
              data-test-id="admin-accessory-status-detail-comment"
            />
          </div>
        </div>
      </Flex>

      <div className="accessory-status-detail-buttons">
        <Button
          className="rented-accessories-active-button"
          data-test-id="admin-rented-accessories-detail-modal-save-button"
          onClick={saveAccessoryStatus}
          disabled={checkData()}
        >
          {strings.save}
        </Button>

        <Button className="rented-accessories-inactive-button" onClick={closeModal}>
          {strings.cancel}
        </Button>
      </div>
    </div>
  );
}

AccessoryStatusDetail.propTypes = {
  name: PropTypes.string,
  request: PropTypes.bool,
  closeModal: PropTypes.func,
  save: PropTypes.func,
};

export default AccessoryStatusDetail;
