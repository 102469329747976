import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { strings } from '../../strings/StringsProvider';
import '../ActionDetailAdmin.scss';

function ClientHeader(props) {
  return (
    <>
      <div className="clientHeader">{props.isClub ? strings.preschoolClub : strings.lesson}</div>
      <div className="actionHeader">
        <CloseOutlined
          className="actionCloseIcon"
          onClick={() => props.showClientModal('', props.action._id, 'client')}
        />
        <div>{dayjs(props.action.date).format('dddd D. M. YYYY, HH:mm')}</div>
        {!props.isClub && props.client.birthDate && (
          <div className="actionClientInfo">
            <div
              onClick={() => props.showDevelopmentStageDetail(props.client.birthDate, props.action.date)}
              className="openDevStageModal"
            >
              {props.client.name} {dayjs(props.client.birthDate).format('D. M. YYYY')} (
              {props.formatChildWeek(props.client.birthDate, props.action.date)})
            </div>
            <div className="devStageTooltipIcon">
              <Tooltip title={strings.clientDevelopmentStageTooltip}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </div>
        )}
        {Object.keys(props.birthDates).length > 0 &&
          props.birthDates.map((e) => {
            return (
              <div key={e._id}>
                {e.name ? e.name : <div className="missingClientWarning">{e._id}</div>}
                <span style={{ paddingLeft: '10px' }}>{e.birthDate && props.formatChildWeek(e.birthDate)}</span>
              </div>
            );
          })}
        {!props.client.active && (
          <p className="missingClientWarning">
            {strings.thisClientMissing}
            <br />
          </p>
        )}
        {!props.birthDates.every((client) => client.name) && (
          <p className="missingClientWarning">
            {strings.someClientMissing}
            <br />
          </p>
        )}
      </div>
    </>
  );
}

ClientHeader.propTypes = {
  isClub: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  showClientModal: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  birthDates: PropTypes.array,
  formatChildWeek: PropTypes.func.isRequired,
  showDevelopmentStageDetail: PropTypes.func.isRequired,
};

export default ClientHeader;
