export const GET_ALL_CLIENT_MEASUREMENTS = 'GET_ALL_CLIENT_MEASUREMENTS';
export const POPULATE_INTERNDA_FORM = 'POPULATE_INTERNDA_FORM';
export const RESET_INTERNDA_FORM = 'RESET_INTERNDA_FORM';
export const POPULATE_PRESCHOOL_SCREENING = 'POPULATE_PRESCHOOL_SCREENING';
export const HANDLE_FORM_ANSWERS_CHANGE = 'HANDLE_FORM_ANSWERS_CHANGE';

export const initialState = {
  interNDA: null,
  preschoolScrening: null,
  interNDAAnswers: {},
  interNDAQuestions: [],
  preschoolScreeningAnswers: {},
  preschoolScreeningQuestions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLIENT_MEASUREMENTS:
      return {
        ...state,
        interNDA: action.payload.interNDA,
        preschoolScreening: action.payload.preschoolScreening,
      };
    case POPULATE_INTERNDA_FORM:
      return {
        ...state,
        interNDAAnswers: {
          ...state.data,
          ...action.payload.interNDAAnswers,
        },
        interNDAQuestions: action.payload.interNDAQuestions,
      };
    case RESET_INTERNDA_FORM:
      return {
        ...state,
        interNDAAnswers: {},
        interNDAQuestions: [],
      };
    case POPULATE_PRESCHOOL_SCREENING:
      return {
        ...state,
        preschoolScreeningAnswers: {
          ...state.data,
          ...action.payload.preschoolScreeningAnswers,
        },
        preschoolScreeningQuestions: action.payload.preschoolScreeningQuestions,
      };
    case HANDLE_FORM_ANSWERS_CHANGE:
      return {
        ...state,
        preschoolScreeningAnswers: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
