import React from 'react';
import { Modal } from 'antd';
import './PopUp.scss';
import PropTypes from 'prop-types';

function PopUp({ messageText, messageResult, cleanMessage }) {
  const handleCancel = () => {
    cleanMessage();
  };

  return (
    <div>
      <Modal
        className="modal"
        open={true}
        closable={false}
        centered={true}
        footer={null}
        styles={{ backgroundColor: 'rgba(140, 140, 140, 0.65)' }}
        onCancel={handleCancel}
      >
        {messageResult ? (
          <p className="textSuccess">&#10004;&nbsp; {messageText}</p>
        ) : (
          <p className="textFail">&#10006;&nbsp;&nbsp;{messageText}</p>
        )}
      </Modal>
    </div>
  );
}

PopUp.propTypes = {
  messageText: PropTypes.string,
  messageResult: PropTypes.bool,
  cleanMessage: PropTypes.func,
};

export default PopUp;
