import React, { useRef, useState } from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { api } from '../../conf/api';
import { getOmamaClientsAction, resetOmamaClientsAction } from '../../actions/omama.actions';
import { Upload, Button, Alert, Slider } from 'antd';
import { LeftOutlined, UploadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { strings } from '../../strings/StringsProvider';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';

function ChangeClientPhoto({ client, toggleChangePhoto }) {
  const [fileList, setFileList] = useState([]);
  const [scale, setScale] = useState(1);
  const [alertMessage, setAlertMessage] = useState('');

  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const uploadProps = () => {
    return {
      beforeUpload: (file) => {
        setFileList([file]);
        setAlertMessage('');
        return false;
      },

      fileList,
    };
  };

  const getClientPhoto = () => {
    if (editorRef) {
      return editorRef.current.getImageScaledToCanvas().toDataURL();
    }
    return '';
  };

  const submitPicture = async () => {
    let { ...set } = cloneDeep(client);

    try {
      const { data } = await axios.post(api.editClient, {
        id: client._id,
        photo: getClientPhoto(),
        set,
      });

      if (data.success) {
        toggleChangePhoto();
        dispatch(resetOmamaClientsAction());
        dispatch(getOmamaClientsAction());
      }
    } catch (error) {
      setAlertMessage(strings.errorChangingPicture);
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 800,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          height: '50px',
          backgroundColor: 'black',
          color: 'white',
          fontSize: '1.1rem',
          lineHeight: '50px',
        }}
      >
        <LeftOutlined
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            cursor: 'pointer',
            verticalAlign: 'middle',
          }}
          title="Späť"
          onClick={toggleChangePhoto}
        />
        {strings.changePictureOfClient}
      </div>
      <div style={{ padding: '16px 30px 0 30px' }}>
        <Upload className="settings-button settings-upload" showUploadList={false} {...uploadProps()}>
          <UploadOutlined /> {strings.choosePhoto}
        </Upload>
        {fileList[0] && (
          <div className="settings-avatar">
            <AvatarEditor
              ref={editorRef}
              image={fileList[0]}
              width={250}
              height={250}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              scale={scale}
              rotate={0}
            />
            <div className="icon-wrapper">
              <ZoomOutOutlined />
              <Slider min={1} max={10} step={0.1} value={scale} onChange={setScale} />
              <ZoomInOutlined />
            </div>
          </div>
        )}
        <Button
          className="settings-button"
          type="primary"
          style={{ marginTop: 16 }}
          shape="round"
          onClick={submitPicture}
          disabled={!fileList[0]}
        >
          {strings.changePicture}
        </Button>
        {alertMessage && (
          <Alert type="error" message={alertMessage} style={{ marginTop: '10px', marginBottom: '10px' }} />
        )}
      </div>
    </div>
  );
}

ChangeClientPhoto.propTypes = {
  client: PropTypes.shape({
    _id: PropTypes.string,
  }),
  toggleChangePhoto: PropTypes.func,
  resetOmamaClientsAction: PropTypes.func,
  getOmamaClientsAction: PropTypes.func,
};

export default ChangeClientPhoto;
