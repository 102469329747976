import { GET_CLIENT_ACTIVITIES } from '../reducers/clientActivities.reducer';

import { SET_LOADING } from '../reducers/status.reducer';

import {
  HANDLE_ACTION_CHECKBOX,
  HANDLE_ACTION_RADIO,
  HANDLE_ACTION_COMMENT,
  HANDLE_CLIENT_XBUTTON,
  HANDLE_CLIENTEND_XBUTTON,
  HANDLE_ACTION_QUESTION,
  CHANGE_CURRENT_WEEK,
} from '../reducers/omama.reducer';

// Local imports
import { api } from '../conf';
import { getActionsAction, showClientModal } from './omama.actions';
import { setLoading } from './status.actions';
import { orderBy } from 'lodash';
import axios from 'axios';

export const getActivitiesAction = () => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getALLActivities);

    const tempActivitiesType1 = {};
    const tempActivitiesType2 = {};
    const tempActivitiesType3 = {};
    const tempActivitiesType4 = {};
    const tempActivitiesType5 = {};
    const tempActivitiesType6 = {};
    const activitiesAll = [];

    data.activities.forEach((ac) => {
      activitiesAll.push(ac);
      const type = ac.type;
      const interval = `${ac.from} - ${ac.to}`;

      switch (type) {
        /* eslint-disable */
        case 1:
          if (tempActivitiesType1[interval]) {
            tempActivitiesType1[interval].push(ac);
          } else {
            tempActivitiesType1[interval] = [ac];
          }
          break;
        case 2:
          if (tempActivitiesType2[interval]) {
            tempActivitiesType2[interval].push(ac);
          } else {
            tempActivitiesType2[interval] = [ac];
          }
          break;
        case 3:
          if (tempActivitiesType3[interval]) {
            tempActivitiesType3[interval].push(ac);
          } else {
            tempActivitiesType3[interval] = [ac];
          }
          break;
        case 4:
          if (tempActivitiesType4[interval]) {
            tempActivitiesType4[interval].push(ac);
          } else {
            tempActivitiesType4[interval] = [ac];
          }
          break;
        case 5:
          if (tempActivitiesType5[interval]) {
            tempActivitiesType5[interval].push(ac);
          } else {
            tempActivitiesType5[interval] = [ac];
          }
          break;
        case 6:
          if (tempActivitiesType6[interval]) {
            tempActivitiesType6[interval].push(ac);
          } else {
            tempActivitiesType6[interval] = [ac];
          }
          break;
        default:
        /* eslint-enable */
      }
    });

    const activitiesType1 = [];
    const activitiesType2 = [];
    const activitiesType3 = [];
    const activitiesType4 = [];
    const activitiesType5 = [];
    const activitiesType6 = [];
    Object.keys(tempActivitiesType1).forEach((item) =>
      activitiesType1.push({
        interval: item,
        Activities: tempActivitiesType1[item],
      }),
    );

    activitiesType1.forEach((interval) => {
      let temporary = interval.Activities;
      interval.Activities = orderBy(temporary, ['name'], ['asc']);
    });

    Object.keys(tempActivitiesType2).forEach((item) =>
      activitiesType2.push({
        interval: item,
        Activities: tempActivitiesType2[item],
      }),
    );
    activitiesType2.forEach((interval) => {
      let temporary = interval.Activities;
      interval.Activities = orderBy(temporary, ['name'], ['asc']);
    });

    Object.keys(tempActivitiesType3).forEach((item) =>
      activitiesType3.push({
        interval: item,
        Activities: tempActivitiesType3[item],
      }),
    );
    activitiesType3.forEach((interval) => {
      let temporary = interval.Activities;
      interval.Activities = orderBy(temporary, ['name'], ['asc']);
    });

    Object.keys(tempActivitiesType4).forEach((item) =>
      activitiesType4.push({
        interval: item,
        Activities: tempActivitiesType4[item],
      }),
    );
    activitiesType4.forEach((interval) => {
      let temporary = interval.Activities;
      interval.Activities = orderBy(temporary, ['name'], ['asc']);
    });

    Object.keys(tempActivitiesType5).forEach((item) =>
      activitiesType5.push({
        interval: item,
        Activities: tempActivitiesType5[item],
      }),
    );
    activitiesType5.forEach((interval) => {
      let temporary = interval.Activities;
      interval.Activities = orderBy(temporary, ['name'], ['asc']);
    });

    Object.keys(tempActivitiesType6).forEach((item) =>
      activitiesType6.push({
        interval: item,
        Activities: tempActivitiesType6[item],
      }),
    );
    activitiesType6.forEach((interval) => {
      let temporary = interval.Activities;
      interval.Activities = orderBy(temporary, ['name'], ['asc']);
    });

    return dispatch({
      type: GET_CLIENT_ACTIVITIES,
      payload: {
        all: activitiesAll,
        type1: activitiesType1,
        type2: activitiesType2,
        type3: activitiesType3,
        type4: activitiesType4,
        type5: activitiesType5,
        type6: activitiesType6,
      },
    });
  };
};

export const changeClientAction = (actionId, data, start, end) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const body = {
      id: actionId,
      set: {
        ...data,
      },
    };

    try {
      await axios.post(api.updateAction, body);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }

    dispatch({
      type: CHANGE_CURRENT_WEEK,
      payload: {
        start: start,
        end: end,
      },
    });
    await dispatch(getActionsAction(start, end));
  };
};

export function postCheckedActivities(action, start, end) {
  return async (dispatch) => {
    // ClientEnd REWORKED

    // V1
    // const evaluationActivities =  action.checkedActivities.map( id =>  {
    //   return {_id: id, value: null};
    // });

    const data = {
      id: action._id,
      set: {
        checkedActivities: action.checkedActivities,
        // V1
        // evaluation : evaluationActivities,

        // v2
        evaluation: {
          question1: null,
          question2: null,
          question3: null,
        },
      },
    };

    try {
      dispatch(setClientLoading(true));
      await axios.post(api.postCheckedActivities, data);
      dispatch(setClientLoading(false));
    } catch (error) {
      dispatch(setClientLoading(false));
    }
    dispatch(getActionsAction(start, end));
  };
}

export function cancelClientAction(action, key, start, end) {
  return async (dispatch) => {
    if (key === 'Natvrdo odstrániť lekciu') {
      const data = {
        id: action._id,
      };
      try {
        dispatch(setClientLoading(true));
        await axios.post(api.deleteAction, data);
        dispatch(setClientLoading(false));
      } catch (error) {
        dispatch(setClientLoading(false));
      }
    } else {
      const data = {
        id: action._id,
        set: {
          comment: action.comment,
          status: `canceled - ${key}`,
        },
      };

      try {
        dispatch(setClientLoading(true));
        await axios.post(api.updateAction, data);
        dispatch(setClientLoading(false));
      } catch (error) {
        dispatch(setClientLoading(false));
      }
    }

    dispatch(getActionsAction(start, end));
  };
}

export function cancelActionAction(action, start, end) {
  return async (dispatch) => {
    const data = {
      id: action._id,
    };
    try {
      dispatch(setClientLoading(true));
      await axios.post(api.deleteAction, data);
      dispatch(setClientLoading(false));
    } catch (error) {
      dispatch(setClientLoading(false));
    }
    dispatch(getActionsAction(start, end));
  };
}

export const postActivitiesEnd = (action, clientID, photos, thumbnails, datesTaken, start, end, hasPhoto) => {
  return async (dispatch) => {
    const data = {
      id: action._id,
      set: {
        evaluation: action.evaluation,
        comment: action.comment,
        status: 'done',
        hasPhoto,
      },
    };
    async function uploadImage(photos, thumbnails, datesTaken, id, actionId) {
      var i;
      for (i = 0; i < photos.length; i++) {
        let tempBody = {
          photo: photos[i],
          thumbnail: thumbnails[i],
          imageDateTaken: datesTaken[i],
          clientID: id,
          actionID: actionId,
        };
        await axios.post(api.addNewPhoto, tempBody);
      }
    }

    try {
      dispatch(setClientLoading(true));
      await uploadImage(photos, thumbnails, datesTaken, clientID, action._id);
      await axios.post(api.postCheckedActivities, data);
      dispatch(setClientLoading(false));
      dispatch(showClientModal('')); // Zatvorenie posledneho modalu
    } catch (error) {
      dispatch(setClientLoading(false));
    }
    dispatch(getActionsAction(start, end));
  };
};

export const handleActionCheckBox = (actionId, activityId) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_ACTION_CHECKBOX,
      payload: {
        activityId: activityId,
        actionId: actionId,
      },
    });
  };
};

export const handleActionRadio = (actionId, activityId, value) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_ACTION_RADIO,
      payload: {
        activityId: activityId,
        actionId: actionId,
        value: value,
      },
    });
  };
};

export const handleActionQuestion = (actionId, questionId, value) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_ACTION_QUESTION,
      payload: {
        // activityId: activityId, // V1
        actionId: actionId,
        questionId: questionId,
        value: value,
      },
    });
  };
};

export const handleActionComment = (actionId, comment) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_ACTION_COMMENT,
      payload: {
        actionId: actionId,
        comment: comment,
      },
    });
  };
};

export const handleClientXButton = (actionId) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_CLIENT_XBUTTON,
      payload: {
        actionId: actionId,
      },
    });
  };
};

export const handleClientEndXButton = (actionId) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_CLIENTEND_XBUTTON,
      payload: {
        actionId: actionId,
      },
    });
  };
};

export function setClientLoading(loading) {
  return async (dispatch) => {
    return dispatch({
      type: SET_LOADING,
      payload: {
        loading: loading,
      },
    });
  };
}
