import React from 'react';
import PropTypes from 'prop-types';
import { RightOutlined } from '@ant-design/icons';

import './Supervision.scss';

function Supervision({ date, time, supervisor, points, changePage }) {
  const onChangePage = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    changePage();
  };

  return (
    <div className="item" onClick={() => onChangePage()}>
      <div className="datum">{date}</div>
      <div className="time">{time}</div>
      <div className="supervisor">
        {supervisor && `${supervisor.split(' ')[0]} ${(supervisor.split(' ')[1] || '').charAt(0)}.`}
      </div>
      <div className="points">{points && `${points.value}/${points.max}`}</div>
      <div>
        <RightOutlined className="icon" />
      </div>
    </div>
  );
}

Supervision.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
  supervisor: PropTypes.string,
  points: PropTypes.object,
  changePage: PropTypes.func,
};

export default Supervision;
