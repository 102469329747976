import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { strings } from '../../../strings/StringsProvider';

function ActivityInfo({ activity, goBack, mobileActivity }) {
  const [youtubeLinksParsed, setYoutubeLinksParsed] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const activityVideos = activity.youtubeVideos || [];
    const youtubeLinksParsed = activityVideos
      ?.filter((url) => !!url)
      .map((url) => {
        const parsed = url.split('/');
        return parsed[parsed.length - 1];
      });
    setYoutubeLinksParsed(youtubeLinksParsed);
  }, [activity.youtubeVideos]);

  return (
    <div
      key={activity._id}
      className={classNames('activityInfo-main', {
        'activityInfo-main-mobile': mobileActivity,
      })}
    >
      <Descriptions
        className={classNames('styleDescriptions', {
          'styleDescriptions-mobile': mobileActivity,
        })}
      >
        <Descriptions.Item className="styleName displayItem">
          {activity.name}
          <p>{activity.code}</p>
        </Descriptions.Item>
        <Descriptions.Item className="styleText" label={strings.goal}>
          {activity.ciel}
        </Descriptions.Item>
        <Descriptions.Item className="styleText" label={strings.helperThings}>
          {activity.pomocky}
        </Descriptions.Item>
        {activity.pomockyPhotos && (
          <Descriptions.Item className="styleText" label={strings.photoOfHelperThing}>
            {activity.pomockyPhotos.map((photo) => (
              <img className="activityPhoto" src={photo} key={photo} alt="Smiley face" />
            ))}
          </Descriptions.Item>
        )}
        <Descriptions.Item className="styleText" label={strings.storyOfActivity}>
          <span style={{ whiteSpace: 'pre-wrap' }} className="markdownPreview aligned">
            <ReactMarkdown>{activity.priebeh}</ReactMarkdown>
          </span>
        </Descriptions.Item>
        {activity.priebehPhotos && (
          <Descriptions.Item className="styleText" label={strings.photoOfStory}>
            {activity.priebehPhotos.map((photo) => (
              <img className="activityPhoto" src={photo} key={photo} alt="Smiley face" />
            ))}
          </Descriptions.Item>
        )}
        <Descriptions.Item className="styleText" label="Video">
          {youtubeLinksParsed.map((youtubeLink) => (
            <iframe
              height="315"
              title="youtubevideo"
              src={`https://www.youtube.com/embed/${youtubeLink}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              key={youtubeLink}
            ></iframe>
          ))}
        </Descriptions.Item>
        <Descriptions.Item className="styleText">
          {activity.link && (
            <a href={activity.link} rel="noopener noreferrer" target="_blank">
              {strings.documentLink}
            </a>
          )}
        </Descriptions.Item>
        <Descriptions.Item className="styleName displayItem">
          <p className="name">
            <LeftOutlined className="styleIcon" onClick={() => goBack()} />
          </p>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

ActivityInfo.propTypes = {
  activity: PropTypes.object,
  goBack: PropTypes.func,
  mobileActivity: PropTypes.bool,
};

export default ActivityInfo;
