import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Modal, Checkbox } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { isEmpty } from 'lodash';
import { stringSorter, stringSorterBy } from 'tools/utils';
import {
  getAccessories,
  createAccessory,
  updateAccessory,
  removeAccessory,
  getMissingAccessories,
  getAdminUser,
  getMissingAccessoriesCount,
} from 'actions/admin.actions';
import { changeOmamaAccessoryStatus } from 'actions/omama.actions';
import { strings } from 'strings/StringsProvider';
import AccessoryDetail from './AccessoryDetail';
import { providedByMap } from 'data/enums';
import AccessoryStatusDetail from 'components/AccessoryStatusDetail';
import MissingAccessoryDetail from './MissingAccessoryDetail';
import SearchBar from 'components/SearchBar';

import './Accessories.scss';

const columns = [
  {
    title: strings.accessory,
    dataIndex: 'name',
    key: 'name',
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('name'),
  },
  {
    title: strings.onePhoto,
    dataIndex: 'photoUrl',
    key: 'photoUrl',
    render: (photo) =>
      isEmpty(photo) ? (
        <div></div>
      ) : (
        <div>
          <img src={photo} alt="" className="accessory-image" />
        </div>
      ),
  },
  {
    title: strings.providedBy,
    dataIndex: 'providedBy',
    key: 'providedBy',
    render: (providedBy) => providedByMap[providedBy],
  },
  {
    title: strings.omamasWithAccessory,
    dataIndex: 'users',
    key: 'users',
    render: (users) => {
      const usersCount = users.length;
      if (usersCount === 1) return `${usersCount} ${strings.oneOmama}`;
      else if (usersCount > 1 && usersCount < 5) return `${usersCount} ${strings.twoAndMoreOmamas}`;
      else return `${usersCount} ${strings.fiveAndMoreOmamas}`;
    },
  },
  {
    title: strings.modifiedDate,
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (_, record) => {
      if (record.updatedAt) {
        return dayjs(record.updatedAt).format('D. M. YYYY');
      } else {
        return dayjs(record.createdAt).format('D. M. YYYY');
      }
    },
  },
];

const columnsMobile = [
  {
    title: strings.accessory,
    dataIndex: 'name',
    key: 'name',
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('name'),
    width: '35%',
  },
  {
    title: strings.providedBy,
    dataIndex: 'providedBy',
    key: 'providedBy',
    width: '35%',
    render: (providedBy) => providedByMap[providedBy],
  },
  {
    title: strings.modifiedDate,
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '20%',
    render: (_, record) => {
      if (record.updatedAt) {
        return dayjs(record.updatedAt).format('D. M. YYYY');
      } else {
        return dayjs(record.createdAt).format('D. M. YYYY');
      }
    },
  },
  {
    title: '',
    width: '5%',
    render: () => <RightOutlined className="accessories-table-mobile-icon" />,
  },
];

const columnsMissing = (onButtonClickHandler) => [
  {
    title: strings.region,
    dataIndex: 'omamaRegion',
    key: 'omamaRegion',
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('omamaRegion'),
  },
  {
    title: strings.providedBy,
    dataIndex: 'providedBy',
    key: 'providedBy',
    render: (providedBy) => providedByMap[providedBy],
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorterBy('providedBy', providedByMap),
  },
  {
    title: strings.omama,
    dataIndex: 'omamaName',
    key: 'omamaName',
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('omamaName'),
  },
  {
    title: strings.accessory,
    dataIndex: 'name',
    key: 'name',
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('name'),
  },
  {
    title: strings.comment,
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: strings.modifiedDate,
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (_, record) => {
      if (record.updatedAt) {
        return dayjs(record.updatedAt).format('D. M. YYYY');
      } else {
        return dayjs(record.createdAt).format('D. M. YYYY');
      }
    },
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('updatedAt'),
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    render: (record) => (
      <Button className="accessories-deliver-button" onClick={() => onButtonClickHandler(record)}>
        {strings.deliverAccessory}
      </Button>
    ),
  },
];

const columnsMissingMobile = [
  {
    title: strings.accessory,
    dataIndex: 'name',
    key: 'name',
    sortDirections: ['ascend', 'descend'],
    sorter: stringSorter('name'),
    width: '35%',
  },
  {
    title: strings.omama,
    dataIndex: 'omamaName',
    key: 'omamaName',
    width: '35%',
  },
  {
    title: strings.modifiedDate,
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '20%',
    render: (_, record) => {
      if (record.updatedAt) {
        return dayjs(record.updatedAt).format('D. M. YYYY');
      } else {
        return dayjs(record.createdAt).format('D. M. YYYY');
      }
    },
  },
  {
    title: '',
    width: '5%',
    render: () => <RightOutlined className="accessories-table-mobile-icon" />,
  },
];

function Accessories() {
  const [missingAccessoriesTable, setMissingAccessoriesTable] = useState(false);
  const [search, setSearch] = useState('');
  const [searchMissing, setSearchMissing] = useState('');
  const [missingAccessoriesToShow, setMissingAccessoriesToShow] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [addAccessory, setAddAccessory] = useState(false);
  const [accessoryDetail, setAccessoryDetail] = useState(null);
  const [edit, setEdit] = useState(false);
  const [deliverAccessoryModal, setDeliverAccessoryModal] = useState(false);
  const [deliverAccessory, setDeliverAccessory] = useState(null);
  const [missingAccesoryModal, setMissingAccessoryModal] = useState(false);
  const [missingAccessoryDetail, setMissingAccessoryDetail] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const userName = jwtDecode(localStorage.getItem('access-token')).username;

  const user = useSelector((state) => state.admin.oneUser);
  const accessories = useSelector((state) => state.admin.accessories);
  const allMissingAccessories = useSelector((state) => state.admin.missingAccessories);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminUser(userName));
    dispatch(getAccessories());
    dispatch(getMissingAccessories());
    dispatch(getMissingAccessoriesCount());
  }, [dispatch, userName]);

  const filterRegionalMissing = useCallback(() => {
    const regionalMissingAccessories =
      allMissingAccessories &&
      allMissingAccessories.filter(
        (missingAccessory) =>
          missingAccessory.providedBy === 'regionalCoordinator' && missingAccessory.omamaRegion === user.region,
      );
    setMissingAccessoriesToShow(regionalMissingAccessories);
  }, [allMissingAccessories, user]);

  const filterCentralMissing = useCallback(() => {
    const centralMissingAccessories =
      allMissingAccessories &&
      allMissingAccessories.filter((missingAccessory) => missingAccessory.providedBy === 'centralCoordinator');
    setMissingAccessoriesToShow(centralMissingAccessories);
  }, [allMissingAccessories]);

  useEffect(() => {
    if (user && user.accessoriesCoordinator && allMissingAccessories) {
      setMissingAccessoriesTable(true);
      if (showAll) {
        setMissingAccessoriesToShow(allMissingAccessories);
      } else if (user.accessoriesCoordinator === 'regionalCoordinator') {
        filterRegionalMissing();
      } else {
        filterCentralMissing();
      }
    }
    if (initialLoading) {
      setInitialLoading(false);
    }
  }, [user, allMissingAccessories, showAll, initialLoading, filterRegionalMissing, filterCentralMissing]);

  const filterAccessories = useCallback((accessoriesToFilter, filter) => {
    return accessoriesToFilter.filter((accessory) => {
      const query = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase();

      return accessory.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .includes(query);
    });
  }, []);

  const filterMissingAccessoriesBySearch = useCallback((accessoriesToFilter, filter) => {
    return accessoriesToFilter.filter((accessory) => {
      const query = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase();

      return (
        accessory.omamaRegion
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(query) ||
        providedByMap[accessory.providedBy]
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(query) ||
        accessory.omamaName
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(query) ||
        accessory.name
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(query)
      );
    });
  }, []);

  const addAccessoryOpen = () => {
    setAddAccessory(true);
  };

  const addAccessoryClose = () => {
    setAddAccessory(false);
    setAccessoryDetail(null);
    setEdit(false);
  };

  const openAccessoryDetail = (accessory) => {
    setAccessoryDetail(accessory);
    setEdit(true);
    addAccessoryOpen();
  };

  const createAccessoryHandler = (body, photo) => {
    dispatch(createAccessory(body, photo)).then(() => {
      addAccessoryClose();
      dispatch(getAccessories());
    });
  };

  const updateAccessoryHandler = (body, id, photo) => {
    dispatch(updateAccessory(body, id, photo)).then(() => {
      addAccessoryClose();
      dispatch(getAccessories());
    });
  };

  const removeAccessoryHandler = (id) => {
    dispatch(removeAccessory(id)).then(() => {
      addAccessoryClose();
      dispatch(getAccessories());
    });
  };

  const deliverAccessoryOpen = (accessory) => {
    setDeliverAccessoryModal(true);
    setDeliverAccessory(accessory);
  };

  const deliverAccessoryClose = () => {
    setDeliverAccessoryModal(false);
    setDeliverAccessory(null);
  };

  const confirmDelivery = (body) => {
    dispatch(changeOmamaAccessoryStatus(deliverAccessory.accessoryId, deliverAccessory.userId, body)).then(() =>
      dispatch(getMissingAccessories()),
    );
    deliverAccessoryClose();
    missingAccessoryClose();
  };

  const missingAccessoryOpen = (accessory) => {
    setMissingAccessoryModal(true);
    setMissingAccessoryDetail(accessory);
  };

  const missingAccessoryClose = () => {
    setMissingAccessoryModal(false);
    setMissingAccessoryDetail(null);
  };

  const data = !isEmpty(accessories) ? filterAccessories(accessories, search) : null;
  const missingAccessoriesData = !isEmpty(missingAccessoriesToShow)
    ? filterMissingAccessoriesBySearch(missingAccessoriesToShow, searchMissing)
    : null;

  return (
    <div className="statsBackground">
      <div className="adminBox">
        <div className="accessories-main">
          <div className="accessories-choose-table">
            {user && user.accessoriesCoordinator && (
              <div onClick={() => setMissingAccessoriesTable(true)}>
                <Button
                  className={
                    initialLoading
                      ? 'accessories-active-button'
                      : missingAccessoriesTable
                        ? 'accessories-active-button'
                        : 'accessories-inactive-button'
                  }
                  type={initialLoading ? 'primary' : missingAccessoriesTable ? 'primary' : 'secondary'}
                >
                  {strings.missingAccessories}
                </Button>
              </div>
            )}
            {user && (
              <div onClick={() => setMissingAccessoriesTable(false)}>
                <Button
                  className={
                    initialLoading && user.accessoriesCoordinator
                      ? 'accessories-inactive-button'
                      : missingAccessoriesTable
                        ? 'accessories-inactive-button'
                        : 'accessories-active-button'
                  }
                  type={
                    initialLoading && user.accessoriesCoordinator
                      ? 'secondary'
                      : missingAccessoriesTable
                        ? 'secondary'
                        : 'primary'
                  }
                >
                  {strings.accessoriesList}
                </Button>
              </div>
            )}
          </div>
          <div>
            {missingAccessoriesTable && user && user.accessoriesCoordinator && (
              <div>
                <div className="table-manipulation missing-desktop">
                  <div className="missing-search-input-container">
                    <SearchBar
                      placeholder={strings.search}
                      value={searchMissing}
                      onChange={(e) => setSearchMissing(e.target.value)}
                    />
                  </div>
                  <div className="new-table-control-checkbox">
                    <Checkbox checked={showAll} onChange={() => setShowAll((previousValue) => !previousValue)}>
                      {strings.showAllMissingAccessories}
                    </Checkbox>
                  </div>
                </div>
                <div className="table-manipulation missing-mobile">
                  <div className="missing-search-input-container">
                    <Input
                      className="accessories-input search"
                      placeholder={strings.search}
                      value={searchMissing}
                      onChange={(e) => setSearchMissing(e.target.value)}
                    />
                  </div>
                  <div className="filter-checkbox">
                    <Checkbox checked={showAll} onChange={() => setShowAll((previousValue) => !previousValue)}>
                      {strings.showAllMissingAccessories}
                    </Checkbox>
                  </div>
                </div>
                <Table
                  className="new-table missing-accessories-table-desktop"
                  rowKey="_id"
                  dataSource={missingAccessoriesData}
                  columns={columnsMissing(deliverAccessoryOpen)}
                />
                <Table
                  className="table accessories-table-mobile"
                  rowKey="_id"
                  dataSource={missingAccessoriesData}
                  columns={columnsMissingMobile}
                  onRow={(record) => ({
                    onClick: () => missingAccessoryOpen(record),
                  })}
                  size="small"
                />
              </div>
            )}
          </div>
          <div>
            {!missingAccessoriesTable && (
              <div>
                <div className="table-manipulation all-accessories-desktop">
                  <SearchBar
                    className="accessories-input search"
                    placeholder={strings.search}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button className="new-table-control-button" onClick={addAccessoryOpen}>
                    {strings.add}
                  </Button>
                </div>
                <div className="table-manipulation all-accessories-mobile">
                  <Input
                    className="accessories-input search"
                    style={{ marginRight: '20px' }}
                    placeholder={strings.search}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    onClick={addAccessoryOpen}
                    data-test-id="admin-accessories-add-button-mobile"
                    className="ikona pridatButton usersAddButton"
                    src="/images/Icons/plus_icon.png"
                    alt="ikona"
                  />
                </div>
                <Table
                  className="new-table accessories-table-desktop"
                  rowKey="_id"
                  dataSource={data}
                  columns={columns}
                  onRow={(record) => ({
                    onClick: () => openAccessoryDetail(record),
                  })}
                />
                <Table
                  className="table accessories-table-mobile"
                  rowKey="_id"
                  dataSource={data}
                  columns={columnsMobile}
                  onRow={(record) => ({
                    onClick: () => openAccessoryDetail(record),
                  })}
                  size="small"
                />
              </div>
            )}
          </div>
        </div>
        {addAccessory && (
          <Modal
            centered
            open
            width={600}
            title={strings.addAccessory}
            onCancel={addAccessoryClose}
            onOk={addAccessoryClose}
            footer={null}
          >
            <AccessoryDetail
              accessory={accessoryDetail}
              edit={edit}
              createAccessoryHandler={createAccessoryHandler}
              updateAccessoryHandler={updateAccessoryHandler}
              removeAccessoryHandler={removeAccessoryHandler}
            />
          </Modal>
        )}
        <Modal
          centered
          title={strings.confirmAccesoryDelivery}
          open={deliverAccessoryModal}
          onCancel={deliverAccessoryClose}
          onOk={deliverAccessoryClose}
          footer={null}
        >
          {deliverAccessoryModal && (
            <AccessoryStatusDetail
              name={deliverAccessory.name}
              request={false}
              closeModal={deliverAccessoryClose}
              save={confirmDelivery}
            />
          )}
        </Modal>
        <Modal
          centered
          title={strings.missingAccessory}
          open={missingAccesoryModal}
          onCancel={missingAccessoryClose}
          onOk={missingAccessoryClose}
          footer={null}
        >
          {missingAccessoryDetail && (
            <MissingAccessoryDetail accessory={missingAccessoryDetail} deliverAccessoryModal={deliverAccessoryOpen} />
          )}
        </Modal>
      </div>
    </div>
  );
}

export default Accessories;
