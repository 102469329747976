import { keyBy } from 'lodash';

export const GET_CLIENT_ACTIVITIES = 'GET_CLIENT_ACTIVITIES';
export const GET_OMAMA_CLIENTS = 'GET_OMAMA_CLIENTS';

const initialState = {
  activities: [],
  loading: false,
  clients: {},
  activitiesAll: [],
  activitiesType1: [],
  activitiesType2: [],
  activitiesType3: [],
  activitiesType4: [],
  activitiesType5: [],
  activitiesType6: [],
  checkedActivities: [],
  comment: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_ACTIVITIES:
      return {
        ...state,
        activitiesAll: action.payload.all,
        activitiesByID: keyBy(action.payload.all, '_id'),
        activitiesType1: action.payload.type1,
        activitiesType2: action.payload.type2,
        activitiesType3: action.payload.type3,
        activitiesType4: action.payload.type4,
        activitiesType5: action.payload.type5,
        activitiesType6: action.payload.type6,
      };
    case GET_OMAMA_CLIENTS:
      return {
        ...state,
        clients: keyBy(action.payload, '_id'),
        clientsArr: action.payload,
      };
    default:
      return state;
  }
};
