import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Modal, Input, Checkbox } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { strings } from 'strings/StringsProvider';
import { stringSorter } from 'tools/utils';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { providedByMap } from 'data/enums';
import AccessoryStatusDetail from 'components/AccessoryStatusDetail';
import { getRentedAccessories, changeOmamaAccessoryStatus } from 'actions/omama.actions';
import SearchBar from 'components/SearchBar';

import './RentedAccessories.scss';

function RentedAccessories({ userId }) {
  const [accessoryDetail, setAccessoryDetail] = useState(null);
  const [accessoryRequestModalOpen, setAccessoryRequestModalOpen] = useState(false);
  const [accessoryConfirmationModalOpen, setAccessoryConfirmationModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [accessoriesToShow, setAccessoriesToShow] = useState(null);
  const isOmama = jwtDecode(localStorage.getItem('access-token')).role === 'omama';
  const userName = jwtDecode(localStorage.getItem('access-token')).username;
  const omamaId = userId ? userId : userName;

  const dispatch = useDispatch();
  const changePage = (location) => dispatch(push(location));

  const allRentedAccessories = useSelector((state) => state.omama.rentedAccessories);

  useEffect(() => {
    dispatch(getRentedAccessories(omamaId));
  }, [dispatch, omamaId, userName]);

  useEffect(() => {
    setAccessoriesToShow(allRentedAccessories);
  }, [allRentedAccessories]);

  const columns = [
    {
      title: strings.accessory,
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: stringSorter('name'),
    },
    {
      title: strings.onePhoto,
      dataIndex: 'photoUrl',
      key: 'photoUrl',
      render: (photo) =>
        isEmpty(photo) ? (
          <div></div>
        ) : (
          <div>
            <img src={photo} alt="" className="rented-accessories-image" />
          </div>
        ),
    },
    {
      title: strings.providedBy,
      dataIndex: 'providedBy',
      key: 'providedBy',
      render: (providedBy) => providedByMap[providedBy],
    },
    {
      title: strings.modifiedDate,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        if (record.updatedAt) {
          return dayjs(record.updatedAt).format('D. M. YYYY');
        } else {
          return dayjs(record.createdAt).format('D. M. YYYY');
        }
      },
    },
    {
      title: strings.state,
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status === 'rented' ? strings.possess : <span style={{ color: 'red' }}>{strings.miss}</span>,
    },
    {
      title: strings.action,
      dataIndex: 'status',
      key: 'action',
      render: (status, accessory) => {
        return status === 'rented' ? (
          <Button className="rented-accessories-active-button" onClick={() => openRequestModal(accessory)}>
            <img src="/images/Icons/request_white.svg" alt="" height={20} className="icon" />
            {strings.accessoryRequestTitle}
          </Button>
        ) : (
          <Button className="rented-accessories-active-button" onClick={() => openConfirmationModal(accessory)}>
            <img src="/images/Icons/receive_white.svg" alt="" height={20} className="icon" />
            {strings.accessoryConfirmationTitle}
          </Button>
        );
      },
    },
    {
      title: strings.comment,
      dataIndex: 'note',
      key: 'note',
    },
  ];

  const columnsMobile = [
    {
      title: strings.accessory,
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: stringSorter('name'),
      width: '35%',
    },
    {
      title: strings.providedBy,
      dataIndex: 'providedBy',
      key: 'providedBy',
      width: '35%',
      render: (providedBy) => providedByMap[providedBy],
    },
    {
      title: strings.state,
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      render: (status) => {
        if (status === 'rented') return <div style={{ color: '#41aea6' }}>{strings.possess}</div>;
        else return <div style={{ color: 'red' }}>{strings.miss}</div>;
      },
    },
    {
      title: '',
      width: '5%',
      render: () => <RightOutlined className="accessories-table-mobile-icon" />,
    },
  ];

  const openRequestModal = (accessory) => {
    if (accessory.status === 'rented') {
      setAccessoryRequestModalOpen(true);
      setAccessoryDetail(accessory);
    }
  };

  const openConfirmationModal = (accessory) => {
    if (accessory.status === 'missing') {
      setAccessoryConfirmationModalOpen(true);
      setAccessoryDetail(accessory);
    }
  };

  const closeRequestModal = () => {
    setAccessoryRequestModalOpen(false);
    setAccessoryDetail(null);
  };

  const closeConfirmationModal = () => {
    setAccessoryConfirmationModalOpen(false);
    setAccessoryDetail(null);
  };

  const sendRequest = (body) => {
    dispatch(changeOmamaAccessoryStatus(accessoryDetail.accessoryId, omamaId, body)).then(() =>
      dispatch(getRentedAccessories(omamaId)),
    );
    setAccessoryRequestModalOpen(false);
    setAccessoryDetail(null);
  };

  const sendConfirmation = (body) => {
    dispatch(changeOmamaAccessoryStatus(accessoryDetail.accessoryId, omamaId, body)).then(() =>
      dispatch(getRentedAccessories(omamaId)),
    );
    setAccessoryConfirmationModalOpen(false);
    setAccessoryDetail(null);
  };

  const filterAccessoriesBySearch = useCallback((accessoriesToFilter, filter) => {
    return accessoriesToFilter.filter((accessory) => {
      const query = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase();

      return accessory.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .includes(query);
    });
  }, []);

  const filterMissingAccessories = () => {
    const missingAccessories =
      allRentedAccessories && allRentedAccessories.filter((rentedAccessory) => rentedAccessory.status === 'missing');
    setAccessoriesToShow(missingAccessories);
  };

  const handleRentedAccessoriesCheckbox = () => {
    if (!showAll) {
      filterMissingAccessories();
      setShowAll(true);
    } else {
      setAccessoriesToShow(allRentedAccessories);
      setShowAll(false);
    }
  };

  const rentedAccessoriesData = !isEmpty(accessoriesToShow)
    ? filterAccessoriesBySearch(accessoriesToShow, search)
    : null;

  return (
    <div className="rented-accessories-container">
      <div className="table-manipulation-desktop">
        <div className="rented-accessories-search-container" data-testid="rentedAccessories-search">
          <SearchBar placeholder={strings.search} value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <Checkbox
          checked={showAll}
          onChange={handleRentedAccessoriesCheckbox}
          className="new-table-control-checkbox"
          data-testid="rentedAccessories-missingCheckbox"
        >
          {strings.showOnlyMissingAccessories}
        </Checkbox>
      </div>

      <div className="table-manipulation-mobile">
        <div className="rented-accessories-input-container">
          <Input
            className="accessories-input search"
            placeholder={strings.search}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Checkbox checked={showAll} onChange={handleRentedAccessoriesCheckbox} className="accessories-checkbox">
          {strings.showOnlyMissingAccessories}
        </Checkbox>
      </div>

      <Table
        className="new-table rented-accessories-table-desktop"
        rowKey="_id"
        dataSource={rentedAccessoriesData}
        columns={columns}
        data-testid="rentedAccessories-table-desktop"
      />
      <Table
        className="table accessories-table-mobile"
        rowKey="_id"
        dataSource={rentedAccessoriesData}
        columns={columnsMobile}
        onRow={(record) => ({
          onClick: () => {
            if (isOmama) changePage(`/omama/pomocky/${record.accessoryId}`);
            else changePage(`/admin/user/${omamaId}/pomocky/${record.accessoryId}`);
          },
        })}
        size="small"
        data-testid="rentedAccessories-table-mobile"
      />
      <Modal
        centered
        title={strings.accessoryRequestTitle}
        open={accessoryRequestModalOpen}
        onCancel={closeRequestModal}
        onOk={closeRequestModal}
        footer={null}
        data-testid="rentedAccessories-RequestModal"
      >
        {accessoryRequestModalOpen && (
          <AccessoryStatusDetail
            name={accessoryDetail.name}
            request={true}
            closeModal={closeRequestModal}
            save={sendRequest}
            data-testid="rentedAccessories-RequestDetail"
          />
        )}
      </Modal>
      <Modal
        centered
        title={strings.accessoryConfirmationTitle}
        open={accessoryConfirmationModalOpen}
        onCancel={closeConfirmationModal}
        onOk={closeConfirmationModal}
        footer={null}
        data-testid="rentedAccessories-ConfirmationModal"
      >
        {accessoryConfirmationModalOpen && (
          <AccessoryStatusDetail
            name={accessoryDetail.name}
            request={false}
            closeModal={closeConfirmationModal}
            save={sendConfirmation}
            data-testid="rentedAccessories-ConfirmationDetail"
          />
        )}
      </Modal>
    </div>
  );
}

RentedAccessories.propTypes = {
  userId: PropTypes.string,
};

export default RentedAccessories;
