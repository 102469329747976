import dayjs from 'dayjs';

/* eslint-disable no-unused-vars */
export const GET_SUPERVISOR_CLIENTS = 'GET_SUPERVISOR_CLIENTS';
export const ADMIN_SUPERVISOR_CHANGE_NEXT_MONTH = ' ADMIN_SUPERVISOR_CHANGE_NEXT_MONTH';
export const ADMIN_SUPERVISOR_CHANGE_PREVIOUS_MONTH = ' ADMIN_SUPERVISOR_CHANGE_PREVIOUS_MONTH';
export const GET_ADMIN_SUPERVISOR_ACTIONS = 'GET_ADMIN_SUPERVISOR_ACTIONS';
export const ADMIN_SUPERVISOR_CHANGE_DATE = 'ADMIN_SUPERVISOR_CHANGE_DATE';
export const GET_SPECIFIC_SUPERVISOR_ACTION = 'GET_SPECIFIC_SUPERVISOR_ACTION';
export const SET_CHECKLISTS = 'SET_CHECKLISTS';

const initialState = {
  supervisor: [],
  supervisorStartMonth: dayjs().startOf('month').format('YYYY-MM-DD'),
  supervisorEndMonth: dayjs().endOf('month').format('YYYY-MM-DD'),
  supervisorFrom: null,
  supervisorTo: null,
  supervisorActions: [],
  supervisorAction: {},
  checklistsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_SUPERVISOR_CHANGE_NEXT_MONTH:
      return {
        ...state,
        supervisorStartMonth: action.payload.start,
        supervisorEndMonth: action.payload.end,
      };

    case ADMIN_SUPERVISOR_CHANGE_PREVIOUS_MONTH:
      return {
        ...state,
        supervisorStartMonth: action.payload.start,
        supervisorEndMonth: action.payload.end,
      };
    case ADMIN_SUPERVISOR_CHANGE_DATE:
      return {
        ...state,
        supervisorFrom: action.payload.supervisorFrom,
        supervisorTo: action.payload.supervisorTo,
      };

    case GET_ADMIN_SUPERVISOR_ACTIONS:
      return {
        ...state,
        supervisorActions: action.payload.supervisorActions,
      };

    case GET_SPECIFIC_SUPERVISOR_ACTION:
      return {
        ...state,
        supervisorAction: action.payload,
      };

    case SET_CHECKLISTS:
      return {
        ...state,
        checklistsList: action.payload,
      };

    default:
      return state;
  }
};
