import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import axios from 'axios';
import classNames from 'classnames';
import { api } from '../../conf/api';
import { Button, Input, Alert } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { strings } from '../../strings/StringsProvider';
import './index.scss';
import PropTypes from 'prop-types';

function ChangePassword({ location }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordConfirmChanged, setPasswordConfirmChanged] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [formErrors, setFormErrors] = useState('');

  const backPage = location.pathname.substr(0, location.pathname.lastIndexOf('/'));

  const dispatch = useDispatch();
  const changePage = (location) => dispatch(push(location));

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      changePassword();
    }
  };

  const changePassword = async () => {
    if (isValid) {
      try {
        const { status } = await axios.post(api.changePassword, {
          oldPassword: oldPassword,
          password: newPassword,
        });
        if (status === 200) {
          changePage(backPage);
        } else {
          setAlertType('error');
          setAlertMessage(strings.passwordChangeError);
        }
      } catch (error) {
        if (error.message.includes('Request failed with status code 40')) {
          setAlertType('error');
          setAlertMessage(strings.oldPasswordError);
        } else {
          setAlertType('error');
          setAlertMessage(strings.errorWhileSending);
        }
      }
    }
  };

  const onChangeOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const onChangeNewPassword = (e) => {
    const newTypedPassword = e.target.value;
    setNewPassword(newTypedPassword);
    validatePasswords(newTypedPassword, newPasswordConfirm);
  };

  const onChangeNewPasswordConfirm = (e) => {
    const newTypedPasswordConfirm = e.target.value;
    setNewPasswordConfirm(newTypedPasswordConfirm);
    validatePasswords(newPassword, newTypedPasswordConfirm);
    setPasswordConfirmChanged(true);
  };

  const validatePasswords = (password, confirm) => {
    const newPasswordValid = password.match(/^(?=.*\d)(?=.*[A-Z])(?=.{8,})[0-9a-zA-Z!@#$%^&*)(+=._-]{8,}$/);
    let formErrorMessage = newPasswordValid ? '' : strings.passwordIsIncorrect;

    if (password !== confirm && passwordConfirmChanged) {
      formErrorMessage = strings.passwordsAreNotSame;
    }

    if (password.length === 0 || confirm.length === 0) {
      formErrorMessage = '';
    }

    const isChangeValid = newPasswordValid && password === confirm ? true : false;

    setFormErrors(formErrorMessage);
    setIsValid(isChangeValid);
  };

  const path = '/' + location.pathname.split('/', 2)[1];

  return (
    <div
      className={classNames('settings-container', {
        admin: path === '/admin',
      })}
    >
      <div
        className="settings-nav"
        tabIndex={0}
        onClick={() => changePage(backPage)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') changePage(backPage);
        }}
      >
        <LeftOutlined />
        <h3>{strings.passwordChange}</h3>
      </div>
      <div className="settings-main">
        <form>
          <label htmlFor="oldPassword">{strings.oldPassword}</label>
          <Input
            className="settings-input"
            placeholder={strings.oldPassword}
            onChange={onChangeOldPassword}
            onKeyPress={onKeyPress}
            value={oldPassword}
            type="password"
            name="oldPassword"
            id="oldPassword"
            autoComplete="current-password"
          />
          <label htmlFor="oldPassword">{strings.newPassword}</label>
          <Input
            className="settings-input"
            placeholder={strings.newPassword}
            onChange={onChangeNewPassword}
            onKeyPress={onKeyPress}
            value={newPassword}
            type="password"
            name="newPassword"
            id="newPassword"
            autoComplete="new-password"
          />
          <label htmlFor="oldPassword">{strings.confirmNewPassword}</label>
          <Input
            className="settings-input"
            placeholder={strings.confirmNewPassword}
            onChange={onChangeNewPasswordConfirm}
            onKeyPress={onKeyPress}
            value={newPasswordConfirm}
            type="password"
            name="newPasswordConfirm"
            id="newPasswordConfirm"
            autoComplete="new-password"
          />
          <p className="settings-showErrors">{formErrors}</p>
          <Button className="settings-button" type="primary" shape="round" onClick={changePassword} disabled={!isValid}>
            {strings.change}
          </Button>
        </form>
        {alertType && (
          <Alert type={alertType} message={alertMessage} style={{ marginTop: '10px', marginBottom: '10px' }} />
        )}
      </div>
    </div>
  );
}

ChangePassword.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any,
  }),
};

export default ChangePassword;
