import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getRentedAccessoryHistory } from 'actions/admin.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { strings } from 'strings/StringsProvider';
import dayjs from 'dayjs';
import { accessoryStatusMap } from 'data/enums';

import './RentedAccessoryHistory.scss';

const columns = [
  {
    title: strings.date,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => dayjs(date).format('D. M. YYYY'),
  },
  {
    title: strings.status,
    dataIndex: 'status',
    key: 'status',
    render: (status) => accessoryStatusMap[status],
  },
  {
    title: strings.comment,
    dataIndex: 'note',
    key: 'note',
  },
];

function RentedAccessoryHistory({ accessory, omama }) {
  const rentedAccessoryHistory = useSelector((state) => state.admin.rentedAccessoryHistory);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRentedAccessoryHistory(accessory, omama));
  }, [dispatch, accessory, omama]);

  return (
    <Table
      columns={columns}
      dataSource={rentedAccessoryHistory}
      className="rented-accessory-history-table"
      pagination={false}
      rowKey={(record) => record.createdAt}
    />
  );
}

RentedAccessoryHistory.propTypes = {
  accessory: PropTypes.string,
  omama: PropTypes.string,
};

export default RentedAccessoryHistory;
