import {
  GET_STATISTICS,
  GET_STATISTICS_CLIENTS,
  GET_STATISTICS_OMAMAS,
  GET_STATISTICS_OTHER,
  GET_TRENDS,
} from '../reducers/statistics.reducer';
import { keyBy, groupBy, cloneDeep } from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';
import { strings } from '../strings/StringsProvider';

export const getStatistics = (year) => {
  return async (dispatch) => {
    const { data } = await axios.get(api.getStatistics(year));
    const inactiveClients = data.allClients.length - data.activeClients.length;
    const inactiveOmamas = data.allOmamas.length - data.activeOmamas.length;
    const inactiveMentors = data.allMentors.length - data.activeMentors.length;
    const inactiveSupervisors = data.allSupervisors.length - data.activeSupervisors.length;

    const inactiveOmamaList = data.allOmamas.filter(
      (user) => !data.activeOmamas.some((activeUser) => activeUser._id === user._id),
    );

    const inactiveMentorList = data.allMentors.filter(
      (user) => !data.activeMentors.some((activeUser) => activeUser._id === user._id),
    );

    const inactiveSupervisorList = data.allSupervisors.filter(
      (user) => !data.activeSupervisors.some((activeUser) => activeUser._id === user._id),
    );

    const inactiveClientList = data.allClients.filter(
      (client) => !data.activeClients.some((activeClient) => activeClient._id === client._id),
    );

    const countDoneLections = data.countDoneLections;
    const countDoneClubs = data.countDoneClubs;
    const countParentalClub = data.countParentalClub;

    return dispatch({
      type: GET_STATISTICS,
      payload: {
        allClients: data.allClients.length,
        activeClients: data.activeClients.length,
        inactiveClients: inactiveClients,
        allOmamas: data.allOmamas.length,
        activeOmamas: data.activeOmamas.length,
        inactiveOmamas: inactiveOmamas,
        allMentors: data.allMentors.length,
        activeMentors: data.activeMentors.length,
        inactiveMentors: inactiveMentors,
        allSupervisors: data.allSupervisors.length,
        activeSupervisors: data.activeSupervisors.length,
        inactiveSupervisors: inactiveSupervisors,
        clientList: {
          active: data.activeClients,
          inactive: inactiveClientList,
          total: data.allClients,
        },
        omamaList: {
          active: data.activeOmamas,
          inactive: inactiveOmamaList,
          total: data.allOmamas,
        },
        mentorList: {
          active: data.activeMentors,
          inactive: inactiveMentorList,
          total: data.allMentors,
        },
        supervisorList: {
          active: data.activeSupervisors,
          inactive: inactiveSupervisorList,
          total: data.allSupervisors,
        },
        countDoneLections,
        countDoneClubs,
        countParentalClub,
      },
    });
  };
};

export const getStatisticsClients = (year) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const { data } = await axios.get(api.getStatisticsClients(year));
    const tempClientsByBirthDate = {
      [`0 - 5 ${strings.zeroOrFiveMonths}`]: 0,
      [`6 - 11 ${strings.zeroOrFiveMonths}`]: 0,
      [`12 - 17 ${strings.zeroOrFiveMonths}`]: 0,
      [`18 - 23 ${strings.zeroOrFiveMonths}`]: 0,
      [`24 - 29 ${strings.zeroOrFiveMonths}`]: 0,
      [`30 - 35 ${strings.zeroOrFiveMonths}`]: 0,
      [`36 - 41 ${strings.zeroOrFiveMonths}`]: 0,
      [`42 - 47 ${strings.zeroOrFiveMonths}`]: 0,
      [`48+ ${strings.zeroOrFiveMonths}`]: 0,
    };

    const clientsByBirthDate = [];
    const clientsByCity = [];

    Object.keys(data.data.clientsByBirthDate).forEach((item) => {
      let range = item;
      switch (range) {
        /* eslint-disable */
        case '5month':
          range = `0 - 5 ${strings.zeroOrFiveMonths}`;
          break;
        case '11month':
          range = `6 - 11 ${strings.zeroOrFiveMonths}`;
          break;
        case '17month':
          range = `12 - 17 ${strings.zeroOrFiveMonths}`;
          break;
        case '23month':
          range = `18 - 23 ${strings.zeroOrFiveMonths}`;
          break;
        case '29month':
          range = `24 - 29 ${strings.zeroOrFiveMonths}`;
          break;
        case '35month':
          range = `30 - 35 ${strings.zeroOrFiveMonths}`;
          break;
        case '41month':
          range = `36 - 41 ${strings.zeroOrFiveMonths}`;
          break;
        case '47month':
          range = `42 - 47 ${strings.zeroOrFiveMonths}`;
          break;
        case '48plus':
          range = `48+ ${strings.zeroOrFiveMonths}`;
          break;
        default:
          range = 'UNclassified';
        /* eslint-enable */
        // DOROBIT DEFAULT
      }

      tempClientsByBirthDate[range] = data.data.clientsByBirthDate[item];
    });
    Object.keys(tempClientsByBirthDate).forEach((item) => {
      clientsByBirthDate.push({
        range: item,
        count: tempClientsByBirthDate[item].count || 0,
        clientList: tempClientsByBirthDate[item].clientList || [],
      });
    });

    Object.keys(data.data.clientsByCity).forEach((item) =>
      clientsByCity.push({
        city: item,
        countTotal: data.data.clientsByCity[item].count,
        countActive: data.data.clientsByCity[item].countActive,
        countNonActive: data.data.clientsByCity[item].countNonActive,
        clients: data.data.clientsByCity[item].clients,
      }),
    );

    await dispatch({
      type: GET_STATISTICS_CLIENTS,
      payload: {
        clientsByBirthDate: clientsByBirthDate,
        clientsByCity: clientsByCity,
      },
    });
    dispatch(setLoading(false));
  };
};

export const getStatisticsOmamas = (year, allTimeStats) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const start = dayjs(year).startOf('year').format('YYYY-MM-DD');
    const end = dayjs(year).endOf('year').add(1, 'days').format('YYYY-MM-DD');
    const thisYear = await axios.get(api.getOmamasStatsRange(start, end));
    await dispatch({
      type: GET_STATISTICS_OMAMAS,
      payload: {
        data: {
          thisYear: thisYear.data.data,
        },
      },
    });

    if (!allTimeStats) {
      const allTime = await axios.get(api.getOmamasStatsAll);
      await dispatch({
        type: GET_STATISTICS_OMAMAS,
        payload: {
          data: {
            allTime: allTime.data.data,
          },
        },
      });
    }

    dispatch(setLoading(false));
  };
};

export const getStatisticsOther = (year) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const start = dayjs(year).startOf('year').format('YYYY-MM-DD');
    const end = dayjs(year).endOf('year').add(1, 'days').format('YYYY-MM-DD');

    try {
      const { data: allActions } = await axios.get(api.getStatisticsOther(start, end));
      const { data: users } = await axios.get(api.getOmamas);
      const allOmamas = users.users;
      const { data: activities } = await axios.get(api.getAdminActivities);

      const { data: clients } = await axios.get(api.getClients());

      const { data: latestScreenings } = await axios.get(api.getAllLatestScreenings(start, end));

      // testy s otazkami
      const { data: allScreeningsTests } = await axios.get(api.getAllScreeningTests);
      const screeningTestsByID = keyBy(allScreeningsTests.data, '_id');

      // skriningy podla typu
      const screeningsByType = groupBy(latestScreenings.data, '_id.type');

      const screeningsStatsTemplate = {
        '2.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '3.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '4.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '5.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '6.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '7.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '8.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '9.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '10.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '11.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
        '12.PP': {
          green: 0,
          greenClients: [],
          orange: 0,
          orangeClients: [],
          red: 0,
          redClients: [],
          worries: 0,
          worriesClients: [],
          totalClients: 0,
        },
      };

      /* MOKCUP TEMPLATE pre "Vývinový skríning za aktívnych klientov v tomto roku" objekt kde ratame odpovede */

      let tempScreeningAnswers = {};

      // pocet zaznamenanych odpovedy per screeningType
      const filledScreeningsByType = {
        '2.PP': 0,
        '3.PP': 0,
        '4.PP': 0,
        '5.PP': 0,
        '6.PP': 0,
        '7.PP': 0,
        '8.PP': 0,
        '9.PP': 0,
        '10.PP': 0,
        '11.PP': 0,
        '12.PP': 0,
      };

      for (const test of allScreeningsTests.data) {
        tempScreeningAnswers[test._id] = {};
        for (const question of test.questions) {
          tempScreeningAnswers[test._id][question.id] = 0;
        }
      }

      let screeningsStatsTemplates = {};
      for (const omama of allOmamas) {
        screeningsStatsTemplates[omama._id] = cloneDeep(screeningsStatsTemplate);
      }
      screeningsStatsTemplates.all = cloneDeep(screeningsStatsTemplate);

      // Vývinový skríning za aktívnych klientov v tomto roku a Vývinový skríning – skóre
      for (const key in screeningsByType) {
        for (const scr of screeningsByType[key]) {
          filledScreeningsByType[key] += 1;
          for (const answer of scr.answers) {
            if (answer.answer === true) {
              tempScreeningAnswers[scr.type][answer.id] += 1;
            }
          }

          try {
            let createdBy = scr.createdBy;
            // If user from createdBy was not found, it means
            // the screening was filled by mentor.
            // In that case we find omama by mentor.
            if (!screeningsStatsTemplates[createdBy]) {
              const omama = allOmamas.find((omama) => omama.mentorID === createdBy);
              if (omama) {
                createdBy = omama._id;
              }
            }

            if (scr.pasma.vyvinovehoSkore.pasmo === 'green') {
              screeningsStatsTemplates[createdBy][key].green += 1;
              screeningsStatsTemplates[createdBy][key].greenClients.push(scr.clientID);
              screeningsStatsTemplates.all[key].green += 1;
              screeningsStatsTemplates.all[key].greenClients.push(scr.clientID);
            } else if (scr.pasma.vyvinovehoSkore.pasmo === 'orange') {
              screeningsStatsTemplates[createdBy][key].orange += 1;
              screeningsStatsTemplates[createdBy][key].orangeClients.push(scr.clientID);
              screeningsStatsTemplates.all[key].orange += 1;
              screeningsStatsTemplates.all[key].orangeClients.push(scr.clientID);
            } else {
              screeningsStatsTemplates[createdBy][key].red += 1;
              screeningsStatsTemplates[createdBy][key].redClients.push(scr.clientID);
              screeningsStatsTemplates.all[key].red += 1;
              screeningsStatsTemplates.all[key].redClients.push(scr.clientID);
            }

            if (scr.pasma.obavy.pasmo === 'red') {
              screeningsStatsTemplates[createdBy][key].worries += 1;
              screeningsStatsTemplates[createdBy][key].worriesClients.push(scr.clientID);
              screeningsStatsTemplates.all[key].worries += 1;
              screeningsStatsTemplates.all[key].worriesClients.push(scr.clientID);
            }

            screeningsStatsTemplates[createdBy][key].totalClients += 1;
            // KONIEC loopovania vsetkych skriningov jedneho typu
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
          }
        }

        screeningsStatsTemplates.all[key].totalClients = screeningsByType[key].length;
      }

      const actions = allActions.data.actions;
      const totalLessons = allActions.data.totalLessons;
      const totalClubs = allActions.data.totalClubs;
      const totalParentClubs = allActions.data.totalParentClubs;

      let doneLessons = 0;
      let doneClubs = 0;

      const tempActionsByOmamas = Object.assign(...allOmamas.map((omama) => ({ [omama._id]: 0 })));
      let tempParentalCLubsByOmamas = Object.assign(...allOmamas.map((omama) => ({ [omama._id]: 0 })));
      let tempActivities = Object.assign(...activities.activities.map((activity) => ({ [activity._id]: 0 })));
      const activitiesByID = keyBy(activities.activities, '_id');
      const omamasByID = keyBy(allOmamas, '_id');

      let tempActionAnswersByOmamas = Object.assign(
        ...allOmamas.map((omama) => ({
          [omama._id]: {
            question1: {
              notAtAll: 0,
              sometimes: 0,
              often: 0,
              count: 0,
            },
            question2: {
              notAtAll: 0,
              sometimes: 0,
              often: 0,
              count: 0,
            },
            question3: {
              notAtAll: 0,
              sometimes: 0,
              often: 0,
              count: 0,
            },
          },
        })),
      );

      let tempActionAnswersTotal = {
        question1: {
          notAtAll: 0,
          sometimes: 0,
          often: 0,
          count: 0,
        },
        question2: {
          notAtAll: 0,
          sometimes: 0,
          often: 0,
          count: 0,
        },
        question3: {
          notAtAll: 0,
          sometimes: 0,
          often: 0,
          count: 0,
        },
      };

      let preSchoolClubCount = 0;
      for (const client of clients.data) {
        if (client.predskolskyKlub === true) {
          preSchoolClubCount += 1;
        }
      }

      // Special handling for preschool clubs
      // which are stored as duplicates per each club client
      const countedPreschoolClubs = {};

      for (const action of actions) {
        // Ignore actions that have omamaID of unexisting user
        // which would cause this for loop to crash
        if (!omamasByID[action.omamaID]) {
          continue;
        }

        // Unique id for clubs
        const uid = action.clubNames + action.date;

        // Počet zrealizovaných lekcií v tomto roku PRE KAZDU OMAMU
        if (action.client === true && action.status === 'done') {
          const omama = action.omamaID;
          if (action.differentAction === 'club' && !countedPreschoolClubs[uid]) {
            doneClubs += 1;
            countedPreschoolClubs[uid] = true;
          } else if (action.differentAction !== 'club') {
            tempActionsByOmamas[omama] += 1;
            doneLessons += 1;
          }
        }
        // Počet zrealizovaných rodičovských klubov v tomto roku podla OMAMA
        if (action.id === strings.parentalClub1) {
          const omama = action.omamaID;
          tempParentalCLubsByOmamas[omama] += 1;
        }

        for (const activity of action.checkedActivities) {
          tempActivities[activity] += 1;
        }

        if (action.client === true && action.status === 'done' && action.differentAction !== 'club') {
          tempActionAnswersByOmamas[action.omamaID].question1[action.evaluation.question1] += 1;
          tempActionAnswersByOmamas[action.omamaID].question1.count += 1;
          tempActionAnswersTotal.question1[action.evaluation.question1] += 1;
          tempActionAnswersTotal.question1.count += 1;
          tempActionAnswersByOmamas[action.omamaID].question2[action.evaluation.question2] += 1;
          tempActionAnswersByOmamas[action.omamaID].question2.count += 1;
          tempActionAnswersTotal.question2[action.evaluation.question2] += 1;
          tempActionAnswersTotal.question2.count += 1;
          tempActionAnswersByOmamas[action.omamaID].question3[action.evaluation.question3] += 1;
          tempActionAnswersByOmamas[action.omamaID].question3.count += 1;
          tempActionAnswersTotal.question3[action.evaluation.question3] += 1;
          tempActionAnswersTotal.question3.count += 1;
        }
      }

      let actionAnswers = Object.keys(tempActionAnswersByOmamas).map((omama) => ({
        ...tempActionAnswersByOmamas[omama],
        omamaID: omama,
      }));
      actionAnswers.push(tempActionAnswersTotal);

      let tempActivitiesToConvert = cloneDeep(tempActivities);
      let tempActivitiesArray = [];
      // transform object to array
      tempActivitiesArray = Object.entries(tempActivitiesToConvert).map(([k, v]) => ({ [k]: v }));
      // order array
      tempActivitiesArray = tempActivitiesArray.sort((a, b) => {
        let A = a[Object.keys(a)[0]];
        let B = b[Object.keys(b)[0]];
        return B - A;
      });

      dispatch({
        type: GET_STATISTICS_OTHER,
        payload: {
          doneLessons,
          doneClubs,
          totalLessons,
          totalClubs,
          totalParentClubs,
          preSchoolClubCount,
          tempActionsByOmamas,
          tempParentalCLubsByOmamas,
          tempActivities: tempActivitiesArray,
          allOmamas,
          activitiesByID,
          omamasByID,
          screeningTestsByID,
          filledScreeningsByType,
          actionAnswers: actionAnswers,
          screeningAnswers: tempScreeningAnswers,
          screeningStatsTableData: screeningsStatsTemplates,
        },
      });
      dispatch(setLoading(false));
    } catch (error) {
      // console.log(error);
      dispatch(setLoading(false));
    }
  };
};

export const getTrends = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const { data } = await axios.get(api.getTrends);
    let counts = data.data.counts;
    let screeningsByYear = data.data.screeningsByYear;
    let screeningsByLessonsCount = data.data.screeningsByLessonsCount;
    let parentEngagementByYear = data.data.parentEngagementByYear;
    let parentEngagementByLessonsCount = data.data.parentEngagementByLessonsCount;

    try {
      dispatch({
        type: GET_TRENDS,
        payload: {
          counts,
          screeningsByYear,
          screeningsByLessonsCount,
          parentEngagementByYear,
          parentEngagementByLessonsCount,
        },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};
