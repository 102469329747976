import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ProgressBar.scss';

function ProgressBar({ text, current, total, fillClass, pointsWidth, showPercentage }) {
  const percentage = total > 0 ? Math.round((current / total) * 100) : 0;
  return (
    <div className="progress-bar">
      <p className="progress-bar-name">{text}</p>
      <div className="progress-bar-chart">
        <div className="progress-bar-chart-bar" style={showPercentage && { height: '20px' }}>
          <div className={'progress-bar-chart-bar-fill' + (fillClass || '')} style={{ width: percentage + '%' }}>
            {showPercentage && (
              <span className={classNames('progress-bar-chart-bar-fill-percentage', { shifted: percentage < 15 })}>
                {percentage}%
              </span>
            )}
          </div>
        </div>
        <span className="progress-bar-chart-points" style={pointsWidth ? { width: pointsWidth + 'px' } : {}}>
          {`${current || 0}/${total || 0}`}
        </span>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  text: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  fillClass: PropTypes.string,
  pointsWidth: PropTypes.number,
  showPercentage: PropTypes.bool,
};

export default ProgressBar;
