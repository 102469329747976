import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import status from './status.reducer';
import counter from './counter.reducer';
import users from './users.reducer';
import omama from './omama.reducer';
import menu from './menu.reducer';
import clientActivities from './clientActivities.reducer';
import admin from './admin.reducer';
import mentor from './mentor.reducer';
import message from './message.reducer';
import statistics from './statistics.reducer';
import report from './report.reducer';
import clientForm from './clientForm.reducer';
import activityForm from './activityForm.reducer';
import deactivateClient from './deactivateClient.reducer';
import supervisor from './supervisor.reducer';
import supervisionForm from './supervisionForm.reducer';
import measurements from './measurements.reducer';
import notifications from 'slices/notifications';
import screenings from 'slices/screenings';
import clients from 'slices/clients';

export const USER_LOGOUT = 'USER_LOGOUT';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    statistics,
    status,
    counter,
    users,
    omama,
    menu,
    clientActivities,
    admin,
    mentor,
    message,
    report,
    clientForm,
    activityForm,
    deactivateClient,
    supervisor,
    supervisionForm,
    measurements,
    notifications,
    screenings,
    clients,
  });

export const createRootReducer = (history) => (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    const { router } = state;
    state = { router };
  }
  return rootReducer(history)(state, action);
};
