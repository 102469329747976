import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Base64Image from './Base64Image';
import { strings } from '../strings/StringsProvider';

import './ClientProfileCard.scss';

function ClientProfileCard({ doneLections, nextLection, client, toggleChangePhoto }) {
  return (
    <div>
      <div style={{ paddingTop: '60px' }}>
        <div className="clientPersonCard">
          <div className="section_one">
            <div className="clientCardImage">
              <Base64Image
                key={client._id}
                type="user"
                backUpImage="/images/cestavonLOGO_unofficial.png"
                imageID={client._id}
              />
              <p
                style={{
                  marginBottom: 0,
                  color: '#41aea6',
                  fontSize: '0.8em',
                  cursor: 'pointer',
                }}
                onClick={toggleChangePhoto}
              >
                {strings.changePicture}
              </p>
            </div>
          </div>
          <div className="section_two">
            <div className="top-block">
              <div className="user-name">{client.name}</div>
              <p>
                {dayjs(client.birthDate).format('D. M. YYYY')} ({client.age})
              </p>
            </div>
            <div className="bottom-block">
              <div className="lessons" style={{ textAlign: 'center' }}>
                <div>
                  <span className="lection_count">{doneLections}</span>
                </div>
                <div>
                  <span className="label">{strings.completedLessons}</span>
                </div>
              </div>
              <div className="joined" style={{ textAlign: 'center' }}>
                <div>
                  <span className="lection_count">{dayjs(client.inProgramFromDate).format('D. M. YYYY')}</span>
                </div>
                <div>
                  <span className="label">{strings.inProgramFrom}</span>
                </div>
              </div>
              <div className="next-lesson" style={{ textAlign: 'center' }}>
                <div>
                  <span className="lection_count">{nextLection ? dayjs(nextLection).format('D. M.') : '—'}</span>
                </div>
                <div>
                  <span className="label">{strings.nextLesson}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clientArticle">
        <div>{`${strings.address}: ${client.street} `}</div>
        <div>
          {`${strings.mother}: ${client.motherName}, ${strings.telephone} ${' '}
          ${client.motherPhone}`}
        </div>
        <div>
          {`${strings.father}: ${client.fatherName}, ${strings.telephone} ${' '}
          ${client.fatherPhone}`}
        </div>
        <div>{`${strings.numberOfSiblings}: ${client.siblingsNumber}`}</div>
      </div>
    </div>
  );
}

ClientProfileCard.propTypes = {
  doneLections: PropTypes.number,
  nextLection: PropTypes.string,
  client: PropTypes.object,
  toScreening: PropTypes.func,
  toggleChangePhoto: PropTypes.func,
};

export default ClientProfileCard;
