import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { formatChildWeek } from 'tools/date.tools';

// Local imports
import { strings } from '../strings/StringsProvider';
import './ClientProfileAction.scss';
import { getActivitiesAction } from '../actions/clientActivities.action';

const actionQuestions = [strings.questionParentJoined, strings.questionParentPraised, strings.questionParentDecicated];

const groupNames = {
  1: strings.conginitiveFunctions,
  2: strings.fineMotorSkills,
  3: strings.grossMotorSkills,
  4: strings.emotionalDevelopment,
  5: strings.readingBook,
  6: strings.homeworksForParents,
};

function ClientProfileAction({ client, action, goBack }) {
  //const activitiesByID = useSelector(state => state.clientActivities.activitiesByID);
  const activitiesAll = useSelector((state) => state.clientActivities.activitiesAll);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(activitiesAll)) {
      dispatch(getActivitiesAction());
    }
  }, [activitiesAll, dispatch]);

  const renderActivityList = useCallback(
    (activityGroup, checkedActivities) => {
      let activitiesToRender = [];
      switch (activityGroup) {
        case 1:
          const activitiesType1 = activitiesAll.filter((activity) => activity.type === 1);
          activitiesToRender = activitiesType1.filter((item) => {
            return checkedActivities.includes(item._id);
          });
          break;
        case 2:
          const activitiesType2 = activitiesAll.filter((activity) => activity.type === 2);
          activitiesToRender = activitiesType2.filter((item) => {
            return checkedActivities.includes(item._id);
          });
          break;
        case 3:
          const activitiesType3 = activitiesAll.filter((activity) => activity.type === 3);
          activitiesToRender = activitiesType3.filter((item) => {
            return checkedActivities.includes(item._id);
          });
          break;
        case 4:
          const activitiesType4 = activitiesAll.filter((activity) => activity.type === 4);
          activitiesToRender = activitiesType4.filter((item) => {
            return checkedActivities.includes(item._id);
          });
          break;
        case 5:
          const activitiesType5 = activitiesAll.filter((activity) => activity.type === 5);
          activitiesToRender = activitiesType5.filter((item) => {
            return checkedActivities.includes(item._id);
          });
          break;
        case 6:
          const activitiesType6 = activitiesAll.filter((activity) => activity.type === 6);
          activitiesToRender = activitiesType6.filter((item) => {
            return checkedActivities.includes(item._id);
          });
          break;
        default:
          break;
      }

      if (isEmpty(activitiesToRender)) {
        return null;
      } else {
        return (
          <div>
            <div style={{ paddingLeft: '10px', color: '#41aea6' }}>
              {activityGroup}. {groupNames[activityGroup]}
            </div>
            <div className="groupList">
              {activitiesToRender.map((act, i) => (
                <span key={i} style={{ margin: '0 4px', textAlign: 'center' }}>
                  <span className="monthWeek">{`${act.code} - ${act.mesiac}m/${act.tyzden}t - `}</span> {act.name}
                  {i < activitiesToRender.length - 1 && ','}
                </span>
              ))}
            </div>
          </div>
        );
      }
    },
    [activitiesAll],
  );

  const { date, checkedActivities, evaluation, comment } = action;
  return (
    <div className="clientsActivities">
      <div className="clientHeader">{strings.lesson}</div>
      <div className="actionHeader">
        <CloseOutlined type="close" className="actionCloseIcon" onClick={() => goBack()} />
        <div>{dayjs(date).format('dddd D. M. YYYY, HH:mm')} </div>
        <div>
          {client.name}{' '}
          {client.birthDate && (
            <>
              {<span>{dayjs(client.birthDate).format('D. M. YYYY')}</span>}
              <span>({formatChildWeek(client.birthDate, action.date)})</span>
            </>
          )}
        </div>
      </div>
      <div className="clientProfileAction-main">
        <div
          className="clientProfileAction-headline"
          style={{ paddingTop: '0', marginBottom: '10px', borderTop: 'none' }}
        >
          {strings.activities}
        </div>
        <div className="clientProfileAction-activitiesList">
          {renderActivityList(1, checkedActivities)}
          {renderActivityList(2, checkedActivities)}
          {renderActivityList(3, checkedActivities)}
          {renderActivityList(4, checkedActivities)}
          {renderActivityList(5, checkedActivities)}
          {renderActivityList(6, checkedActivities)}
        </div>
        <div className="clientProfileAction-headline">{strings.assessment}</div>
        {actionQuestions.map((question, i) => (
          <div key={i}>
            <div className="clientend-question">{question}</div>
            <div className="clientend-question__buttons">
              <div
                className={classNames({
                  selected: evaluation['question' + (i + 1)] === 'notAtAll',
                })}
              >
                {strings.almostNotAtAll}
              </div>
              <div
                className={classNames({
                  selected: evaluation['question' + (i + 1)] === 'sometimes',
                })}
              >
                {strings.sometimes}
              </div>
              <div
                className={classNames({
                  selected: evaluation['question' + (i + 1)] === 'often',
                })}
              >
                {strings.veryOften}
              </div>
            </div>
          </div>
        ))}
        <div className="clientProfileAction-headline">{strings.comment}</div>
        <div className="clientProfileAction-comment">{comment}</div>
      </div>
    </div>
  );
}

ClientProfileAction.propTypes = {
  client: PropTypes.object,
  action: PropTypes.object,
  goBack: PropTypes.func,
  getActivitiesAction: PropTypes.func,
  // activitiesByID: PropTypes.objectOf(PropTypes.object),
};

export default ClientProfileAction;
