import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

//Local imports
import './CheckBox.scss';

function CheckBox({ check, activityId, actionId, handleActionCheckBox }) {
  return (
    <div className="checkBox" onClick={() => handleActionCheckBox(actionId, activityId)}>
      <div className="outter">
        <div className={classNames('inner', { innerActive: check })}></div>
      </div>
    </div>
  );
}

CheckBox.propTypes = {
  check: PropTypes.bool,
  activityId: PropTypes.string,
  actionId: PropTypes.string,
  handleActionCheckBox: PropTypes.func,
};

export default CheckBox;
