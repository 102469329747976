import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { jwtDecode } from 'jwt-decode';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import './ErrorReportFloatButton.scss';
import { Tooltip } from 'antd';
import { BugOutlined } from '@ant-design/icons';
import { showBugButton, errorDetected } from 'conf/loggers';
import { strings } from 'strings/StringsProvider';

function ErrorReportFloatButton() {
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const changePage = (location) => dispatch(push(location));
  const token = localStorage.getItem('access-token');
  let user = token ? jwtDecode(token) : {};

  // Error is detected, but button is not shown yet
  if (errorDetected && !timeoutId) {
    // After detecting an error, bug button will be displayed for 60 seconds
    const newTimeoutId = setTimeout(() => {
      showBugButton(false);
      setTimeoutId(null);
    }, 60000);

    setTimeoutId(newTimeoutId);
  }

  // If error report was sent and timeout is still running, we need to clear the timeout
  if (!errorDetected && timeoutId) {
    clearTimeout(timeoutId);
    setTimeoutId(null);
  }

  // display button only when there was and console error
  if (
    !errorDetected ||
    location.pathname === '/sign-in' ||
    location.pathname === '/register-device' ||
    location.pathname === '/'
  ) {
    return null;
  }

  return (
    <div>
      <Tooltip placement="left" title={strings.reportAProblem}>
        <div
          onClick={() => {
            changePage(`/${user.role === 'omama' ? 'omama' : 'admin'}/nastavenia/problem`);
          }}
          id="ErrorReportBtn"
        >
          <BugOutlined style={{ fontSize: '25px', color: 'white' }} />
        </div>
      </Tooltip>
    </div>
  );
}

export default ErrorReportFloatButton;
