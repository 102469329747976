import { GET_MENU } from '../reducers/menu.reducer';
import { USER_LOGOUT } from '../reducers/index';

export const getMenuAction = (isMenu) => {
  return async (dispatch) => {
    return dispatch({
      type: GET_MENU,
      payload: isMenu,
    });
  };
};

export const userLogoutAction = () => ({ type: USER_LOGOUT });
