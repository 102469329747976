import React, { useState, useEffect, useCallback } from 'react';
import { push, replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import Base64Image from '../../../components/Base64Image';
import classNames from 'classnames';
import { jwtDecode } from 'jwt-decode';
import { isEmpty } from 'lodash';
import MentorActions from '../../../containers/admin/MentorActions';
import Button from 'components/Button';

// Local imports
import './AdminMentor.scss';
import { getAdminUser } from '../../../actions/admin.actions';
import { strings } from '../../../strings/StringsProvider';
import { getOmamasAction } from '../../../actions/users.actions';
import { getMentorReportStatisticsAction } from '../../../actions/report.actions';
import EditProfile from '../../../components/EditProfile';
import UserInfoPanel from './UserInfoPanel';

const subMenuItems = ['Akcie', 'Omamy', 'Info'];

const columns = [
  {
    title: '',
    dataIndex: '_id',
    key: 'photo',
    render: (id) => (
      <Base64Image backUpImage="/images/cestavonLOGO_unofficial.png" className="tablePhoto" type="user" imageID={id} />
    ),
  },
  {
    title: strings.name,
    dataIndex: 'name',
    key: 'name',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      return a.name.localeCompare(b.name, 'sk', { sensitivity: 'accent' });
    },
  },
  {
    title: strings.email,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: strings.phoneNumber,
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: strings.city,
    dataIndex: 'city',
    key: 'city',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      return a.city.localeCompare(b.city, 'sk', { sensitivity: 'accent' });
    },
  },
  {
    title: strings.role,
    dataIndex: 'role',
    key: 'role',
    sortDirections: ['descend', 'ascend'],
    sorter: (a, b) => {
      return a.role.localeCompare(b.role, 'sk', { sensitivity: 'accent' });
    },
  },
];

function AdminMentor({ match }) {
  const [edit, setEdit] = useState(false);
  const [tab, setTab] = useState(decodeURIComponent(window.location.hash.substr(1)));
  const [initialOmamy, setInitialOmamy] = useState(true);
  const [initialInfo, setInitialInfo] = useState(true);
  const [iconClicked, setIconClicked] = useState(!isEmpty(window.location.hash.substr(1)) ? true : false);
  const userRole = jwtDecode(localStorage.getItem('access-token')).role;
  const userName = jwtDecode(localStorage.getItem('access-token')).username;
  const { userId } = useParams();
  const history = useHistory();

  const user = useSelector((state) => state.admin.oneUser);
  const reportMentorStatistics = useSelector((state) => state.report.reportMentorStatistics);
  const selectMappedOmamas = createSelector(
    (state) => state.users.omamas,
    (omamas) => omamas.filter((user) => user.mentorID === userId && user.active),
  );
  const mappedOmamas = useSelector(selectMappedOmamas);

  const dispatch = useDispatch();

  const changePage = useCallback((location) => dispatch(push(location)), [dispatch]);
  const replacePage = useCallback((location) => dispatch(replace(location)), [dispatch]);

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const handleClick = useCallback(() => {
    if (iconClicked === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'unset';
    } else if (iconClicked === false) {
      window.scrollTo(0, 0);
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    setIconClicked((prevIconClicked) => !prevIconClicked);
  }, [iconClicked]);

  const changeTab = useCallback(
    (tab) => () => {
      switch (true) {
        case tab === strings.actionOneUpperCase:
          setTab(tab);
          replacePage(`#${tab}`);
          break;
        case tab === strings.omamy:
          if (initialOmamy) {
            try {
              dispatch(getOmamasAction());
            } catch (error) {
              // console.log(error)
            }
          }
          setTab(tab);
          setInitialOmamy(false);
          replacePage(`#${tab}`);
          break;
        case tab === strings.info:
          if (initialInfo) {
            try {
              dispatch(getMentorReportStatisticsAction(dayjs().year(), userId));
            } catch (error) {
              // console.log(error)
            }
          }
          setTab(tab);
          setInitialInfo(false);
          replacePage(`#${tab}`);
          break;
        default:
      }
    },
    [dispatch, replacePage, initialInfo, initialOmamy, userId],
  );

  const mobileChangeTab = useCallback(
    (tab) => {
      changeTab(tab)();
      handleClick();
    },
    [changeTab, handleClick],
  );

  useEffect(() => {
    dispatch(getAdminUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!isMobile() && isEmpty(window.location.hash.substr(1))) {
      window.history.replaceState(null, match.url, `#${strings.actionOneUpperCase}`);
      setTab(strings.actionOneUpperCase);
    }
  }, [match.url]);

  const fetchUserAfterUpdate = () => {
    dispatch(getAdminUser(userId));
  };

  const switchEdit = (flag) => {
    setEdit(flag);
  };

  const renderStatsSubmenu = () => {
    return (
      <div className="admin-omama-submenu">
        <Button
          type="secondary"
          icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
          label={strings.goBack}
          className="go-back-button"
          onClick={history.goBack}
        />
        <ul>
          {subMenuItems.map((item, i) => (
            <li
              key={i}
              className={classNames({ active: tab === item })}
              onClick={changeTab(item)}
              data-test-id={`adminMentor-${item}`}
            >
              <span className="submenu-item">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderStatsSubmenuMobile = () => {
    return subMenuItems.map((item, i) => (
      <div key={i} className="admin-mentor-tabsMobile_row" onClick={() => mobileChangeTab(item)}>
        <span>{item}</span>
        <RightOutlined />
      </div>
    ));
  };

  const renderMentorAkcie = () => {
    return <MentorActions match={match} />;
  };

  const renderMentorOmamy = () => {
    return (
      <div data-test-id="adminMentor-omamas">
        {mappedOmamas && (
          <Table
            className="new-table users-table"
            rowKey="_id"
            dataSource={mappedOmamas}
            columns={columns}
            onRow={(record) => ({
              onClick: () => changePage('/admin/user/' + record._id + `/${record.role}`),
            })}
          />
        )}
      </div>
    );
  };

  const renderMentorInfo = () => {
    if (reportMentorStatistics) {
      const {
        nevyplneneVykazy,
        mentoring,
        phoneCall,
        otherMentoring,
        parentalClub,
        supervision,
        training,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other,
      } = reportMentorStatistics;
      return (
        <div className="mentorReportAdmin-main">
          <br />
          <h4 data-test-id="adminMentor-incompleteReports">
            <b>
              {strings.noFilledReports}: <span style={{ color: '#41aea6' }}>{nevyplneneVykazy}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminMentor-mentoringsThisYear">
            <b>
              {strings.mentoring1}: <span style={{ color: '#41aea6' }}>{mentoring}</span>
            </b>
          </h4>
          <h4 data-test-id="adminMentor-phoneCallsThisYear">
            <b>
              {strings.phoneCalls}: <span style={{ color: '#41aea6' }}>{phoneCall}</span>
            </b>
          </h4>
          <h4 data-test-id="adminMentor-otherMentoringsThisYear">
            <b>
              {strings.otherMentoring1}: <span style={{ color: '#41aea6' }}>{otherMentoring}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminMentor-otherWorks">
            <b>{strings.otherWorkingActions}</b>
          </h4>
          <div data-test-id="adminMentor-parentalsClub">
            {strings.parentalClub}:{' '}
            <span style={{ color: '#41aea6' }}>
              {parentalClub} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-supervisions">
            {strings.supervision}:{' '}
            <span style={{ color: '#41aea6' }}>
              {supervision} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-trainings">
            {strings.training}:{' '}
            <span style={{ color: '#41aea6' }}>
              {training} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-miu">
            {strings.miu}:{' '}
            <span style={{ color: '#41aea6' }}>
              {miu} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-travel">
            {strings.travel}:{' '}
            <span style={{ color: '#41aea6' }}>
              {travel} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-interNDA">
            {strings.interNDA}:{' '}
            <span style={{ color: '#41aea6' }}>
              {INTER_NDA} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-otherJobs">
            {strings.otherJob}:{' '}
            <span style={{ color: '#41aea6' }}>
              {otherJob} {strings.hour}
            </span>
          </div>
          <br />
          <h4 data-test-id="adminMentor-absences">
            <b>{strings.notBeingInTheJob}</b>
          </h4>
          <div data-test-id="adminMentor-vacations">
            {strings.vacation}:{' '}
            <span style={{ color: '#41aea6' }}>
              {vacation} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-doctors">
            {strings.doctor}:{' '}
            <span style={{ color: '#41aea6' }}>
              {doctorVisit} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-familyDoctors">
            {strings.familyDoctor}:{' '}
            <span style={{ color: '#41aea6' }}>
              {doctorRelative} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-workIncapacity">
            {strings.workUnable}:{' '}
            <span style={{ color: '#41aea6' }}>
              {workUnable} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-OCR">
            {strings.OCR}:{' '}
            <span style={{ color: '#41aea6' }}>
              {OCR} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-extraTimeOff">
            {strings.extraTimeOff}:{' '}
            <span style={{ color: '#41aea6' }}>
              {extraTimeOff} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminMentor-others">
            {strings.other}:{' '}
            <span style={{ color: '#41aea6' }}>
              {other} {strings.hour}
            </span>
          </div>
        </div>
      );
    } else {
      return <div>{strings.noInfo}.</div>;
    }
  };

  const openUserScreen = (record) => {
    document.getElementsByTagName('body')[0].style.overflow = 'unset';
    changePage('/admin/user/' + record._id + `/${record.role}`);
  };

  const renderMentorAkcieMobile = () => {
    return <MentorActions match={match} />;
  };

  const renderMentorOmamyMobile = () => {
    if (mappedOmamas) {
      return (
        <div className="omamasMobileList">
          {mappedOmamas.map((user) => {
            return (
              <div key={user._id} className="userMobileList-item" onClick={() => openUserScreen(user)}>
                <div className="userMobileList-item-leftCont">
                  <Base64Image
                    backUpImage="/images/cestavonLOGO_unofficial.png"
                    className="userMobileList-item-leftCont-tablePhoto"
                    type="user"
                    imageID={user._id}
                  />
                  <div className="userMobileList-item-leftCont-userInfo">
                    <p className="userMobileList-item-leftCont-userInfo-name">{user.name}</p>
                    <p className="userMobileList-item-leftCont-userInfo-city">{user.city}</p>
                  </div>
                </div>
                <div className="userMobileList-item-rightCont">
                  <p
                    className="userMobileList-item-rightCont-role"
                    style={{
                      color: '#41aea6',
                    }}
                  >
                    {user.role === 'supervisor' ? strings.supervision1.toLowerCase() : user.role}
                  </p>
                  <RightOutlined className="userMobileList-item-rightCont-icon" />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return [];
    }
  };

  if (user.role === 'mentor') {
    return (
      <>
        {edit && <EditProfile role={user.role} bckButton={switchEdit} fetchUser={fetchUserAfterUpdate} />}
        {!edit && (
          <div className="admin-userDetail">
            <UserInfoPanel user={user} userRole={userRole} userName={userName} switchEdit={switchEdit} />

            <div className="admin-generalUserInfo doNotShowOnMobile" style={{ marginLeft: '25px' }}>
              <div>{renderStatsSubmenu()}</div>
              <div
                className={`admin-omama-bottompanel ${tab === strings.info ? 'white-background' : ''}`}
                data-test-id="adminMentor-bottomPanel"
              >
                {tab === strings.actionOneUpperCase && renderMentorAkcie()}
                {tab === strings.omamy && renderMentorOmamy()}
                {tab === strings.info && renderMentorInfo()}
              </div>
            </div>

            <div className="admin-omama-tabsMobile showJustOnMobile" data-test-id="adminMentor-submenu-mobile">
              {renderStatsSubmenuMobile()}
            </div>
            <div
              className="tab_modal showJustOnMobile"
              style={{ display: iconClicked ? 'block' : 'none' }}
              data-test-id="adminMentor-closeButton-mobile"
            >
              <div
                className="tab_modal_menu showJustOnMobile"
                onClick={handleClick}
                data-test-id="adminMentor-modal-mobile"
              >
                {iconClicked ? <CloseOutlined className="tab_modal_menu-closeIcon closeIcon" /> : ''}
              </div>
              <div
                className="admin-omama-bottompanel admin-mentor-bottompanel showJustOnMobile"
                data-test-id="adminMentor-actions-mobile"
              >
                {tab === strings.actionOneUpperCase && renderMentorAkcieMobile()}
                {tab === strings.omamy && renderMentorOmamyMobile()}
                {tab === strings.info && renderMentorInfo()}
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else return null;
}

AdminMentor.propTypes = {
  match: PropTypes.object,
};

export default AdminMentor;
