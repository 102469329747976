import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import './MentorReport.scss';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

import { getMentorReportStatisticsAction } from '../../actions/report.actions';
import { strings } from '../../strings/StringsProvider';

function MentorReport({ reportMentorStatistics, getMentorReportStatisticsAction }) {
  useEffect(() => {
    const user = jwtDecode(localStorage.getItem('access-token'));
    if (user && user.username) {
      getMentorReportStatisticsAction(dayjs().year(), user.username);
    }
  }, [getMentorReportStatisticsAction]);

  const {
    nevyplneneVykazy,
    mentoring,
    phoneCall,
    otherMentoring,
    parentalClub,
    supervision,
    training,
    miu,
    travel,
    INTER_NDA,
    otherJob,
    vacation,
    doctorVisit,
    doctorRelative,
    workUnable,
    OCR,
    other,
  } = reportMentorStatistics || {};

  return (
    <div className="mentor-report-container">
      <div className="mentor-report">
        <br />
        <h4>
          <b>
            {strings.noFilledReports}: <span style={{ color: '#41aea6' }}>{nevyplneneVykazy}</span>
          </b>
        </h4>
        <br />
        <h4>
          <b>
            {strings.mentoring1}: <span style={{ color: '#41aea6' }}>{mentoring}</span>
          </b>
        </h4>
        <h4>
          <b>
            {strings.phoneCalls}: <span style={{ color: '#41aea6' }}>{phoneCall}</span>
          </b>
        </h4>
        <h4>
          <b>
            {strings.otherMentoring1}: <span style={{ color: '#41aea6' }}>{otherMentoring}</span>
          </b>
        </h4>
        <br />
        <h4>
          <b>{strings.otherWorkingActions}</b>
        </h4>
        <div>
          {strings.parentalClub}:{' '}
          <span style={{ color: '#41aea6' }}>
            {parentalClub} {strings.hour}
          </span>
        </div>
        <div>
          {strings.supervision}:{' '}
          <span style={{ color: '#41aea6' }}>
            {supervision} {strings.hour}
          </span>
        </div>
        <div>
          {strings.training}:{' '}
          <span style={{ color: '#41aea6' }}>
            {training} {strings.hour}
          </span>
        </div>
        <div>
          {strings.miu}:{' '}
          <span style={{ color: '#41aea6' }}>
            {miu} {strings.hour}
          </span>
        </div>
        <div>
          {strings.travel}:{' '}
          <span style={{ color: '#41aea6' }}>
            {travel} {strings.hour}
          </span>
        </div>
        <div>
          {strings.interNDA}:{' '}
          <span style={{ color: '#41aea6' }}>
            {INTER_NDA} {strings.hour}
          </span>
        </div>
        <div>
          {strings.otherJob}:{' '}
          <span style={{ color: '#41aea6' }}>
            {otherJob} {strings.hour}
          </span>
        </div>
        <br />
        <h4>
          <b>{strings.notInWork}</b>
        </h4>
        <div>
          {strings.vacation}:{' '}
          <span style={{ color: '#41aea6' }}>
            {vacation} {strings.hour}
          </span>
        </div>
        <div>
          {strings.doctor}:{' '}
          <span style={{ color: '#41aea6' }}>
            {doctorVisit} {strings.hour}
          </span>
        </div>
        <div>
          {strings.familyDoctor}:{' '}
          <span style={{ color: '#41aea6' }}>
            {doctorRelative} {strings.hour}
          </span>
        </div>
        <div>
          {strings.workUnable}:{' '}
          <span style={{ color: '#41aea6' }}>
            {workUnable} {strings.hour}
          </span>
        </div>
        <div>
          {strings.OCR}:{' '}
          <span style={{ color: '#41aea6' }}>
            {OCR} {strings.hour}
          </span>
        </div>
        <div>
          {strings.other}:{' '}
          <span style={{ color: '#41aea6' }}>
            {other} {strings.hour}
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ report }) => {
  return {
    reportMentorStatistics: report.reportMentorStatistics,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMentorReportStatisticsAction,
    },
    dispatch,
  );

MentorReport.propTypes = {
  reportMentorStatistics: PropTypes.object,
  getMentorReportStatisticsAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentorReport);
